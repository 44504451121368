.upload__block {
  &.service__button {
    & .wrap-btn-add {
      &.service__drop--wrapper {
        position: relative;
        & .service__dropdown {
          top: 50px;
          & .service__list {
            & ul {
              & li {
                & p {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
        & .wrap__another--drop {
          position: static;
        }
      }
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.employer__table {
  width: 100%;
  padding-bottom: 250px;
  overflow-x: auto;
  & .employer__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .elem__employer {
      padding: 10px 15px;
      &.centered__table {
        text-align: center;
        & h5 {
          text-align: center;
        }
      }
      & h5 {
        margin: 0px;
        font-size: 15px;
        color: #9e9e9e;
        font-weight: 600;
      }
      &:nth-child(1) {
        min-width: 160px;
        max-width: 160px;
        padding-left: 30px;
      }
      &:nth-child(2) {
        min-width: 280px;
        max-width: 280px;
      }
      &:nth-child(3) {
        min-width: 180px;
        max-width: 180px;
      }
      &:nth-child(4) {
        min-width: 140px;
        max-width: 140px;
      }
      &:nth-child(5) {
        min-width: 210px;
        max-width: 210px;
      }
      &:nth-child(6) {
        min-width: 230px;
        max-width: 230px;
      }
      &:nth-child(7) {
        min-width: 110px;
        max-width: 110px;
      }
    }
  }
  & .employer__content {
    & .row__employer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:nth-child(odd) {
        background-color: #f7f7f7;
        & > .elem__employer {
          background-color: #f7f7f7;
        }
      }
      & > .elem__employer {
        padding: 5px 15px;
        & > .drop__wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        & > .waiting__block {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:nth-child(1) {
          min-width: 160px;
          max-width: 160px;
          padding-left: 30px;
        }
        &:nth-child(2) {
          min-width: 280px;
          max-width: 280px;
        }
        &:nth-child(3) {
          min-width: 180px;
          max-width: 180px;
        }
        &:nth-child(4) {
          min-width: 140px;
          max-width: 140px;
        }
        &:nth-child(5) {
          min-width: 210px;
          max-width: 210px;
        }
        &:nth-child(6) {
          min-width: 230px;
          max-width: 230px;
        }
        &:nth-child(7) {
          min-width: 110px;
          max-width: 110px;
        }
      }
    }
  }
}

.client__table {
  width: 100%;
  overflow-x: auto;
  & .head__client {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 7px;
    & > .elem__client {
      padding: 7px 15px;
      & h5 {
        margin: 0px;
        color: #9e9e9e;
        font-weight: 600;
        font-size: 15px;
      }
      &:nth-child(2) {
        width: 100%;
        min-width: 400px;
      }
      &:nth-child(1) {
        min-width: 150px;
        max-width: 150px;
        padding: 7px 15px;
        padding-left: 30px;
      }
    }
  }
  & .content__client {
    & .row__client {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:nth-child(odd) {
        background-color: #f7f7f7;
        & > .elem__client {
          background-color: #f7f7f7;
        }
      }
      & > .elem__client {
        padding: 7px 15px;
        & > p {
          margin: 0px;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
        }
        & > .profile__client {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          & .client__info {
            & p {
              margin: 0px;
              font-weight: 700;
              font-size: 14px;
            }
          }
          & .client__image {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            & img {
              max-width: 40px;
              min-width: 40px;
              max-height: 40px;
              min-height: 40px;
              border-radius: 250px;
              object-fit: cover;
            }
          }
        }
        &:nth-child(2) {
          width: 100%;
          min-width: 400px;
        }
        &:nth-child(1) {
          min-width: 150px;
          max-width: 150px;
          padding: 7px 15px;
          padding-left: 30px;
        }
      }
    }
  }
}

.table__stat {
  width: 100%;
  overflow-x: auto;
  & .table__stat--head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .elem__stat {
      padding: 7px 15px;
      &:nth-child(1) {
        min-width: 290px;
        max-width: 290px;
      }
      &:nth-child(2) {
        min-width: 180px;
        max-width: 180px;
      }
      &:nth-child(3) {
        min-width: 115px;
        max-width: 115px;
      }
      &:nth-child(4) {
        min-width: 130px;
        max-width: 130px;
      }
      &:nth-child(5) {
        min-width: 230px;
        max-width: 230px;
      }
      &:nth-child(6) {
        min-width: 230px;
        max-width: 230px;
      }
      & h5 {
        margin-top: 0px;
        color: #3d3d3d;
        font-size: 14px;
        padding-bottom: 5px;
        font-weight: 600;
      }
    }
  }
  & .content__stat {
    & .row__stat {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &:nth-child(even) {
        background-color: #fff;
        & .elem__stat {
          background-color: #fff;
        }
      }
      &:nth-child(odd) {
        background-color: #f7f7f7;
        & .elem__stat {
          background-color: #f7f7f7;
        }
      }
      & .elem__stat {
        padding: 7px 15px;
        &:nth-child(1) {
          min-width: 290px;
          max-width: 290px;
        }
        &:nth-child(2) {
          min-width: 180px;
          max-width: 180px;
        }
        &:nth-child(3) {
          min-width: 115px;
          max-width: 115px;
        }
        &:nth-child(4) {
          min-width: 130px;
          max-width: 130px;
        }
        &:nth-child(5) {
          min-width: 230px;
          max-width: 230px;
        }
        &:nth-child(6) {
          min-width: 230px;
          max-width: 230px;
        }
        & .icon-title {
          & .icon-title__icon {
            margin-right: 15px;
            min-width: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          & .icon-title__title {
            & > p {
              font-weight: 600;
              color: #2d2d2d;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.project__table {
  padding-bottom: 180px;
  background-color: #fff;
  width: 100%;
  overflow-x: auto;
  & > .project__table--head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    & .elem__project--table {
      &.center__project {
        & > h5 {
          text-align: center;
        }
      }
      &:nth-child(1) {
        min-width: 130px;
        max-width: 130px;
      }
      &:nth-child(2) {
        min-width: 90px;
        max-width: 90px;
      }
      &:nth-child(3) {
        min-width: 280px;
        max-width: 280px;
      }
      &:nth-child(4) {
        min-width: 135px;
        max-width: 135px;
      }
      &:nth-child(5) {
        min-width: 155px;
        max-width: 155px;
      }
      &:nth-child(6) {
        min-width: 100px;
        max-width: 100px;
      }
      &:nth-child(7) {
        min-width: 100px;
        max-width: 100px;
      }
      &:nth-child(8) {
        min-width: 95px;
        max-width: 95px;
      }
      &:nth-child(9) {
        min-width: 115px;
        max-width: 115px;
      }
      &:nth-child(10) {
        min-width: 80px;
        max-width: 80px;
      }
      &:nth-child(11) {
        min-width: 60px;
        max-width: 60px;
      }
      & > h5 {
        margin: 0px;
        color: rgba(45, 45, 45, 1);
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  & > .project__table--content {
    & > .wrapper__table--row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:nth-child(odd) {
        background-color: #f7f7f7;
        & > .elem__table--content {
          background-color: #f7f7f7;
        }
      }
      &:nth-child(even) {
        background-color: #fff;
        & > .elem__table--content {
          background-color: #fff;
        }
      }
      & > .elem__table--content {
        min-height: 50px;
        padding-top: 3px;
        padding-bottom: 3px;
        display: flex;
        align-items: center;
        &.date__table {
          & p {
            font-weight: 600;
          }
        }
        & p {
          margin: 0px;
          font-size: 14px;
          line-height: 19px;
          color: rgba(45, 45, 45, 1);
        }
        &.center__project {
          & > p {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
        }
        &:nth-child(1) {
          min-width: 130px;
          max-width: 130px;
          padding-left: 30px;
        }
        &:nth-child(2) {
          min-width: 90px;
          max-width: 90px;
        }
        &:nth-child(3) {
          min-width: 280px;
          max-width: 280px;
        }
        &:nth-child(4) {
          min-width: 135px;
          max-width: 135px;
        }
        &:nth-child(5) {
          min-width: 155px;
          max-width: 155px;
        }
        &:nth-child(6) {
          min-width: 100px;
          max-width: 100px;
        }
        &:nth-child(7) {
          min-width: 100px;
          max-width: 100px;
        }
        &:nth-child(8) {
          min-width: 95px;
          max-width: 95px;
        }
        &:nth-child(9) {
          min-width: 115px;
          max-width: 115px;
        }
        &:nth-child(10) {
          min-width: 80px;
          max-width: 80px;
        }
        &:nth-child(11) {
          min-width: 60px;
          max-width: 60px;
          padding-right: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.form-group {
  &.current__group {
    &.tag__group {
      & > .tag__field {
        border: 1px solid #0e7d7d;
      }
    }
    &.responsible__field {
      & > .responsible__person {
        border: 1px solid #0e7d7d !important;
      }
    }
    &.client__dropdown {
      & > .input-animation {
        & > input {
          border: 1px solid #0e7d7d;
        }
      }
    }
  }
  &.responsible__field {
    position: relative;
    & .responsible__dropdown {
      position: absolute;
      left: 0px;
      z-index: 3;
      width: 100%;
      background: #ffffff;
      border-radius: 3px;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      padding: 15px 20px;
      display: none;
      & .list__responsible--person {
        max-height: 180px;
        overflow-y: auto;
        padding-right: 10px;
        &::-webkit-scrollbar {
          width: 3px;
          border-radius: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #e9e9e9;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #aeaeae;
          border-radius: 3px;
        }
        & .elem__responsible--dropdown {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &.active__responsible-- {
            & .response__button {
              & > a {
                background-color: rgba(14, 125, 125, 1) !important;
                & > span {
                  background-color: #fff !important;
                }
                & > span:nth-child(1) {
                  opacity: 0;
                }
                &:hover {
                  background-color: #fff !important;
                  & > span {
                    background-color: rgba(14, 125, 125, 1) !important;
                  }
                }
              }
            }
            & .response__person {
              & .response__image {
                border-color: rgba(14, 125, 125, 1);
              }
            }
          }
          & .response__button {
            & a {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              min-width: 40px;
              max-width: 40px;
              min-height: 40px;
              max-height: 40px;
              border: 2px solid #0e7d7d;
              border-radius: 3px;
              position: relative;
              transition: 0.4s ease all;
              &:hover {
                background-color: #0e7d7d;
                & > span {
                  background-color: #fff !important;
                }
              }
              & > span:nth-child(1) {
                position: absolute;
                left: 0px;
                right: 0px;
                margin-left: auto;
                margin-right: auto;
                width: 2px;
                height: 16px;
                background: #0e7d7d;
                transition: 0.4s ease all;
              }
              & > span:nth-child(2) {
                left: 0px;
                right: 0px;
                margin-left: auto;
                margin-right: auto;
                height: 2px;
                width: 16px;
                background: #0e7d7d;
                transition: 0.4s ease all;
              }
            }
          }
          & .response__person {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            & .response__text {
              & > a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: rgba(45, 45, 45, 1);
                font-size: 14px;
                line-height: 21px;
                font-weight: 600;
                text-decoration: underline;
                transition: 0.4s ease all;
                &:hover {
                  opacity: 0.7;
                }
              }
            }
            & .response__image {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border: 2px solid transparent;
              padding: 3px;
              border-radius: 250px;
              transition: 0.4s ease all;
              margin-right: 10px;
              & img {
                min-width: 34px;
                max-width: 34px;
                min-height: 34px;
                max-height: 34px;
              }
            }
          }
        }
      }
      & .head__responsible--person {
        & > input {
          width: 100%;
          height: 50px;
          padding-left: 20px;
          padding-right: 20px;
          background: #ffffff;
          border: 1px solid #bdbdbd;
          border-radius: 3px;
          color: rgba(130, 130, 130, 1);
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          margin-bottom: 20px;
        }
        & > p {
          margin-top: 0px;
          margin-bottom: 10px;
          color: rgba(45, 45, 45, 1);
          font-size: 14px;
          line-height: 24px;
          font-weight: 600;
        }
      }
    }
    & .responsible__person {
      transition: 0.4s ease all;
      & .person__response {
        &.example__response {
          display: none !important;
        }
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 44px;
        background: rgba(14, 125, 125, 0.05);
        border-radius: 100px;
        padding: 7px 15px;
        margin-bottom: 5px;
        margin-right: 5px;
        position: relative;
        top: 2px;
        & .person__button {
          margin-left: 10px;
          & > a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: 0.4s ease all;
          }
        }
        & .person__left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & .person__info {
            & > p {
              margin: 0px;
              color: rgba(59, 59, 59, 1);
              font-size: 12px;
              line-height: 18px;
              font-weight: 400;
            }
          }
          & .person__image {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            & img {
              max-width: 30px;
              min-width: 30px;
              max-height: 30px;
              min-height: 30px;
              border-radius: 250px;
              object-fit: cover;
            }
          }
        }
      }
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding: 5px;
      border: 1px solid #dadada;
      border-radius: 3px;
      display: flex;
      flex-wrap: wrap;
      min-height: 50px;
      & .responsible__field {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        top: -1px;
        & > input {
          width: 200px;
          height: 35px;
          padding-left: 10px;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #9d9d9d;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #9d9d9d;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #9d9d9d;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: #9d9d9d;
          }
        }
      }
    }
  }
  &.tag__group {
    position: relative;
    & .tag__dropdown {
      position: absolute;
      left: 0px;
      z-index: 3;
      width: 100%;
      background: #ffffff;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      padding: 15px 10px;
      display: none;
      & .tag__search {
        max-height: 180px;
        overflow-y: auto;
        padding-right: 10px;
        & .elem__tag {
          margin-bottom: 3px;
          position: relative;
          top: 2px;
          &.disabled__tag {
            opacity: 0.5;
            pointer-events: none;
          }
          &:last-child {
            margin-bottom: 0px;
          }
          &.active__tag {
            opacity: 1 !important;
            pointer-events: initial !important;
            & > a:nth-child(1) {
              background: #ebf1f1;
            }
            & > a:nth-child(2) {
              display: flex;
            }
          }
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > a:nth-child(1) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 10px 15px;
            color: #0e7d7d;
            font-size: 14px;
            line-height: 21px;
            width: 100%;
            font-weight: 600;
            -webkit-transition: 0.4s ease all;
            transition: 0.4s ease all;
            border-radius: 4px;
            padding: 10px 15px;
            & p {
              margin: 0px;
              color: #0e7d7d;
              font-size: 14px;
              line-height: 21px;
              width: 100%;
              font-weight: 600;
            }
            &:hover {
              background: #ebf1f1;
            }
            & span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 15px;
            }
            &:hover {
            }
          }
          & > a:last-child {
            min-width: 34px;
            max-width: 34px;
            min-height: 34px;
            max-height: 34px;
            transition: 0.4s ease all;
            display: inline-flex;
            align-items: center;
            display: none;
            justify-content: center;
            &:hover {
              opacity: 0.7;
            }
          }
        }
        &::-webkit-scrollbar {
          width: 3px;
          border-radius: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #e9e9e9;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #aeaeae;
          border-radius: 3px;
        }
      }
    }
    & .tag__field {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      transition: 0.4s ease all;
      & .elem__tag {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        background: #f3f3f3;
        border-radius: 3px;
        margin-right: 5px;
        margin-bottom: 5px;
        & p {
          margin: 0px;
          color: rgba(45, 45, 45, 1);
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          margin-right: 9px;
        }
        & a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.4s ease all;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding: 5px;
      border: 1px solid #dadada;
      border-radius: 3px;
      display: flex;
      flex-wrap: wrap;
      & .tag__input {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -1px;
        & > input {
          width: 200px;
          height: 35px;
          padding-left: 10px;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #9d9d9d;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #9d9d9d;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #9d9d9d;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: #9d9d9d;
          }
        }
      }
      min-height: 50px;
    }
  }
  &.client__dropdown {
    position: relative;
    & .client__box {
      position: absolute;
      left: 0px;
      bottom: auto;
      top: 60px;
      width: 100%;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      z-index: 3;
      background-color: #fff;
      padding: 20px;
      display: none;
      & .client__list {
        max-height: 150px;
        overflow-y: auto;
        & .elem__client {
          display: flex;
          width: calc(100% - 20px);
          & a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 15px;
            color: rgba(14, 125, 125, 1);
            font-size: 14px;
            line-height: 21px;
            width: 100%;
            font-weight: 600;
            transition: 0.4s ease all;
            border-radius: 4px;
            &:hover {
              background: #ebf1f1;
            }
          }
        }
        &::-webkit-scrollbar {
          width: 3px;
          border-radius: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #e9e9e9;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #aeaeae;
          border-radius: 3px;
        }
      }
      & .client__search {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 20px;
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #0e7d7d;
          border-radius: 4px;
          font-size: 16px;
          line-height: 21px;
          font-weight: 600;
          color: #0e7d7d;
          min-width: 95px;
          min-height: 50px;
          & span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          }
        }
        & > input[type='text'] {
          padding-left: 20px;
          padding-right: 20px;
          color: #828282;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          width: 100%;
          height: 50px;
          margin-right: 10px;
          border: 1px solid #bdbdbd;
          border-radius: 3px;
        }
      }
    }
  }
}

.services__table--row {
  min-width: 95px;
}
.row-group--nowrap p {
  white-space: nowrap;
}
.lang-combinations-dropdown__box {
  right: auto;
  max-width: 215px;
  min-width: 215px;
  transform: translateX(-100%);
  margin-left: 20px;
  margin-top: 25px;
}
.table__wrapper {
  overflow-x: auto;
  width: 100%;
  background-color: #fff;
  position: relative;
  & > .float__controls {
    position: absolute;
    right: 0px;
    top: -5px;
    z-index: 3;
  }
  &.projects__table {
    padding-bottom: 60px;
  }
  &.jobs__table {
    & > table {
      min-width: 740px;
    }
  }
  &.workflow__table {
    margin-bottom: 20px;
    background: #ffffff;
    border-radius: 3px;
    padding-top: 0px;
    padding-top: 40px;
    padding-bottom: 30px;
    & > table {
      min-width: 950px;
    }
  }

  & .filter__head {
    & .button {
      filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.05));
    }
  }
  & table.table__common {
    & .placeholder__droppable {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      background-color: #fff;
      height: 100%;
      z-index: 5;
    }
    & .deadline__table {
      & p {
        white-space: nowrap;
      }
      & .deadline__soon {
        background: #e7a400;
        border: 1px solid #e7a400;
        border-radius: 100px;
        display: inline-flex;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        padding: 2px 15px;
      }
      & .lating__deadline {
        background: #e74500;
        border-radius: 100px;
        display: inline-flex;
        padding: 2px 15px;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        line-height: 21px;
      }
    }
    & .droppable__deadline {
      & p {
        white-space: nowrap;
      }
      & .deadline__droppable {
        &.set__deadline {
          & > p {
            color: #3f88c5;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            margin: 0px;
            & > span:last-child {
              opacity: 0;
              margin-left: 0px;
              width: 0px;
              transition: 0.4s ease all;
            }
          }
        }
        &.today__deadline {
          & p {
            color: #e7a400;
            font-size: 14px;
            line-height: 21px;
            font-weight: 600;
            margin: 0px;
          }
        }
      }
    }
    & .custom__worker {
      & > p {
        margin: 0px;
        color: rgba(158, 158, 158, 1);
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        white-space: nowrap;
      }
    }
    // & .lang__worker--field{
    //   width: 140px;
    // }
    // & .client__field{
    //   width: 185px;
    // }
    // & .client__field{
    //   width: 185px;
    // }
    & .deadline__picker {
      width: 235px;
    }
    & .expandable__job {
      padding-left: 15px !important;
      padding-right: 15px !important;
      border-bottom: 0px !important;
      // & .worker__field{
      //   width: 310px;
      // }
    }
    & .hover__checkbox--number {
      &:hover {
        & .checkbox__number {
          & .checkbox__w--number {
            & .check__input {
              opacity: 1;
            }
            & p {
              opacity: 0;
            }
          }
        }
      }
      &.active__job {
        & .checkbox__number {
          & .checkbox__w--number {
            & .float__input {
              opacity: 1;
            }
            & .weight__text {
              opacity: 0;
            }
          }
          &:before {
            opacity: 1;
            width: 3px;
          }
        }
      }
    }
    & .checkbox__number {
      width: 30px;
      padding-right: 7px;
      &:before {
        content: '';
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        position: absolute;
        left: -1px;
        top: 0px;
        background-color: #0e7d7d;
        height: 100%;
        width: 0px;
        transition: 0.4s ease all;
        z-index: 2;
        top: -1px;
      }
    }
    & .checkbox__w--number {
      position: relative;
      margin-right: 15px;
      min-width: 30px;
      max-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      & .float__input {
        position: absolute;
        transition: 0.2s ease all;
        left: 0px;
        opacity: 0;
        z-index: 3;
      }
      & p {
        transition: 0.2s ease all;
        min-width: 30px;
        max-width: 30px;
      }
    }
    & .worker__info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & .comment__dropdown {
        & a {
          transition: 0.4s ease all;
          &:hover {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          }
        }
      }
      & > a {
        display: inline-flex;
        text-decoration: underline;
        color: #2d2d2d;
        font-size: 14px;
        font-weight: 600;
        transition: 0.4s ease all;
        white-space: nowrap;
      }
      & .worker__image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        & img {
          max-width: 35px;
          min-width: 35px;
          min-height: 35px;
          max-height: 35px;
          border-radius: 250px;
          object-fit: cover;
        }
      }
    }
    & table {
      width: 100%;
    }
    & .client__name {
      & a {
        text-decoration: underline;
        color: rgba(24, 142, 240, 1);
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        white-space: nowrap;
        transition: 0.4s ease all;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    & .progress__table {
      &.full__progress {
        & p {
          color: #00c868;
          border: 1px solid #00c868;
        }
      }
      & p {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #3f88c5;
        border-radius: 100px;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: rgba(63, 136, 197, 1);
        padding: 5px 15px;
      }
    }
    & .order__link {
      & a {
        display: inline-flex;
        text-decoration: underline;
        color: #0e7d7d;
        font-size: 16px;
        font-weight: 600;
        transition: 0.4s ease all;
        white-space: nowrap;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    & tr {
      & th {
        padding-bottom: 10px !important;
      }
    }
    & .status__table--wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      & .planed__status {
        min-width: 170px;
      }
      & .status__table--dropdown {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & .main__status--table {
          width: auto;
          max-width: 175px;
          /* padding-right: 35px; */
          padding-left: 20px;
          min-width: 175px;
          & p {
            font-weight: 600;
          }
        }
      }
      & .main__status--table {
        min-width: 0px;
        padding-left: 10px;
        padding-right: 10px;
        & > p {
          font-weight: 600;
          & span {
            & img {
              min-width: 14px;
            }
          }
        }
      }
    }
    & .job__element {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > p {
        margin-left: 15px;
        min-width: 35px;
        margin-right: 5px;
      }
    }
    & .service__name {
      display: flex;
      flex-direction: column;
      min-width: 140px;
      & > p {
        white-space: nowrap;
      }
      & > span {
        white-space: nowrap;
      }
    }
    & .small__text {
      font-size: 12px;
    }
    & .transparent__text {
      color: #9e9e9e;
    }
    & .weight__text {
      font-weight: 600;
    }
    & h4 .approx__info {
      margin-left: 15px;
      padding: 5px 10px;
      display: inline-flex;
      align-items: center;
      opacity: 0;
      transition: 0.4s ease all;
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      & p {
        margin: 0px;
        color: rgba(45, 45, 45, 1);
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
      }
      border-radius: 4px;
    }
    width: 100%;
    & .expandable__row {
      padding: 0px;
    }
    & .download__freelancer--file {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      & .file__open {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        & a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-height: 30px;
          padding: 1px 20px;
          border-radius: 4px;
          border: 1px solid #e3e3e3;
          color: #0e7d7d;
          font-size: 12px;
          line-height: 24px;
          font-weight: 600;
          transition: 0.4s ease all;
          &:hover {
            border-color: #0e7d7d;
            -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          }
        }
      }
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e3e3e3;
        min-width: 30px;
        transition: 0.4s ease all;
        max-width: 30px;
        min-height: 30px;
        max-height: 30px;
        border-radius: 4px;
        margin-right: 10px;
        &:hover {
          border-color: #0e7d7d;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    & .file__field--table {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding-left: 25px;
      & .warn__file {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: -30px;
        margin-right: 10px;
      }
      & > p {
        white-space: nowrap;
      }
      & span {
        display: inline-flex;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        & img {
          min-width: 24px;
        }
      }
    }
    & .file__table {
      width: 100%;
      & tr {
        &:last-child {
          & td {
            border-bottom: 0px;
          }
        }
      }
    }
    & .show__row {
      width: 90px;
    }
    & .size__freelance {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > p {
        white-space: nowrap;
      }
      & .approx__info {
        margin-left: 10px;
        & img {
          min-width: 24px;
        }
      }
    }

    & .show__main {
      display: flex;
      &.active__expandable {
        background: #0e7d7d !important;
        border: 1px solid #0e7d7d !important;
        border-radius: 4px !important;
        & path {
          fill: #fff;
        }
        & span.arrow__show {
          transform: rotate(180deg);
        }
        & p {
          color: #fff;
        }
      }
      position: relative;
      align-items: center;
      justify-content: center;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      min-width: 85px;
      max-width: 85px;
      min-height: 30px;
      cursor: pointer;
      transition: 0.4s ease all;
      & svg {
        transition: 0.2s ease all;
        position: relative;
        left: 0px;
      }
      & .arrow__show {
        transition: 0.4s ease all;
        margin-left: 10px;
      }
      & p {
        margin: 0px;
        color: rgba(14, 125, 125, 1);
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        display: flex;
        align-items: center;
      }
      &:hover {
        border-color: #0e7d7d;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
    }
    & p {
      &.active__weight--text {
        color: #0e7d7d !important;
        font-weight: bold;
      }
      &.small__text {
        font-size: 12px;
        color: #3b3b3b;
        margin: 0px;
      }
    }
    & .status__element {
      & p {
        background-color: #f2f2f2;
        border-radius: 100px;
        display: inline-flex;
        margin: 0px;
        padding: 3px 10px;
        font-size: 10px;
        line-height: 18px;
        color: #787878;
        font-weight: 600;
        white-space: nowrap;
      }
    }
    & .remove__source {
      position: relative;
      top: 5px;
      & a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.4s ease all;
        & img {
          min-width: 18px;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
    & .source__tags {
      position: relative;
      top: 0px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
      & .elem__tag {
        display: inline-flex;
        align-items: center;
        margin-right: 5px;
        padding: 10px;
        background: #ffffff;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        margin-bottom: 5px;
        & a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.4s ease all;
          & img {
            min-width: 10px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
        & p {
          margin: 0px;
          color: rgba(63, 136, 197, 1);
          font-size: 12px;
          white-space: nowrap;
          font-weight: 400;
          line-height: 12px;
          margin-right: 10px;
        }
      }
    }
    & .source__picker {
      & p {
        margin: 0px;
        margin-right: 5px;
        text-decoration: underline;
        cursor: pointer;
        color: #2d2d2d;
        font-size: 12px;
        font-weight: 600;
      }
    }
    & .source__main {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & .source__file {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 15px;
        & > p {
          margin: 0px;
          color: #3b3b3b;
          font-size: 12px;
          font-weight: 400;
          white-space: nowrap;
        }
        & > span {
          display: inline-flex;
          margin-right: 10px;
          align-items: center;
          & img {
            min-width: 24px;
          }
          justify-content: center;
        }
      }
    }
    &.no__active {
      & td {
        &:before {
          content: none !important;
        }
      }
    }
    &.no__space {
      & tr {
        & td {
          &:last-child {
            padding-right: 0px;
          }
          padding-left: 0px;
          padding-right: 15px;
        }
        & th {
          padding-left: 0px;
          padding-right: 15px;
        }
      }
    }
    & .check__head {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & .check__input {
        margin-right: 20px;
      }
    }
    & .table__project--buttons {
      width: 200px;
    }
    & table.group__row {
      width: 100%;
      border: 0px;
      & tr {
        &:hover {
          background-color: transparent !important;
        }
        border: 0px;
        background-color: transparent !important;
        & th {
          border: 0px;
          padding: 4px 15px !important;
          padding-left: 0px !important;
          &.table__project--buttons {
            width: 200px;
            padding-right: 0px !important;
            & .file__opps {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 102px;
              & > a {
                &:hover {
                  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
                  border-color: #0e7d7d;
                  & span {
                    width: 55px;
                    opacity: 1;
                    margin-left: 5px;
                  }
                }
                min-width: 34px;
                min-height: 34px;
                background: #ffffff;
                border: 1px solid #e3e3e3;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                transition: 0.2s ease all;
                overflow: hidden;
                & span {
                  color: rgba(14, 125, 125, 1);
                  font-size: 14px;
                  line-height: 24px;
                  font-weight: 600;
                  width: 0px;
                  opacity: 0;
                  transition: 0.2s ease all;
                }
              }
            }
          }
          & .group__task {
            & > h5 {
              color: rgba(45, 45, 45, 1);
              font-size: 14px;
              line-height: 21px;
              font-weight: 600;
              & span {
                display: inline-flex;
                margin-left: 10px;
                padding: 3px 10px;
                border: 1px solid #3f88c5;
                color: rgba(63, 136, 197, 1);
                font-size: 14px;
                font-weight: 700;
                border-radius: 6px;
              }
            }
          }
        }
        & td {
          border: 0px;
        }
      }
    }
    & .opps__deadline {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      & > p {
        color: #2d2d2d;
        font-size: 14px;
        font-weight: 600;
        margin: 0px;
      }
      & .deadline__today {
        & p {
          margin: 0px;
          color: rgba(231, 164, 0, 1);
          font-size: 14px;
          font-weight: 600;
        }
      }
      & .deadline__left {
        & p {
          color: rgba(120, 120, 120, 1);
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
          white-space: nowrap;
        }
      }
      & .deadline__status--lating {
        background: #e74500;
        border-radius: 100px;
        padding: 5px 15px;
        & p {
          margin: 0px;
          white-space: nowrap;
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          color: #fff;
        }
      }
      & .finished__deadline--late {
        border: 1px solid rgba(231, 69, 0, 1);
        border-radius: 100px;
        padding: 5px 15px;
        & p {
          white-space: nowrap;
          margin: 0px;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
          color: rgba(231, 69, 0, 1);
        }
      }
      & .finished__deadline--default {
        & p {
          white-space: nowrap;
          margin: 0px;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
          color: #00c868;
        }
      }
    }
    & .opps__status {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      & .opps__status--main {
        & p {
          white-space: nowrap;
        }
        &.correction__needed {
          padding: 5px 15px;
          border: 1px solid #e9a500;
          border-radius: 50px;
          margin-left: auto;
          margin-right: auto;
          background-color: #fff;
          & p {
            white-space: nowrap;
            & span {
              display: inline-flex;
              margin-right: 10px;
            }
            display: flex;
            align-items: center;
            color: rgba(233, 165, 0, 1);
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            margin: 0px;
          }
        }
        &.canceled__opps {
          & p {
            display: flex;
            align-items: center;
            color: rgba(255, 50, 50, 1);
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            & span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-left: 10px;
            }
          }
        }
        &.waiting__approval {
          & p {
            color: rgba(226, 122, 0, 1);
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
          }
        }
        &.finished__status {
          & > p {
            display: flex;
            align-items: center;
            color: rgba(0, 200, 104, 1);
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
          }
        }
        &.assigned__status {
          border: 1px solid #0e7d7d;
          padding: 5px 15px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 30px;
          background-color: #fff;
          margin-left: auto;
          margin-right: auto;
          & p {
            color: rgba(14, 125, 125, 1);
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
          }
        }
        &.canceled__opps {
          padding: 5px 15px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 30px;
          border: 1px solid #ff3232;
          background-color: #fff;
          margin-left: auto;
          margin-right: auto;
          & p {
            display: flex;
            align-items: center;
            color: rgba(255, 50, 50, 1);
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
          }
        }
        &.waiting__approval {
          padding: 5px 15px;
          border: 1px solid #e27a00;
          border-radius: 50px;
          display: flex;
          min-width: 170px;
          justify-content: center;
          align-items: center;
          min-height: 30px;
          background-color: #fff;
          margin-left: auto;
          margin-right: auto;
          & p {
            color: rgba(226, 122, 0, 1);
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
          }
        }
        &.not__assigned {
          padding: 5px 15px;
          border: 1px solid rgba(63, 136, 197, 1);
          border-radius: 50px;
          display: flex;
          min-width: 195px;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          min-height: 30px;
          margin-left: auto;
          margin-right: auto;
          & p {
            color: rgba(63, 136, 197, 1);
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
          }
        }
        &.picked__status {
          padding: 8px 15px;
          border-radius: 50px;
          display: flex;
          min-width: 180px;
          justify-content: center;
          align-items: center;
          min-height: 30px;
          border: 1px solid #523fc5;
          background-color: #fff;
          margin-left: auto;
          margin-right: auto;
          & p {
            margin: 0px;
            color: #523fc5;
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
          }
        }
      }
    }
    &.hover__table {
      & > tbody > tr:nth-child(1):hover {
        background-color: #fff !important;
      }
      & tr {
        transition: 0.4s ease all;
      }
      & tr:hover {
        background: #f8f8f8 !important;
      }
    }
    & .project__logo {
      min-width: 60px;
      width: 60px;
      max-width: 60px;
      & img {
        max-width: 100%;
      }
    }
    & .declarate__size {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 120px;
      max-width: 120px;
      margin-left: auto;
      margin-right: auto;
      & input[type='text'] {
        width: 100%;
        text-align: center;
        height: 30px;
        background: #ffffff;
        border: 1px solid #0e7d7d;
        border-radius: 3px;
        font-size: 13px;
        line-height: 13px;
        font-weight: 400;
        padding: 0px 5px;
      }
    }
    & .table__dropdown {
      & .select__label {
        height: 30px;
        width: 70px;
        border: 1px solid rgba(14, 125, 125, 1);
        padding-left: 10px;
        margin-left: auto;
        margin-right: auto;
        font-weight: 600;
        background-color: transparent;
        font-size: 13px;
        padding-right: 25px;
        background-color: transparent;
        &:before {
          background-color: transparent;
        }
        &:after {
          right: 10px;
        }
      }
    }
    & .status__uploaded {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      &.notsubmitted__status {
        & > p {
          padding: 4px 10px;
          background: rgba(244, 152, 13, 1);
          border-radius: 100px;
          align-items: center;
          justify-content: center;
          display: flex;
          width: auto;
          color: rgba(255, 255, 255, 1);
          font-size: 13px;
          line-height: 17px;
          white-space: nowrap;
          font-weight: 600;
          white-space: nowrap;
          min-width: 120px;
        }
      }
      &.submitted__status {
        & > p {
          padding: 4px 15px;
          background: #00c868;
          border-radius: 100px;
          align-items: center;
          justify-content: center;
          display: flex;
          width: auto;
          color: rgba(255, 255, 255, 1);
          font-size: 13px;
          line-height: 17px;
          font-weight: 600;
          white-space: nowrap;
          min-width: 120px;
          & span {
            display: inline-flex;
            margin-right: 10px;
          }
        }
      }
    }
    & .remove__uploaded--file {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > a {
        & img {
          margin-right: 10px;
          min-width: 18px;
        }
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 3px 10px;
        color: rgba(120, 120, 120, 1);
        font-size: 12px;
        line-height: 18px;
        transition: 0.4s ease all;
        font-weight: 600;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    &.small__titles {
      & td {
        & > p {
          margin: 0px;
          color: rgba(45, 45, 45, 1);
          font-size: 13px;
          line-height: 16px;
          font-weight: 600;
        }
      }
      & th {
        & h5 {
          margin: 0px;
          color: rgba(59, 59, 59, 1);
          font-size: 13px;
          line-height: 12px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
    & th {
      &.trash__row {
        width: 30px;
        padding-right: 5px !important;
      }
      & h3 {
        margin: 0px;
        color: #0e7d7d;
        font-size: 18px;
        white-space: nowrap;
        font-weight: bold;
      }
      & h4 {
        &.weight__text {
          font-weight: 600;
        }
        color: rgba(59, 59, 59, 1);
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
      }
      & h5 {
        white-space: nowrap;
        & span.info__span {
          display: inline-flex;
          margin-left: 5px;
        }
      }
    }
    & .action__small {
      width: 120px;
    }
    &.top__table {
      & td {
        & > p {
          position: relative;
          top: -3px;
        }
      }
      & tr {
        &:nth-child(1) {
          vertical-align: middle;
        }
        vertical-align: baseline;
      }
    }
    border-collapse: collapse;
    &.head__lines {
      & th {
        border-bottom: 1px solid #e3e3e3;
      }
    }
    &.line__head {
      border-collapse: separate;
      & th {
        border-bottom: 1px solid #e3e3e3;
      }
    }
    &.table__lines {
      border-collapse: separate;
      & th {
        border-bottom: 1px solid #e3e3e3;
      }
      & td {
        border-bottom: 1px solid #e3e3e3;
      }
    }
    & .action--buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & .remove__button {
        display: flex;
        align-items: center;
        justify-content: center;
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-width: 30px;
          max-width: 30px;
          min-height: 30px;
          transition: 0.4s ease all;
          max-height: 30px;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          &:hover {
            border-color: #0e7d7d;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          }
        }
      }
      & .edit__button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-width: 30px;
          max-width: 30px;
          min-height: 30px;
          transition: 0.4s ease all;
          max-height: 30px;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          &:hover {
            border-color: #0e7d7d;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    &.padding-end {
      & tr {
        & th:last-child {
          padding-right: 30px;
        }
        & td:last-child {
          padding-right: 30px;
        }
      }
    }
    &.padding-start {
      & tr {
        & th:nth-child(1) {
          padding-left: 50px;
        }
        & td:nth-child(1) {
          padding-left: 50px;
        }
      }
    }
    &.transparent__table {
      tr {
        background-color: #fff !important;
      }
    }
    & tr {
      &.group__tr {
        position: relative;

        background-color: #f8f8f8 !important;
        & td {
          padding: 0px;
        }
      }
      &.total__row {
        border-top: 1px solid #e3e3e3;
        & td {
          padding-top: 20px;
        }
      }
      & .route__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        position: relative;
        top: -3px;
        &:last-child {
          margin-bottom: 0px;
        }
        & .route__main {
          & > span {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            padding: 5px 10px;
            background: rgba(63, 136, 197, 0.15);
            font-family: 'Arvo', sans-serif;
            font-weight: 400;
            color: rgba(63, 136, 197, 1);
            font-size: 13px;
            line-height: 16px;
            word-break: break-all;
            white-space: nowrap;
          }
        }
        & .route__head {
          margin-right: 20px;
          & span {
            display: inline-flex;
            padding: 5px 10px;
            background: #3f88c5;
            border-radius: 4px;
            justify-content: center;
            align-items: center;
            color: rgba(255, 255, 255, 1);
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            min-width: 45px;
            line-height: 16px;
          }
        }
      }
      position: relative;
      &:hover {
        & .invisible__check {
          opacity: 1;
        }
      }
      & th {
        position: relative;
        & .sort__icon {
          display: inline-flex;
          margin-left: 5px;
        }
        & .search__employee {
          //position: absolute;
          left: 0px;
          height: 100%;
          background-color: #fff;
          width: 100%;
          top: -4px;
          & .employee__destroy {
            & > a {
              min-width: 27px;
              margin-left: 10px;
              max-width: 27px;
              min-height: 27px;
              max-height: 27px;
              background: #f6f6f6;
              border-radius: 3px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              transition: 0.4s ease all;
            }
          }
          & > form {
            display: flex;
            align-items: center;
            & > input[type='submit'] {
              min-width: 16px;
              max-width: 16px;
              min-height: 16px;
              max-height: 16px;
              background: url('../images/vectorsearchgrey.svg');
              background-repeat: no-repeat;
              background-size: auto;
              background-position: center;
              position: absolute;
              right: 50px;
              cursor: pointer;
              transition: 0.4s ease all;
            }
            & > input[type='text'] {
              height: 40px;
              width: 100%;
              padding-left: 15px;
              padding-right: 35px;
              font-size: 12px;
              line-height: 24px;
              font-weight: 400;
              border: 1px solid #bdbdbd;
              border-radius: 3px;
            }
          }
        }
        padding: 25px 15px;

        & .registration__dropdown {
          margin-left: auto;
          margin-right: auto;
          display: flex;
          position: relative;
          justify-content: center;
          & > h5 {
            position: absolute;
            min-width: 150px;
            min-height: 30px;
            background: #ffffff;
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
            border-radius: 10px 10px 10px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            top: -30px;
            color: rgba(61, 61, 61, 1) !important;
            font-size: 13px;
            line-height: 17px;
            pointer-events: none;
            font-weight: 400;
            opacity: 0;
            transition: 0.3s ease all;
          }
          & > .registration__button {
            &:hover {
              & + h5 {
                opacity: 1;
                top: -20px;
              }
            }
            & .btn-add__dropdown {
              padding: 10px !important;
              position: absolute;
              right: 0;
              top: 100%;
              background: #fff;
              box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
              border-radius: 3px;
              min-width: 100%;
              padding: 5px;
              margin-top: 7px;
              white-space: nowrap;
              min-width: 100px;
              display: none;
              & .btn-add__link {
                font-size: 16px !important;
                border-radius: 4px !important;
                display: block;
                padding: 8px 10px 9px 40px;
                border-radius: 3px;
                border: 1px solid #fff;
                transition: all 0.4s ease;
                position: relative;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: #0e7d7d;
                padding-left: 10px;
                text-decoration: none;
              }
            }
            & > a {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background: #ffffff;
              border: 1px solid #0e7d7d;
              box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
              border-radius: 3px;
              min-width: 65px;
              transition: 0.4s ease all;
              min-height: 40px;
              & > span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 9px;
              }
            }
          }
        }
        & .workflow__employee {
          margin-left: 0px;
        }
        & h5 {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
          &.center__text {
            text-align: center;
            justify-content: center;
          }
          & > .search__button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-width: 30px;
            max-width: 30px;
            min-height: 30px;
            max-height: 30px;
            border: 1px solid #e3e3e3;
            margin-left: 10px;
            transition: 0.4s ease all;
            border-radius: 4px;
            &:hover {
              border-color: #0e7d7d;
              box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
            }
          }
          &.transparent__text {
            color: rgba(157, 157, 157, 1);
            font-size: 14px;
            line-height: 21px;
            font-weight: 600;
            margin: 0px;
          }
        }
      }
      & td {
        &.trash__row {
          width: 20px;
          text-align: center;
        }
        & .file__droppable--column {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & > p {
            white-space: nowrap;
            font-size: 13px;
            color: rgba(59, 59, 59, 1);
            font-weight: 400;
            margin: 0px;
            margin-left: 0px;
          }
          & span {
            margin-right: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            & img {
              max-width: 24px;
            }
          }
        }
        & .permissions__list {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          min-width: 220px;
          margin-bottom: -5px;
          position: relative;
          top: -2px;
          & .elem__permission {
            padding-right: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
            & > p {
              white-space: nowrap;
              font-size: 12px;
              font-weight: 600;
              color: #fff;
              padding: 3px 10px;
              background: #18a6a6;
              border-radius: 4px;
            }
          }
        }
        & .action__drop {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        & .waiting__block.centered__table {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        & .assignation__filters {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          &.filter__chosen {
            & > a {
              min-width: 160px;
              min-height: 30px;
              font-size: 14px;
              padding-left: 55px;
              line-height: 21px;
              font-weight: 600;
              display: inline-flex;
              align-items: center;
              justify-content: flex-start;
              background: #00c868;
              border-radius: 100px;
              color: #fff;
              transition: 0.4s ease all;
              border-radius: 100px;
              position: relative;
              &:hover {
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
              }
              & span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;
                position: absolute;
                left: 20px;
                & img {
                  min-width: 14px;
                }
              }
            }
          }
          &.clear__assign--filter {
            & > a {
              min-width: 160px;
              min-height: 30px;
              padding-left: 55px;
              position: relative;
              color: rgba(45, 45, 45, 1);
              font-size: 14px;
              line-height: 21px;
              font-weight: 600;
              display: inline-flex;
              align-items: center;
              justify-content: flex-start;
              background: #ffffff;
              border: 1px solid #e3e3e3;
              transition: 0.4s ease all;
              border-radius: 100px;
              &:hover {
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
              }
              & > span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;
                position: absolute;
                left: 20px;
                & img {
                  min-width: 14px;
                }
              }
            }
          }
        }
        & .droppable__languages {
          & .languages__inner {
            & > p {
              white-space: nowrap;
              margin-right: 7px;
              color: #3b3b3b;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
        & .counter__table {
          margin-right: 10px;
          &:last-child {
            margin-right: 0px;
          }
          & > p {
            margin: 0px;
            color: rgba(59, 59, 59, 1);
            font-size: 14px;
            line-height: 21px;
            font-weight: 600;
            min-width: 27px;
          }
        }
        & .radio__group {
          min-width: 20px;
          margin-right: 10px;
          &:last-child {
            margin-right: 0px;
          }
        }
        & .assignation__profile {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          & .assignation__info {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &.assigned__user {
              & > a {
                white-space: nowrap;
                color: rgba(45, 45, 45, 1);
                font-size: 14px;
                line-height: 21px;
                font-weight: 600;
                text-decoration: underline;
                transition: 0.4s ease all;
              }
            }
            &.empty__assignment {
              & > p {
                color: rgba(158, 158, 158, 1);
                font-size: 14px;
                white-space: nowrap;
                line-height: 21px;
                font-weight: 600;
              }
            }
            &.planned__assignation {
              & > a {
                display: inline-flex;
                margin-left: 20px;
                justify-content: center;
                align-items: center;
                transition: 0.4s ease all;
              }
              & > p {
                white-space: nowrap;
                color: rgba(45, 45, 45, 1);
                font-size: 14px;
                line-height: 21px;
                font-weight: 600;
              }
            }
          }
          & .assignation__image {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            & img {
              min-width: 35px;
              max-width: 35px;
              min-height: 35px;
              max-height: 35px;
              border-radius: 250px;
              object-fit: cover;
            }
          }
        }
        & .assignation__main {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &:before {
          content: '';
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          position: absolute;
          left: -1px;
          top: 0px;
          height: 100%;
          width: 0px;
          background-color: #0e7d7d;
          transition: 0.2s ease all;
          z-index: 2;
          top: -1px;
          opacity: 0;
        }
        &.active--check {
          &:before {
            opacity: 1;
            width: 5px;
          }
          & .check__input {
            opacity: 1 !important;
          }
        }
        & .invisible__check {
          opacity: 0;
          transition: 0.4s ease all;
        }
        & .centered__check {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.id__row {
          width: 140px;
        }
        padding: 7px 15px;
        & > p {
          white-space: nowrap;
        }
        & > a {
          white-space: nowrap;
        }
        & p {
          &.center__text {
            text-align: center;
            justify-content: center;
          }
          margin: 0px;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          & span {
            &.transparent__text {
              color: rgba(158, 158, 158, 1);
            }
          }
          &.transparent__text {
            color: rgba(158, 158, 158, 1);
          }
          &.hover__text {
            background-color: #fff;
            border-radius: 2px;
            display: inline-flex;
            padding: 5px 10px;
          }
          &.weight__text {
            font-weight: 600;
          }
        }
      }
      & .client__element {
        & > a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          white-space: nowrap;
          &.underline__profile {
            & .client__info {
              & p {
                text-decoration: underline !important;
              }
            }
          }
          & .client__info {
            & p {
              margin: 0px;
              font-weight: 700;
              font-size: 14px;
              white-space: nowrap;
            }
          }
          & .client__image {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            & img {
              width: 40px;
              min-width: 40px;
              min-height: 40px;
              height: 40px;
              border-radius: 250px;
              object-fit: cover;
            }
          }
        }
      }
      &:nth-child(even) {
        background-color: #f7f7f7;
      }
    }
  }
}

.float__table--content .choose-actions-dropdown__box {
  min-width: 240px;
  right: auto;
  margin-top: 25px;
  transform: translateX(-100%);
  margin-left: 20px;
}
.table__common .waiting__block {
  min-width: 165px;
}
.float__table--dropdown {
  right: auto;
  min-width: 0px;
  margin-top: 30px;
}
.status__hint {
  bottom: auto;
  z-index: 3;
  margin-top: -10px;
  transform: translateY(-100%);
}
.group__line {
  content: '';
  background-color: #3f88c5;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 200px;
  width: 3px;
  opacity: 1;
  z-index: 3;
  display: none;
}

.float__table--content .select__dropdown {
  right: auto;
  // transform: translateX(-100%);
  margin-left: 0px;
  margin-top: 25px;
  min-width: 0px;
}
.table__common .source__picker {
  display: inline-flex;
}
.time__job--dropdown {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  position: absolute;
  left: 50%;
  transform: translateX(-15%);
  min-width: 200px;
  max-width: 200px;
  padding: 10px;
  margin-top: 40px;
  z-index: 3;
  display: none;
  & > p {
    width: 100%;
    min-height: 28px;
    padding: 5px 15px;
    border: 1px solid #e3e3e3;
    border-radius: 100px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #9e9e9e;
    margin-bottom: 5px;
    & .edit__time {
      margin-left: 10px;
    }
    & span {
      color: #3b3b3b;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.float__table--content {
  & .status__drop {
    background: #ffffff;
    z-index: 3;
    width: 100%;
    max-width: 205px;
    min-width: 205px;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 20px 25px;
    position: absolute;
    margin-top: 40px;
  }
  & .comment__inner {
    position: absolute;
    margin-top: 30px;
    left: 0px;
    min-width: 270px;
    max-width: 270px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    display: none;
    z-index: 3;
    & .comment__buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      & > input[type='submit'] {
        display: inline-flex;
        font-size: 14px;
        color: #fff;
        line-height: 14px;
        font-weight: 600;
        min-height: 40px;
        background: #0e7d7d;
        border: 2px solid #0e7d7d;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        justify-content: center;
        align-items: center;
        transition: 0.4s ease all;
        border-radius: 3px;
        &:hover {
          opacity: 0.7;
        }
      }
      & > a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        width: 100%;
        transition: 0.4s ease all;
        border: 1px solid #0e7d7d;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        color: #0e7d7d;
        transition: 0.4s ease all;
        border-radius: 3px;
      }
    }
    & .reply__comment {
      margin-bottom: 15px;
      & input {
        width: 100%;
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid #bdbdbd;
        border-radius: 3px;
        color: #000;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        transition: 0.4s ease all;
      }
    }
    & .comment__users {
      max-height: 200px;
      overflow-y: auto;
      width: calc(100% + 20px);
      padding-right: 20px;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: #ebebef;
        border-radius: 5px;
      }
      & .comment__user {
        margin-bottom: 15px;
        border-bottom: 1px solid #e3e3e3;
        & .comment__desc {
          margin-bottom: 15px;
          & > p {
            margin: 0px;
            color: #2d2d2d;
            font-size: 14px;
            font-weight: 400;
          }
        }
        & .comment__head {
          display: flex;
          align-content: center;
          justify-content: space-between;
          margin-bottom: 15px;
          & > span {
            color: #9e9e9e;
            font-size: 14px;
            font-weight: 400;
            display: flex;
            align-items: center;
          }
          & > a {
            display: flex;
            align-content: center;
            justify-content: flex-start;
            transition: 0.4s ease all;
            & > p {
              margin: 0px;
              font-size: 14px;
              color: #2d2d2d;
              font-weight: 600;
              display: flex;
              align-items: center;
              text-decoration: underline;
            }
            & .comment__image {
              display: flex;
              margin-right: 15px;
              align-items: center;
              justify-content: center;
              & img {
                max-width: 30px;
                min-width: 30px;
                border-radius: 250px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.float__table--content {
  & .droppable__small {
    position: absolute;
    min-width: 200px;
    max-width: 200px;
    padding: 10px;
    background: #ffffff;
    margin-top: 35px;
    z-index: 2;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    & .elem__drop--small {
      width: 100%;
      padding: 5px 15px;
      border: 1px solid #e3e3e3;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      & p {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
}
.choose-actions-dropdown__toggle-icon.button__action svg {
  position: relative;
}
.placeholder__droppable .placeholder__worker {
  padding-left: 80px;
}

.modal__service {
  & .switcher__service {
    margin-bottom: 30px;
    & ul {
      padding: 0px;
      list-style-type: none;
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > li {
        display: inline-flex;
        margin-right: 15px;
        &:last-child {
          margin-right: 0px;
        }
        &.curr__service {
          & > a {
            background-color: #0e7d7d;
            color: #fff;
          }
        }
        & > a {
          color: #3b3b3b;
          font-weight: 600;
          font-size: 14px;
          padding: 10px 25px;
          background-color: #f7fbfb;
          border-radius: 4px;
          transition: 0.4s ease all;
          &:hover {
            background-color: #0e7d7d;
            color: #fff;
          }
        }
      }
    }
  }
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.7);
  & .service__file {
    & .service__file--loaded {
      & .inner__loaded {
        & > a {
          transition: 0.4s ease all;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    margin-top: 30px;
    & > p {
      margin-bottom: 10px;
      margin-top: 0px;
      color: #444444;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
    }
    & .service__file--picker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      & > input {
        position: absolute;
        left: -3000px;
      }
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 160px;
        min-height: 45px;
        border: 1px solid #0e7d7d;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        position: relative;
        color: #0e7d7d;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        transition: 0.4s ease all;
        &:hover {
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
        &:active,
        &:focus {
          border-color: rgba(14, 125, 125, 0.3);
        }
        & span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 20px;
        }
      }
    }
  }
  & .price__options {
    margin-top: 30px;
    & > p {
      margin: 0px;
      margin-bottom: 15px;
      color: #444444;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
    }
    & .inner__price--option {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 25px;
      & > .elem__price--option {
        & .input {
          text-align: right;
          height: 50px;
        }
        & .select {
          min-width: 85px;
        }
        & > p {
          margin-top: 0px;
          color: #444444;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          margin-bottom: 10px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  & .group__check {
    & .elem__advanced--picker {
      margin-top: 30px;
    }
    & .check__dropdown {
      margin-top: 30px;
    }
    & label {
      & span {
        color: #2d2d2d;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
      }
    }
  }
  & .matt__group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 81px;
    margin-bottom: 30px;
    & .unit__group {
      max-width: 135px;
      min-width: 135px;
      display: none;
      & input {
        height: 50px;
      }
      & > p {
        margin: 0px;
        margin-bottom: 10px;
        color: #2d2d2d;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
      }
    }
    & .default__matt {
      min-width: 135px;
      max-width: 135px;
      display: none;
      & .select__label {
        height: 50px;
      }
      & > p {
        margin: 0px;
        margin-bottom: 10px;
        color: #2d2d2d;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
      }
    }
    & .matt__selection {
      min-width: 230px;
      max-width: 230px;

      & .default__matt {
        & > p {
          margin: 0px;
          margin-bottom: 10px;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
        }
      }
      & .elem__advanced--picker {
        & > p {
          margin: 0px;
          margin-bottom: 10px;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
        }
      }
    }
  }
  & .group__field {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0px;
    }
    & > p {
      margin: 0px;
      margin-bottom: 10px;
      color: #2d2d2d;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
    }
    &.big__field {
      & input {
        height: 50px;
      }
    }
  }
  & .service__controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 70px;
    & > a:nth-child(1) {
      min-width: 125px;
      max-width: 125px;
      margin-right: 15px;
    }
    & > a:nth-child(2) {
      min-width: 125px;
      max-width: 125px;
    }
  }
  & > .inner__modal--service {
    width: 100%;
    max-width: 660px;
    right: -675px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #ffffff;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    padding: 50px 45px;
    transition: 0.4s ease all;
    & > form {
      & > h2 {
        margin-top: 0px;
        margin-bottom: 40px;
        color: rgba(14, 125, 125, 1);
        font-size: 25px;
        line-height: 34px;
        font-weight: 700;
      }
    }
  }
  & > a {
    position: absolute;
    top: 70px;
    right: 675px;
    transition: 0.4s ease all;
    opacity: 0;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    &:hover {
      background: #5c5a64;
    }
    &:active,
    &:focus {
      border-color: #fff;
    }
  }
}
.service__file--loaded {
  margin-top: 15px;
  & .inner__loaded {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & .image__loaded {
      display: flex;
      margin-right: 15px;
      align-items: center;
      justify-content: center;
      min-width: 45px;
      max-width: 45px;
      min-height: 45px;
      max-height: 45px;
      border-radius: 5px;
      background: url('../images/patterimage.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding: 5px;
      & img {
        max-width: 100%;
      }
    }
  }
}
.message__universal {
  & .message__wrapper {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &.error {
      border: 2px solid #fdb913;
    }
    &.info {
      border: 2px solid #0e7d7d;
    }
    & .message__left {
      display: flex;
      align-items: center;
      & > h2 {
        margin: 0px;
        color: #3d3d3d;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
      justify-content: flex-start;
    }

    & .message__image {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      height: 40px;
      & > img {
        max-width: 60px;
        height: 100%;
      }
    }
  }
}
.status__employers {
  margin-bottom: 25px;
  & .correction__status {
    border: 2px solid #00c868;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    width: 100%;
    background: #ffffff;
    border: 2px solid #fdb913;
    border-radius: 5px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    & .correction__left {
      display: flex;
      align-items: center;
      & > h2 {
        margin: 0px;
        color: #3d3d3d;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
      justify-content: flex-start;
    }
    & .correction__info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & .correction__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > a {
          white-space: nowrap;
          &:nth-child(1) {
            min-width: 200px;
            margin-right: 20px;
          }
          &:nth-child(2) {
            min-width: 175px;
          }
        }
      }
      & > h2 {
        margin: 0px;
        margin-right: 40px;
        color: #3d3d3d;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    }
    & .correction__image {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      & img {
        max-width: 60px;
      }
    }
  }

  & .submitted__status {
    border: 2px solid #00c868;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    & .submitted__text {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & a {
        position: absolute;
        right: 30px;
        min-width: 115px;
        max-width: 115px;
      }
      & h2 {
        color: #3d3d3d;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin: 0px;
        white-space: nowrap;
        margin-right: 40px;
      }
    }
    & .submitted__image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      & img {
        max-width: 60px;
      }
    }
  }
  & .finish__info {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    & .finish__text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > a {
        max-width: 160px;
        position: absolute;
        right: 30px;
        min-width: 160px;
      }
      & > h2 {
        white-space: nowrap;
        margin: 0px;
        margin-right: 40px;
        font-size: 16px;
        line-height: 24px;
        color: #3d3d3d;
        font-weight: 600;
      }
    }
    & .finish__img {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      & img {
        max-width: 60px;
      }
    }
  }
  & .assigned__info {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    & .assigned__text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > a {
        position: absolute;
        right: 30px;
        min-width: 115px;
      }
      & > h2 {
        margin: 0px;
        margin-right: 40px;
        color: #3d3d3d;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    }
    & .assigned__img {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      & img {
        max-width: 60px;
      }
    }
  }
  & .success__status {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-right: 270px;
    & .success__note {
      min-width: 230px;
      min-height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #fafafa;
      border-radius: 5px;
      position: absolute;
      right: 30px;
      & p {
        margin: 0px;
        color: #2d2d2d;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
      & span {
        color: #2d2d2d;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
      }
    }
    & .success__info {
      & .success__text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > span {
          min-width: 148px;
          margin-left: 7px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 4px 15px;
          background: #e6faf0;
          border-radius: 50px;
          color: #0e7d7d;
          font-size: 12px;
          line-height: 14px;
          font-weight: 600;
          & img {
            margin-right: 5px;
          }
        }
        & > p {
          margin: 0px;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }
      }
      & > h2 {
        margin: 0px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #000;
        margin-bottom: 10px;
        & span {
          color: #00c868;
        }
      }
    }
    & .success__image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 50px;
      & img {
        max-width: 51px;
      }
    }
  }
  & .deadline__status {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .deadline__left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & h6 {
        margin: 0px;
        margin-right: 40px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #000;
      }
    }
    & .deadline__info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & .datepicker {
        min-width: 230px;
      }
      & .choose__deadline {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & form {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & input[type='submit'] {
            min-width: 190px;
            min-height: 45px;
            border: 0px;
            background: #0e7d7d;
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
            border-radius: 3px;
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            color: #fff;
            margin-left: 15px;
            transition: 0.2s ease all;
            cursor: pointer;
            &:hover {
              background-color: #0a5858;
            }
            &:active {
              color: #ffffff;
              background-color: #094f4f !important;
            }
            &:focus {
              color: #ffffff;
              background-color: #0a5858;
              -webkit-box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
            }
          }
        }
      }
    }
    & .deadline__image {
      margin-right: 35px;
      & img {
        max-width: 65px;
      }
    }
  }
}
.freelancer__box .freelancer__main {
  & .employers-page__heading {
    width: 100%;
    max-width: 100%;
  }
}
.units__wrp {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  & .input {
    height: 50px;
  }
  & .select {
    & .select__label {
      height: 50px;
    }
  }
  & .unit__el {
    & p {
      margin: 0px;
      color: #444444;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
    }
  }
  & .row-group__item {
    margin-right: 0px;
  }
}
.add__more--services {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  & > a {
    color: #0e7d7d;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    min-height: 40px;
    border: 1px solid #0e7d7d;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    transition: 0.4s ease all;
    &:active,
    &:focus {
      color: #0a6161;
      box-shadow: inset 0 0 0 3px rgba(14, 125, 125, 0.3);
    }
    &:hover {
      color: #0a6161;
      box-shadow: inset 0 0 0 2px #0e7d7d;
    }
    & span {
      display: inline-flex;
      margin-right: 10px;
    }
  }
}
.new__service.modal__wrapper {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 5;
  & .inner__service--create {
    width: 100%;
    max-width: 640px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #ffffff;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    padding: 50px 45px;
    right: -650px;
    transition: 0.4s ease all;
    & .more__button {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: -20px;
      & a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 170px;
        min-height: 40px;
        border: 1px solid #0e7d7d;
        // box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        font-size: 14px;
        color: #0e7d7d;
        line-height: 14px;
        font-weight: 600;
        transition: 0.4s ease all !important;
        &:hover {
          border-color: #0e7d7d;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
        & span {
          margin-right: 10px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    & .group__service {
      & .select__label {
        height: 50px;
      }
      & .double__service {
        display: grid;
        grid-template-columns: repeat(2, 140px);
        grid-column-gap: 25px;
      }
      & .triple__service {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0px;
        }
        & .fee__group {
          position: relative;
          padding-left: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center !important;
          & > p {
            text-align: left;
            width: 100%;
          }
          & span {
            margin-top: 32px;
            position: absolute;
            left: -8px;
            color: #444444;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
          }
        }
        & > .group__service {
          // margin-right:25px;
          margin-bottom: 0px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          width: 32%;
          &:nth-child(1) {
            margin-right: 4%;
            width: 28%;
          }
          &:nth-child(2) {
            margin-right: 4%;
            width: 28%;
          }
          &:last-child {
            width: 34%;
            margin-right: 0px;
            align-items: flex-end;
          }
        }
        & .custom__select {
          & .select {
            width: auto;
            padding-right: 10px;
          }
          & .select__label {
            height: auto;
            background-color: transparent;
            overflow: visible;
            height: auto;
            border: 0px;
            padding: 0px;
            padding-right: 10px;
          }
        }
        & .currency__group {
          & > p {
            width: 100%;
          }
        }
        & .currency__picker {
          margin-left: 0px;
          position: relative;
          display: flex;
          align-items: center;
          & span {
            color: #444444;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            position: absolute;
            left: -8px;
          }
        }
        & input {
          padding-left: 20px;
          width: 100%;
          padding-right: 20px;
          height: 50px;
          background: #f7f7f7;
          border-radius: 3px;
        }
      }
      margin-bottom: 30px;
      & > input {
        padding-left: 20px;
        width: 100%;
        padding-right: 20px;
        height: 50px;
        background: #f7f7f7;
        border-radius: 3px;
      }
      & > p {
        margin: 0px;
        margin-bottom: 10px;
        color: #2d2d2d;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
      }
    }
    & form {
      & > h2 {
        color: #0e7d7d;
        margin: 0px;
        font-size: 25px;
        margin-bottom: 25px;
      }
    }
  }
  & > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 70px;
    right: 650px;
    transition: 0.4s ease all;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    transition: 0.4s ease all;
    &:hover {
      background: #5c5a64;
    }
    &:active,
    &:focus {
      border-color: #fff;
    }
  }
  & .service__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 50px;
    & a {
      min-width: 115px;
      min-height: 45px;
      border: 1px solid #0e7d7d;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }
    & input[type='submit'] {
      min-width: 155px;
      max-width: 155px;
      margin-right: 15px;
      min-height: 45px;
      background: #0e7d7d;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      font-size: 14px;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      line-height: 14px;
      transition: 0.2s ease all;
      &:hover {
        background-color: #0a5858;
      }
      &:active {
        color: #ffffff;
        background-color: #094f4f !important;
      }
      &:focus {
        color: #ffffff;
        background-color: #0a5858;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
      }
    }
  }
}
.picker__v--currency {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & .select {
    margin-left: 10px;
  }
}
.more__dynamic {
  margin-top: 40px;
  & .more__button {
    margin-top: -8px !important;
  }
  & .double__service {
    & .fee__group {
      position: relative;
      & > p {
        padding-left: 15px;
      }
      & .select {
        padding-left: 15px;
      }
      & > span {
        margin-top: 15px;
        left: -9px;
        display: inline-flex;
        position: absolute;
        color: #444444;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }
}
.size__graph {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & a {
    &:hover {
      border-color: #0e7d7d;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    }
    transition: 0.4s ease all;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 12px;
    border: 1px solid #e3e3e3;
    border-radius: 100px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #2d2d2d;
    & span {
      display: inline-flex;
      margin-right: 12px;
      align-items: center;
      & img {
        min-width: 14px;
      }
      justify-content: center;
    }
  }
}
.files__padding {
  padding-left: 25px;
}
.width__remover {
  width: 1%;
  white-space: nowrap;
}
.table__wrapper table.table__common {
  & .size__metrix {
    padding: 0px 15px;
  }
  & .size__metrix p {
    margin: 0px;
    padding: 3px 12px;
    white-space: nowrap;
    color: #787878;
    border-radius: 100px;
    font-size: 10px;
    line-height: 18px;
    font-weight: 600;
    display: inline-flex;
    background-color: #f2f2f2;
  }
  & .file__open {
    padding: 0px 15px;
    & a {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      min-height: 30px;
      padding: 1px 15px;
      border-radius: 4px;
      border: 1px solid #e3e3e3;
      color: #0e7d7d;
      min-width: 70px;
      font-size: 12px;
      line-height: 24px;
      font-weight: 600;
      -webkit-transition: 0.4s ease all;
      transition: 0.4s ease all;
      &:hover {
        border-color: #0e7d7d;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.download__all .translate__button {
  margin-right: 15px;
}
table.submitted__table {
  & > tbody {
    & > tr {
      & > td {
        padding-bottom: 0px !important;
      }
    }
  }
}
.head__submitted--files .head__size {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & .total__size {
    margin-right: 10px;
    & a {
      transition: 0.4s ease all;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      min-height: 30px;
      &:hover {
        border-color: #0e7d7d;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
      & span {
        display: inline-flex;
        margin-right: 12px;
        align-items: center;
        justify-content: center;
      }
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 5px 12px;
      border: 1px solid #e3e3e3;
      border-radius: 100px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      color: #2d2d2d;
    }
  }
}

.translation__droppable--content {
  display: none;
}
.elem__droppable--translation {
  // display: none;
  padding: 5px 0px;
  & > .translation__words--counter {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.source__settings {
  & .custom__select {
    & > input {
      min-height: 25px;
      background: #f7f7f7;
      border-radius: 3px;
      min-width: 60px;
      max-width: 60px;
      text-align: right;
      padding-right: 10px !important;
      padding-left: 10px !important;
      margin-right: 15px;
      color: #444444;
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      max-height: 25px;
    }
  }
}
.job__controls {
  // display: flex;
  // align-items:center;
  & .job__controls--wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    & .burger__menu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .burger__button {
      min-width: 33px;
      min-height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      transition: 0.4s ease all;
      &:hover {
        border-color: #0e7d7d;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
      & > span {
        width: 15px;
        &:before {
          width: 15px;
          top: -5px;
        }
        &:after {
          bottom: -5px;
          width: 15px;
        }
      }
    }
    & > .job__button {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      min-height: 30px;
      padding: 1px 15px;
      border-radius: 4px;
      border: 1px solid #e3e3e3;
      color: #0e7d7d;
      min-width: 70px;
      font-size: 12px;
      line-height: 24px;
      font-weight: 600;
      -webkit-transition: 0.4s ease all;
      transition: 0.4s ease all;
      margin-right: 10px;
      &:hover {
        border-color: #0e7d7d;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.file__table {
  & tr {
    & > td:nth-child(1) {
      padding-left: 50px !important;
    }
  }
}
.jobs__table--services {
  & .elem__service {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & span {
      display: inline-flex;
      margin-left: 10px;
      color: #2d2d2d;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
.float__table--content {
  & .dropdown__box {
    position: absolute;
    margin-top: 35px;
    margin-left: 32px;
  }
}
.burger__float {
  min-width: 33px;
  min-height: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  &:hover {
    border-color: #0e7d7d;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  }
  & span {
    &:after {
      border-radius: 4px;
      position: absolute;
      content: '';
      left: 0;
      width: 25px;
      height: 2px;
      background-color: #0e7d7d;
      bottom: -5px;
      width: 15px;
    }
    &:before {
      border-radius: 4px;
      position: absolute;
      content: '';
      left: 0;
      width: 25px;
      height: 2px;
      background-color: #0e7d7d;
      width: 15px;
      top: -5px;
    }
    position: absolute;
    // top: 50%;
    // -webkit-transform: translateY(-50%);
    // transform: translateY(-50%);
    display: block;
    width: 15px;
    height: 2px;
    background-color: #0e7d7d;
    border-radius: 4px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
}
.advanced__settings {
  padding: 15px 20px;
  margin-bottom: 30px;
  background: #f7f7f7;
  border-radius: 10px;
  & .content__advanced {
    & .elem__advanced {
      & .quality__container {
        & .elem__quality {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0px;
          }
          & .quality__el {
            border-bottom: 1px solid #ebebeb;
            padding: 10px 15px;
            position: relative;
            & .quality__events {
              & a {
                white-space: nowrap;
              }
            }
            &.ignore__quality {
              &:before {
                content: '';
                position: absolute;
                right: 0px;
                top: 0px;
                width: 5px;
                height: calc(100% + 1px);
                background-color: #787878;
              }
              & a {
                position: relative;
                & p {
                  color: #787878;
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 600;
                }
                & span {
                  & svg {
                    & path {
                      fill: #787878;
                    }
                  }
                }
              }
            }
            &.notify__quality {
              position: relative;
              &:before {
                content: '';
                position: absolute;
                right: 0px;
                top: 0px;
                width: 5px;
                height: calc(100% + 1px);
                background-color: #54b98c;
              }
              & a {
                position: relative;
                & p {
                  color: #54b98c;
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 600;
                }
                & span {
                  & svg {
                    & path {
                      fill: #54b98c;
                    }
                  }
                }
              }
            }
            &.error__quality {
              position: relative;
              &:before {
                content: '';
                position: absolute;
                right: 0px;
                top: 0px;
                width: 5px;
                height: calc(100% + 1px);
                background-color: #f4284d;
              }
              & a {
                position: relative;
                & p {
                  color: #f4284d;
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 600;
                }
                & span {
                  & svg {
                    & path {
                      fill: #f4284d;
                    }
                  }
                }
              }
            }
            & > .quality__events {
              margin-left: 20px;
              position: relative;
              & .quality__drop {
                position: absolute;
                right: 0px;
                top: 40px;
                z-index: 4;
                padding: 5px;
                background: #ffffff;
                box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                display: none;
                margin-top: -15px;
                // width: 100%;
                & ul {
                  width: 100%;
                  & li {
                    display: flex;
                    width: 100%;
                    & a {
                      font-weight: 600;
                      transition: 0.4s ease all;
                      border-radius: 4px;
                      &.ignore__pick {
                        color: #787878;
                        &:hover {
                          background: rgba(120, 120, 120, 0.1);
                        }
                      }
                      &.notify__pick {
                        color: #54b98c;
                        &:hover {
                          background: rgba(84, 185, 140, 0.1);
                        }
                      }
                      &.require__pick {
                        color: #f4284d;
                        &:hover {
                          background: rgba(244, 40, 77, 0.1);
                        }
                      }
                      display: flex;
                      width: 100%;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 8px 10px;
                      white-space: nowrap;
                    }
                  }
                }
              }
              & > a {
                display: inline-flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;
                & span {
                  display: inline-flex;
                  margin-left: 12px;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
            & > p {
              margin: 0px;
              color: #2d2d2d;
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
            }
            background: #ffffff;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          & .head__quality {
            padding: 10px 15px;
            background: #ededed;
            border-radius: 2px;
            & p {
              margin: 0px;
              color: #2d2d2d;
              font-size: 14px;
              line-height: 24px;
              font-weight: 600;
            }
          }
        }
      }
      & .glossary__info {
        & .content__glossary {
          width: 100%;
          & .el__glossary {
            display: flex;
            margin-bottom: 10px;
            &.glossary__chosen {
              & .glossary__content {
                & .glossary__main {
                  & .glossary__bottom {
                    display: flex;
                  }
                }
              }
            }
            &:last-child {
              margin-bottom: 0px;
            }
            background: #ffffff;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            justify-content: space-between;
            &.glossary__client {
              & .glossary__main {
                & .glossary__top {
                  & .glossary__radio {
                    display: block !important;
                  }
                }
                & .glossary__bottom {
                  display: flex !important;
                }
              }
              & .glossary__image {
                background-color: #3f88c5;
                & span {
                  display: inline-flex;
                }
              }
            }
            & .glossary__content {
              width: 100%;
              & .glossary__main {
                width: 100%;

                & .glossary__bottom {
                  display: none;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 15px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  border-top: 1px solid #ebebeb;
                  padding-right: 23px;
                  & > p {
                    color: #787878;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    margin: 0px;
                  }
                }
                & .glossary__top {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 15px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  padding-right: 23px;
                  & .glossary__radio {
                    display: none;
                  }
                  & .glossary__buttons {
                    min-width: 50px;
                  }
                  & .glossary__right--complex {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    & .glossary__radio {
                      margin-right: 20px;
                      & label {
                        white-space: nowrap;
                        color: #787878;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;
                        & .radio__box {
                          margin-right: 10px;
                          min-width: 20px;
                          max-width: 20px;
                        }
                      }
                    }
                  }
                  & .glossary__buttons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    & > a {
                      &:last-child {
                        margin-right: 0px;
                      }
                      display: -webkit-inline-box;
                      display: -ms-inline-flexbox;
                      display: inline-flex;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      -webkit-box-pack: center;
                      -ms-flex-pack: center;
                      justify-content: center;
                      margin-right: 20px;
                      -webkit-transition: 0.4s ease all;
                      transition: 0.4s ease all;
                      &:hover {
                        opacity: 0.7;
                      }
                    }
                  }
                  & .glossary__info {
                    & > span {
                      display: inline-flex;
                      color: #2d2d2d;
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: 600;
                    }
                    & > p {
                      margin: 0px;
                      color: #787878;
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: 400;
                    }
                  }
                }
                &:last-child {
                  border-bottom: 0px;
                }
                & .glossary__info {
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                }
              }
            }
            & .glossary__image {
              min-width: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              background-color: #0e7d7d;
              padding: 10px;
              & > span {
                color: #ffffff;
                font-size: 14px;
                line-height: 24px;
                font-weight: 600;
                display: inline-flex;
                margin-bottom: 8px;
                display: none;
              }
              & > img {
                max-width: 100%;
              }
            }
          }
        }
        & .head__glossary {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          & > p {
            margin: 0px;
            color: #2d2d2d;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
          }
          & > .add__glossary {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            & > a {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 5px 15px;
              min-width: 80px;
              min-height: 30px;
              color: #0e7d7d;
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              transition: 0.4s ease all;
              background-color: #fff;
              border-radius: 3px;
              box-shadow: 0px 3px 10px 0px #0000001a;
              border: 1px solid #0e7d7d;
              & span {
                display: inline-flex;
                margin-right: 10px;
                align-items: center;
                justify-content: center;
                & img {
                  min-width: 14px;
                }
              }
            }
          }
        }
      }
      & .translation__memories {
        & .memories__list {
          & .elem__memory {
            &.memory__chosen {
              & .memory__top {
                & .right__memory {
                  & .top__memory--edit {
                    display: flex !important;
                  }
                }
              }
              & .memory__bottom {
                & .mem__bottom {
                  display: flex !important;
                }
              }
            }
            width: 100%;
            display: flex;
            margin-bottom: 10px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            background-color: #fff;
            &:last-child {
              margin-bottom: 0px;
            }
            & .memory__content {
              width: 100%;

              & .memory__bottom {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                & .mem__bottom {
                  padding: 10px 15px;
                  display: flex;
                  align-items: flex-start;
                  display: none;
                  & .match__info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    & .custom__select {
                      & .select__dropdown {
                        left: 0px;
                        margin-top: 4px;
                      }
                      & .select {
                        padding-right: 15px;
                        padding-left: 10px;
                      }
                      border-bottom: 1px solid #0e7d7d;
                      padding-bottom: 3px;
                      & button {
                        color: #2d2d2d;
                        font-size: 13px;
                        line-height: 21px;
                        font-weight: 400;
                      }
                    }
                    & > p {
                      margin: 0px;
                      margin-right: 10px;
                      color: #787878;
                      font-size: 14px;
                      line-height: 21px;
                      font-weight: 400;
                    }
                  }
                  justify-content: center;
                  & > span {
                    color: #787878;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                  }
                  & > p {
                    margin: 0px;
                    color: #787878;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                  }
                  flex-direction: column;
                  &:nth-child(1) {
                    border-right: 1px solid #ebebeb;
                  }
                  &:nth-child(2) {
                    padding-left: 30px;
                  }
                }
              }
              & .memory__top {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding-left: 15px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 23px;
                border-bottom: 1px solid #ebebeb;
                align-items: center;
                & .right__memory {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  & .top__memory--edit {
                    display: none;
                    align-items: center;
                    justify-content: flex-end;
                    & .radio__group {
                      & label {
                        color: #787878;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;
                        & .radio__box {
                          margin-right: 10px;
                          min-width: 20px;
                        }
                      }
                    }
                  }
                  & .memory__buttons {
                    margin-left: 30px;
                    & > a {
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      margin-right: 20px;
                      transition: 0.4s ease all;
                      &:hover {
                        opacity: 0.7;
                      }
                      &:last-child {
                        margin-right: 0px;
                      }
                    }
                  }
                }

                & .top__memory--info {
                  & > span {
                    color: #2d2d2d;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 600;
                  }
                  & > ul {
                    padding: 0px;
                    list-style-type: none;
                    margin: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    & > li {
                      &.spacer__memory {
                        margin: 0px 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                      color: #787878;
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
            & .memory__head {
              background: #0e7d7d;
              border-radius: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px 15px;
              & span {
                display: inline-flex;
                color: #ffffff;
                font-size: 14px;
                line-height: 24px;
                font-weight: 600;
              }
            }
          }
        }
        & .head__memories {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          & > p {
            margin: 0px;
            color: #2d2d2d;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
          }
          & > .add__memories {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            & > a {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 5px 15px;
              min-width: 80px;
              min-height: 30px;
              color: #0e7d7d;
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              transition: 0.4s ease all;
              background-color: #fff;
              border-radius: 3px;
              box-shadow: 0px 3px 10px 0px #0000001a;
              border: 1px solid #0e7d7d;
              & span {
                display: inline-flex;
                margin-right: 10px;
                align-items: center;
                justify-content: center;
                & img {
                  min-width: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
  & .head__advanced {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    & .advanced__tab--switcher {
      & ul {
        list-style-type: none;
        margin: 0px;
        padding: 3px;
        background: #ededed;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > li {
          margin-right: 10px;
          &:last-child {
            margin-right: 0px;
          }
          &.active__advanced {
            & a {
              background: #ffffff;
              border-radius: 5px;
              color: #0e7d7d;
            }
          }
          & a {
            &:hover {
              background-color: #fff;
              color: #0e7d7d;
            }
            padding: 7px 15px;
            font-size: 11px;
            line-height: 21px;
            font-weight: 600;
            color: #787878;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            transition: 0.4s ease all;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
.drop__add {
  right: 0px;
  left: auto !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.add__glossary {
  position: relative;
}
.drop__add {
  position: absolute;
  left: 0px;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  top: 100%;
  display: none;
  margin-top: 7px;
  & > p {
    white-space: nowrap;
    padding: 10px;
    color: #9e9e9e;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
  & ul {
    width: 100%;
    & li {
      width: 100%;
      & a {
        width: 100%;
        padding: 10px;
        display: inline-flex;
        align-items: center;
        color: #2d2d2d;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        text-decoration: none;
        justify-content: flex-start;
        white-space: nowrap;
      }
    }
  }
}

.box-shadow-none {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.switcher + label {
  display: block;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  float: none;
  width: 32px;
  height: 12px;
  line-height: 24px;
  margin: 6px;
  padding: 0;
  cursor: pointer;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  background-color: #fff;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  z-index: 1;
  background: #9ec9c9;
  border-radius: 10px;
}
.switcher + label:before {
  content: '';
  display: block;
  min-height: 16px;
  max-height: 16px;
  max-width: 16px;
  line-height: 20px;
  min-width: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 11px;
  padding: 0px;
  margin: 0px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  background: #e2e3e4;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: -2px;
  left: 0px;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  z-index: 2;
  background: #0e7d7d;
  /* Btn shadow */

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.switcher {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}
.switcher:active:not(:disabled) + label {
  border-color: rgba(240, 147, 46, 0.4);
  background: rgba(240, 147, 46, 0.2);
  color: #f0932e;
}

.switcher:checked + label:before {
  left: 15px;
}
.switcher:disabled + label {
  background-color: #f3f3f3;
  cursor: not-allowed;
  pointer-events: none;
}
.switcher:disabled + label:before {
  background: #f3f3f3;
  border-color: #eaeaea;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.04);
  left: 2px;
}
input[type='radio']:checked:not(:focus) + label {
  cursor: not-allowed;
  pointer-events: none;
}

.advanced__settings {
  & .switcher__advanced {
    margin-right: 20px;
    & label {
      color: #2d2d2d;
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}
h5.head__file {
  color: rgba(14, 125, 125, 1);
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
}
.upload__block {
  & .documents__files {
    padding: 15px 20px;
    & .docs__list {
      overflow-x: auto;
      & .el__doc {
        & table {
          width: 100%;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          border-spacing: 0px;
          & tr {
            width: 100%;
            & td {
              padding: 0px;
              // width: 100%;
              &:last-child {
                width: 1%;
                padding-right: 10px;
                white-space: nowrap;
                padding-left: 30px;
              }
              & .remover {
                transition: 0.4s ease all;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                padding: 4px;
                & img {
                  min-width: 11px;
                }
                &:hover {
                  background: #f6f6f6;
                }
              }
              & .doc__progress {
                & .translation__words--counter {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  & .progress__words {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    & .progress__bar {
                    }
                  }
                  & > p {
                    color: rgba(157, 157, 157, 1);
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 400;
                    white-space: nowrap;
                    margin-right: 10px;
                  }
                }
              }
              & .doc__main--info {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                & .doc__image {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-right: 7px;
                  & img {
                    min-width: 27px;
                  }
                }
                & .doc__inner--info {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  & > p {
                    color: rgba(59, 59, 59, 1);
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 600;
                    white-space: nowrap;
                  }
                  & > span {
                    color: rgba(158, 158, 158, 1);
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 400;
                    display: inline-flex;
                    margin-left: 5px;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
        margin-bottom: 5px;

        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    & .head__documents {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      & .full__finished--docs {
        & > p {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: rgba(158, 158, 158, 1);
          font-size: 12px;
          line-height: 24px;
          font-weight: 400;
          & span {
            font-weight: 700;
          }
        }
      }
      & .linked__info {
        & p {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & span {
            font-weight: 700;
          }
          margin: 0px;
          color: rgba(68, 68, 68, 1);
          font-size: 12px;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
  }
}
.translation__words--counter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  padding-left: 30px;
  &.fourth__progress {
    & .progress__words {
      & > p {
        color: #ff5b5b;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-right: 25px;
          margin-right: -25px;
          position: relative;
          & span {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            margin-left: 15px;
            position: absolute;
            right: 0px;
            transform: rotate(-180deg);
          }
        }
      }
      & .progress__bar {
        & .active__words2 {
          background: linear-gradient(
              90.02deg,
              rgba(255, 255, 255, 0.2) -1.38%,
              rgba(255, 255, 255, 0) 99.12%
            ),
            #ff5b5b;
          border: 1px solid #ff5b5b;
          box-shadow: 0px 0px 4px rgba(255, 91, 91, 0.4);
          border-radius: 10px;
          min-width: 10px;
        }
        & .active__words {
          background: linear-gradient(
              90.02deg,
              rgba(255, 255, 255, 0.2) -1.38%,
              rgba(255, 255, 255, 0) 99.12%
            ),
            #ff5b5b;
          border: 1px solid #ff5b5b;
          box-shadow: 0px 0px 4px rgba(255, 91, 91, 0.4);
          border-radius: 10px;
          min-width: 10px;
        }
      }
    }
  }
  &.second__progress {
    & .progress__words {
      & > p {
        color: #27ae60;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-right: 25px;
          margin-right: -25px;
          position: relative;
          & span {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            margin-left: 15px;
            position: absolute;
            right: 0px;
            transform: rotate(-180deg);
          }
        }
      }
      & .progress__bar {
        & .active__words {
          background: linear-gradient(
              90.02deg,
              rgba(255, 255, 255, 0.2) -1.38%,
              rgba(255, 255, 255, 0) 99.12%
            ),
            #27ae60;
          border: 1px solid #27ae60;
          box-shadow: 0px 0px 4px rgba(63, 136, 197, 0.4);
          border-radius: 10px;
        }
      }
    }
  }

  &.first__progress {
    & .progress__words {
      & > p {
        color: #fdb913;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-right: 25px;
          margin-right: -25px;
          position: relative;
          & span {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            margin-left: 15px;
            position: absolute;
            right: 0px;
            transform: rotate(-180deg);
          }
        }
      }
      & .progress__bar {
        & .active__words {
          background: linear-gradient(
              90.02deg,
              rgba(255, 255, 255, 0.2) -1.38%,
              rgba(255, 255, 255, 0) 99.12%
            ),
            #fdb913;
          border: 1px solid #fdb913;
          box-shadow: 0px 0px 4px rgba(253, 185, 19, 0.4);
          border-radius: 10px;
        }
      }
    }
  }
  &.third__progress {
    & .progress__words {
      & > p {
        color: #3f88c5;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-right: 25px;
          margin-right: -25px;
          position: relative;
          & span {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            margin-left: 15px;
            position: absolute;
            right: 0px;
            transform: rotate(-180deg);
          }
        }
      }
      & .progress__bar {
        & .active__words {
          background: linear-gradient(
              90.02deg,
              rgba(255, 255, 255, 0.2) -1.38%,
              rgba(255, 255, 255, 0) 99.12%
            ),
            #4194d9;
          border: 1px solid #3f88c5;
          box-shadow: 0px 0px 4px rgba(63, 136, 197, 0.4);
          border-radius: 10px;
          min-width: 10px;
        }
      }
    }
  }
  & .progress__words--select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & .progress__drop {
      & a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        line-height: 20px;
        font-weight: 600;
        color: #787878;
        position: relative;
        padding-right: 25px;
        margin-right: -25px;
        & span {
          display: inline-flex;
          margin-left: 15px;
          position: absolute;
          right: 0px;
          & img {
            min-width: 9px;
          }
        }
      }
    }
    & .progress__bar {
      min-width: 100px;
      background: #e9e9e9;
      margin-right: 10px;
      border-radius: 10px;
      height: 8px;
      position: relative;
      & > .active__1 {
        padding: 4px 5px;
        border-radius: 10px;
        position: absolute;
        left: 0px;
        top: -1px;
        z-index: 5;
        background: linear-gradient(
            90.02deg,
            rgba(255, 255, 255, 0.2) -1.38%,
            rgba(255, 255, 255, 0) 99.12%
          ),
          #ff5b5b;
        min-height: 10px;
        max-height: 10px;
        height: 10px;
        background: linear-gradient(
            90.02deg,
            rgba(255, 255, 255, 0.2) -1.38%,
            rgba(255, 255, 255, 0) 99.12%
          ),
          #ff5b5b;
        border: 1px solid #ff5b5b;
        box-sizing: border-box;
        box-shadow: 0px 0px 4px rgba(255, 91, 91, 0.4);
        border-radius: 10px;
      }
      & > .active__2 {
        padding: 4px 5px;
        border-radius: 10px;
        position: absolute;
        left: 0px;
        top: -1px;
        z-index: 4;
        background: linear-gradient(
            90.02deg,
            rgba(255, 255, 255, 0.2) -1.38%,
            rgba(255, 255, 255, 0) 99.12%
          ),
          #4194d9;
        min-height: 10px;
        max-height: 10px;
        height: 10px;
        border: 1px solid #3f88c5;
        box-shadow: 0px 0px 4px rgba(63, 136, 197, 0.4);
      }
      & > .active__3 {
        padding: 4px 5px;
        border-radius: 10px;
        position: absolute;
        left: 0px;
        top: -1px;
        z-index: 3;
        background: linear-gradient(
            90.02deg,
            rgba(255, 255, 255, 0.2) -1.38%,
            rgba(255, 255, 255, 0) 99.12%
          ),
          #27ae60;
        border: 1px solid #27ae60;
        min-height: 10px;
        max-height: 10px;
        height: 10px;
        box-shadow: 0px 0px 4px rgba(39, 174, 96, 0.4);
      }

      & > .active__4 {
        padding: 4px 5px;
        border-radius: 10px;
        background: linear-gradient(
            90.02deg,
            rgba(255, 255, 255, 0.2) -1.38%,
            rgba(255, 255, 255, 0) 99.12%
          ),
          #fdb913;
        border: 1px solid #fdb913;
        height: 10px;
        z-index: 2;
        position: absolute;
        left: 0px;
        top: -1px;
        border-radius: 18px;
        box-shadow: 0px 0px 4px rgba(253, 185, 19, 0.4);
      }
    }
  }
  & .progress__words {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & .progress__bar {
      min-width: 100px;
      background: #e9e9e9;
      margin-right: 10px;
      border-radius: 10px;
      height: 8px;
      position: relative;
      & .active__words {
        background: linear-gradient(
            90.02deg,
            rgba(255, 255, 255, 0.2) -1.38%,
            rgba(255, 255, 255, 0) 99.12%
          ),
          #fdb913;
        border: 1px solid #fdb913;
        height: 10px;
        position: absolute;
        left: 0px;
        top: -1px;
        border-radius: 18px;
        box-shadow: 0px 0px 4px rgba(253, 185, 19, 0.4);
      }
    }
    & p {
      color: #fdb913;
      font-size: 12px !important;
      line-height: 20px !important;
      min-width: 24px !important;
      font-weight: 600 !important;
    }
  }
  & > p {
    margin-right: 10px !important;
    color: #787878 !important;
    white-space: nowrap;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    margin: 0px;
    margin-right: 10px;
    & span {
      display: inline-flex;
      color: #9d9d9d;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
    }
  }
}
.file__row {
  & > .documents__files {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0px;
    }
    & > .references__list {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
.advanced__drop {
  margin-left: 16%;
}
.service__main {
  &.service__chosen {
    & .service__elem {
      border-bottom: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 0px !important;
      justify-content: center !important;
      & > img {
        display: none;
      }
    }
  }
}
.unclickable__service {
  pointer-events: none;
}
.table__burger {
  & .menu__burger--float {
    position: relative;
    top: 10px;
  }
}
.pre__translation {
  padding-left: 35px;
  width: 100%;
  & .add__rule {
    margin-top: 20px;
    width: 100%;
    & a {
      display: flex;
      width: 100%;
      padding: 12px 20px;
      background: #ffffff;
      border-radius: 2px;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      transition: 0.4s ease all;
      color: rgba(14, 125, 125, 1);
      &:hover {
        opacity: 0.7;
      }
      & img {
        margin-right: 10px;
      }
    }
  }
  & .translation__memories {
    margin-top: 20px;
    position: relative;
    & .memories__main--list {
      & .check__source {
        & .checkbox__box {
          margin-right: 7px;
        }
      }
      & .triple__list {
        display: grid;
        align-items: flex-end;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        margin-bottom: 20px;
        & .elem__list {
          & p {
            margin: 0px;
            margin-bottom: 5px;
            color: rgba(120, 120, 120, 1);
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
          }
          & .custom__select {
            width: 100%;
            & .select {
              & .select__dropdown {
                margin-top: 0px;
              }
              max-width: 500px;
              width: 100%;
              border-bottom: 1px solid #0e7d7d;
              padding-bottom: 5px;
              padding-top: 5px;
            }
          }
        }
      }
      & .list__full {
        margin-bottom: 20px;
        & > p {
          margin: 0px;
          margin-bottom: 5px;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
        }
        & .custom__select {
          width: 100%;
          & .select {
            & .select__dropdown {
              margin-top: 0px;
            }
            max-width: 500px;
            width: 100%;
            border-bottom: 1px solid #0e7d7d;
            padding-bottom: 5px;
            padding-top: 5px;
          }
        }
      }
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      position: relative;
    }
    & > span {
      position: absolute;
      left: -35px;
      min-width: 29px;
      max-width: 29px;
      min-height: 29px;
      max-height: 29px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 250px;
      top: 0px;
    }
    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 2;
      min-width: 28px;
      max-width: 28px;
      min-height: 28px;
      max-height: 28px;
      transition: 0.4s ease all;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  & .machine__translation {
    margin-top: 40px;
    position: relative;
    &:before {
      content: '';
      top: -20px;
      width: calc(100% + 75px);
      left: -55px;
      height: 1px;
      background-color: #9e9e9e;
      position: absolute;
    }
  }
  & .source__block,
  & .machine__translation {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    position: relative;
    & > h6 {
      margin: 0px;
      margin-bottom: 10px;
      color: rgba(45, 45, 45, 1);
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }
    & > p {
      margin: 0px;
      margin-bottom: 20px;
      color: rgba(45, 45, 45, 1);
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
    & .check__source {
      & .check__input {
        & .checkbox__box {
          margin-right: 7px;
        }
      }
    }
    & > span {
      position: absolute;
      left: -35px;
      min-width: 29px;
      max-width: 29px;
      min-height: 29px;
      max-height: 29px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 250px;
      top: 0px;
    }
    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 15px;
      top: 15px;
      min-width: 28px;
      max-width: 28px;
      min-height: 28px;
      max-height: 28px;
      transition: 0.4s ease all;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  & .finish__translation {
    padding: 12px 20px;
    background: #ededed;
    border-radius: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    & p {
      margin: 0px;
      color: rgba(158, 158, 158, 1);
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      display: flex;
      align-items: center;
      & span {
        display: inline-flex;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
      }
    }
  }
  & .translation__insert {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 20px;
    & p {
      color: rgba(45, 45, 45, 1);
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 10px;
      margin-top: 0px;
    }
    & ul {
      padding: 0px;
      list-style-type: none;
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      & li {
        margin-right: 15px;
        margin-bottom: 5px;
        &.translation__droppable {
          position: relative;
          & .dropdown__translate {
            position: absolute;
            right: 0px;
            top: 15px;
            background: #ffffff;
            box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            & ul {
              padding: 0px;
              list-style-type: none;
              margin: 0px;
              padding: 5px;
              & li {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 3px;
                &.active__check {
                  & a {
                    background-color: rgba(239, 239, 239, 1);
                  }
                }
                &:last-child {
                  margin-bottom: 0px;
                }
                & a {
                  white-space: nowrap;
                  color: rgba(45, 45, 45, 1);
                  font-size: 12px;
                  line-height: 18px;
                  border-radius: 4px;
                  font-weight: 400;
                  padding: 8px 10px;
                  transition: 0.4s ease all;
                  width: 100%;
                  & .check__source {
                    width: 100%;
                    & .check__input {
                      width: 100%;
                      & .checkbox__box {
                        margin-right: 10px;
                        & span {
                          margin-left: 0px;
                        }
                      }
                      & label {
                        width: 100%;
                      }
                    }
                  }
                  &:hover {
                    opacity: 1;
                    background-color: rgba(239, 239, 239, 1);
                  }
                }
              }
            }
          }
          & a {
            & span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-left: 7px;
            }
          }
        }
        & a {
          color: rgba(14, 125, 125, 1);
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-transform: uppercase;
          transition: 0.4s ease all;
          display: flex;
          align-items: center;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  & .head__pre--translation {
    width: 100%;

    margin-bottom: 15px;

    & p {
      margin: 0px;
      margin-bottom: 10px;
      margin-top: 0px;
      color: rgba(45, 45, 45, 1);
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      & a {
        color: rgba(14, 125, 125, 1);
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        text-decoration: none;
        transition: 0.4s ease all;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
.submitted__files--employee {
  width: 100%;
  padding: 20px 30px;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .submitted__files--controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & .upload__block {
      & .btn-add__input_add {
        & .btn-add__input-label {
          white-space: nowrap;
        }
      }
      & .wrap-btn-add {
        padding-right: 0px;
      }
    }
    & .hint__submitted {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding-bottom: 15px;
      top: 8px;
      & > span {
        & img {
          min-width: 30px;
        }
      }
      & .submitted__float {
        position: absolute;
        right: 0px;
        top: 40px;
        padding: 15px;
        background: #ffffff;
        box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
        min-width: 235px;
        border-radius: 4px;
        display: none;
        z-index: 4;
        left: 50%;
        transform: translate(-50%, 0);
        & ul {
          padding: 0px;
          list-style-type: none;
          margin: 0px;
          & li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #000000;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            & span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              min-width: 3px;
              max-width: 3px;
              min-height: 3px;
              max-height: 3px;
              margin-right: 7px;
              border-radius: 250px;
              background-color: #000;
            }
          }
        }
        & p {
          margin: 0px;
          margin-bottom: 10px;
          color: #000000;
          font-size: 14px;
          line-height: 24px;
          font-weight: 600;
        }
      }
      & span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  & .submitted__files--info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & .submitted__text--description {
      & > h6 {
        margin-bottom: 5px;
        margin-top: 0px;
        color: #0e7d7d;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }
      & > p {
        color: #3d3d3d;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin: 0px;
      }
    }
    & .icon__submitted {
      display: inline-flex;
      margin-right: 45px;
      align-items: center;
      justify-content: center;
      & img {
        max-width: 55px;
      }
    }
  }
}
.file__table.file__table {
  & tr {
    & > td:nth-child(1) {
      padding-left: 15px !important;
      & .file__field--table {
        padding-left: 15px !important;
      }
    }
  }
}
.download__complex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 8px 8px 8px;
  margin-top: 20px;
  & .complex__left {
    & .upload__block {
      & .wrap-btn-add {
        padding-left: 0px;
      }
    }
  }
  & .complex__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & .finish__project {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      & a {
        background-color: #0e7d7d;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 200px;
        min-height: 40px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        text-decoration: none;
        border-radius: 4px;
        color: #fff;
        transition: 0.4s ease all;
        &:hover {
          background-color: #0a5858;
        }
        &:active {
          color: #ffffff;
          background-color: #094f4f !important;
        }
        &:focus {
          color: #ffffff;
          background-color: #0a5858;
          -webkit-box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
        }
        &.disabled__finish {
          cursor: default;
          background: #c4c4c4 !important;
          border-radius: 4px;
          color: rgba(255, 255, 255, 1) !important;
          box-shadow: none !important;
        }
        & span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
        }
      }
    }
    & .warning__complex {
      margin-right: 15px;
      & .warning__button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        transition: 0.4s ease all;
        &:hover {
          border-color: #0e7d7d;
          -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
        & img {
          max-width: 20px;
        }
      }
    }
  }
}
.file__table--result {
  & tr {
    & h5 {
      color: rgba(120, 120, 120, 1);
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      & span {
        margin-left: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.file__table--result {
  & tr {
    & h5 {
      color: rgba(120, 120, 120, 1);
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      & span {
        margin-left: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.table__wrapper {
  & table.table__common {
    & .submitted__table--buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & .status__submitted {
        position: relative;
        margin-right: 10px;
        & a {
          min-width: 95px;
          white-space: nowrap;
          padding-left: 15px;
          padding-right: 15px;
          min-height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.4s ease all;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          font-size: 12px;
          line-height: 24px;
          font-weight: 600;
          color: rgba(14, 125, 125, 1);
          &.button__disabled {
            cursor: default;
            color: rgba(158, 158, 158, 1);
            &:hover {
              border-color: #e3e3e3;
              box-shadow: none;
            }
            & span {
              & svg {
                & circle {
                  fill: #9e9e9e;
                }
              }
            }
          }
          &:hover {
            border-color: #0e7d7d;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          }
          & span {
            display: inline-flex;
            margin-left: 10px;
            & svg {
              max-width: 12px;
            }
          }
        }
        &.replaced__status {
          & p {
            color: rgba(120, 120, 120, 1);
          }
        }
        & p {
          color: rgba(0, 200, 104, 1);
          font-size: 12px;
          line-height: 24px;
          font-weight: 600;
        }
      }
    }
  }
}
.float__table--content {
  & .menu__drop {
    margin-left: 30px !important;
    border-radius: 5px;
    padding: 5px;
    margin-top: 30px !important;
    & .actions {
      padding: 0px;
      & .actions__action {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
}
.file__table--result {
  & .permissions-control-dropdown__toggle {
    transition: 0.4s ease all;
    &:hover {
      border-color: #0e7d7d;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    }
    box-shadow: none;
    border: 1px solid rgba(227, 227, 227, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    border-radius: 4px;
    & .burger {
      display: flex;
      justify-content: center;
      align-items: center;
      & span {
        width: 16px;
        &:before {
          width: 16px;
        }
        &:after {
          width: 16px;
        }
      }
    }
  }
}
.submitted__files--list {
  & .head__submitted--files {
    padding-left: 15px;
  }
}
.file__field--table {
  & .file__status {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    & span {
      & img {
        max-width: 24px;
      }
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.source__file {
  & + .size__graph {
    margin-left: 30px;
  }
}
.jobs__table--services {
  & .elem__service {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & p {
      margin: 0px;
      white-space: nowrap !important;
      margin-left: 10px !important;
      color: rgba(45, 45, 45, 1) !important;
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 600 !important;
    }
  }
}
.choose-actions-dropdown__box .actions__action {
  width: 100%;
  margin-left: 0px;
}
.actions {
  padding: 0px;
}
.permissions-control-dropdown__box {
  padding-right: 5px;
  border-radius: 4px;
}
.source__picker > input.field__source {
  min-width: 70px;
  text-align: center;
}
.field__input > input {
  min-width: 70px;
  text-align: center;
  border-radius: 3px;
  max-width: 70px;
  margin-right: 5px;
  min-height: 30px;
  font-size: 12px;
  border: 1px solid #dadada;
}
.download__all {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  & > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 160px;
    min-height: 40px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    color: rgba(14, 125, 125, 1);
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    transition: 0.4s ease all;
    &:hover {
      border-color: #0e7d7d;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    }
    & span {
      display: inline-flex;
      margin-right: 15px;
    }
  }
}
.order__table + .submitted__files--list {
  margin-top: 20px;
}
.complex__right {
  & .download__all {
    margin-top: 0px;
    padding-right: 0px;
    & > a {
      min-height: 45px;
    }
  }
}
.center__source.source__picker {
  display: flex;
  width: 100%;
  justify-content: center;
}
.float__table--content {
  & .select__dropdown.float__table--dropdown {
    margin-top: 30px;
  }
}
.hint__float {
  position: absolute;
  background: #ffffff;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px;
  min-width: 230px;
  max-width: 230px;
  bottom: auto;
  z-index: 3;
  margin-top: -10px;
  margin-left: -220px;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  & p {
    margin: 0px;
    color: rgba(0, 0, 0, 1) !important;
    font-size: 12px !important;
    line-height: 18px !important;
    font-weight: 400 !important;
  }
}

.float__table--content {
  & .hint__float {
    display: none;
  }
}
.hint__table {
  & > img {
    min-width: 12px;
    max-width: 12px;
  }
  &__image16 {
    & > img {
      min-width: 16px;
      max-width: 16px;
    }
  }
}

.source__wrapper--center {
  display: flex;
  justify-content: center;
}
.task__el {
  & .default__task {
    &.weight__default {
      & > p {
        font-weight: 600;
      }
    }
    & > p {
      margin: 0px;
      color: #2d2d2d;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}
.service__name.service__locked {
  & > input {
    min-width: 170px;
    height: 30px;

    border-radius: 3px;
    padding-left: 15px;
    padding-right: 15px;
    color: #787878;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    background: url('../images/lockicon.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 95% center;
    background-color: #f7f7f7;
    padding-right: 30px;
  }
}
.table__custom--select {
  &.small__table {
    & .select__label {
      min-width: 75px;
      padding-right: 25px;
      font-size: 12px;
      &:after {
        right: 10px;
      }
    }
  }
  & .select__label {
    height: 30px;
    & span {
      font-weight: 600;
    }
  }
}
.table__custom--right {
  & > input {
    text-align: right;
  }
}
.custom__table--field {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom__table--field > input {
  min-width: 75px;
  height: 30px;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  color: #787878;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  max-width: 75px;
  background-color: #f7f7f7;
}
h4.center__text {
  text-align: center;
}
table {
  & .currency__picker {
    display: flex;
    flex-direction: row-reverse;
    & .select button {
      height: 30px;
    }
    & .val__currency {
      & > input {
        text-align: center;
        font-size: 12px;
        height: 30px;
        min-width: 60px;
        max-width: 60px;
      }
    }
  }
}
.table__wrapper {
  & table {
    &.table__common {
      & tr {
        & td {
          .additional__info {
            padding-top: 5px;
            padding-left: 46px;
            padding-right: 46px;
            margin-top: -8px;
            position: relative;
            z-index: 2;
            background-color: #fff;
            &.additional__longer {
              padding-left: 69px;
              padding-right: 69px;
            }
            & > p {
              margin: 0px;
              color: #9e9e9e;
              font-size: 12px;
              line-height: 16px;
              font-weight: 600;
              margin-bottom: 15px;
            }
            & .additional__info--inner {
              margin-bottom: 25px;
              & .head__info {
                width: 100%;
                border-bottom: 1px solid #0e7d7d;
                padding-bottom: 5px;
                padding-top: 5px;
                margin-bottom: 10px;
                & > h6 {
                  margin: 0px;
                  color: #2c2c2c;
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: 600;
                }
              }
              & .content__info {
                & > .elem__content--info {
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-start;
                  margin-bottom: 7px;
                  &:last-child {
                    margin-bottom: 0px;
                  }
                  & .content__block {
                    & > p {
                      color: #2d2d2d;
                      font-size: 12px;
                      line-height: 18px;
                      font-weight: 400;
                    }
                    & .requirements__tags {
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      flex-wrap: wrap;
                      & > .elem__tag {
                        margin-right: 10px;
                        margin-bottom: 10px;
                        padding: 4px 10px;
                        background: #f7f7f7;
                        border-radius: 3px;
                        & > p {
                          margin: 0px;
                          color: #2d2d2d;
                          font-size: 12px;
                          line-height: 18px;
                          font-weight: 400;
                        }
                      }
                    }
                    & .deadline__droppable {
                      & p {
                        padding: 3px 15px;
                        font-size: 12px;
                        line-height: 18px;
                      }
                    }
                    & .flags__content {
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      & > ul {
                        padding: 0px;
                        list-style-type: none;
                        margin: 0px;
                        position: relative;
                        top: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        & > li {
                          margin-right: 10px;
                          margin-bottom: 5px;
                        }
                      }
                      & > p {
                        margin-top: 0px;
                        margin-bottom: 0px;
                        margin-right: 10px;
                        color: #2d2d2d;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 400;
                      }
                    }
                  }
                  & > span {
                    color: #787878;
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    min-width: 160px;
                    margin-right: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.expandable__stick {
  position: relative;
}
.expandable__stick .stick__float {
  position: absolute;
  left: 0px;
  top: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 3;
  width: 0px;
  height: 100%;
  transition: 0.2s ease all;
  background-color: #0e7d7d;
}
.expandable__stick > td.active--check:before {
  content: none !important;
}
table {
  & .currency__picker {
    margin-left: 0px;
    justify-content: center;
    & .select {
      width: auto;
      & button {
        margin-right: 0px;
      }
    }
  }
}
.show__main > p {
  white-space: nowrap;
}
.table__wrapper table.small__table tr th {
  padding: 7px 4px;
}
.table__wrapper table.small__table tr td {
  padding: 7px 4px;
}
.table__wrapper table.table__common tr td p.rep__info {
  text-align: center;
  color: #2d2d2d;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  margin: 0px;
}
.show__row.row__space {
  padding-right: 20px !important;
}
.show__main--whitespace {
  min-width: 125px !important;
  max-width: 125px !important;
  & > p {
    white-space: nowrap;
  }
}
.small__table .download__freelancer--file {
  padding-right: 20px !important;
}
.small__table .download__all {
  padding-right: 25px;
  padding-bottom: 18px;
}
.small__table {
  & .check__input {
    padding-left: 15px !important;
  }
}

.source__settings {
  & .task__info {
    & .task__content {
      & .task__picker {
        & .small__table {
          & .select__label {
            max-width: 50px;
            min-width: 50px;
            padding-left: 10px;
            height: 35px;
            border: 1px solid #e3e3e3;
            border-radius: 3px;
            background-color: transparent;
            &:before {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
.freelance__time--left {
  width: 100%;
  & > p {
    text-align: left;
    width: 100%;
    color: #2d2d2d;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    margin: 0px;
  }
}
.small__info {
  &.weight__small {
    font-weight: 600 !important;
  }
  text-align: center !important;
  color: #2d2d2d !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  margin: 0px !important;
}
.service__short {
  width: 180px;
}
.check__small {
  width: 43px;
}

.float__table--content {
  & .select__dropdown {
    position: absolute;
  }
}
.select__width {
  min-width: 260px !important;
}
.small__table--float {
  transform: none !important;
}
.additional__address {
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  & > .elem__address {
    & .info__address {
      & > p {
        margin: 0px;
        color: #2c2c2c;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
      }
    }
    & > h6 {
      padding-bottom: 5px;
      margin-bottom: 10px;
      margin-top: 0px;
      color: #2c2c2c;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      border-bottom: 1px solid #0e7d7d;
    }
  }
}
h4.flex__title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.center__text {
    justify-content: center;
  }
  & > span {
    margin-left: 5px;
  }
}
.table__custom--center {
  & > input {
    text-align: center;
  }
}
.select__dropdown.float__table--dropdown {
  transform: none;
}
.file__table.file__small {
  & .source__small {
    width: 180px;
  }
}
.width__auto--select {
  width: auto !important;
  margin-right: auto !important;
  margin-left: 0px !important;
}
.small__files {
  width: 170px !important;
}
.weight__text--headline {
  color: rgba(158, 158, 158, 1) !important;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600 !important;
}
.expandable__stick {
  &.expandable__long {
    & .service__locked {
      & + p {
        left: 15px;
      }
    }
  }
  & td {
    position: relative;
    padding-bottom: 20px !important;
  }
  & .service__locked {
    position: relative;
    & + p {
      color: #9e9e9e !important;
      font-size: 12px !important;
      line-height: 16px !important;
      font-weight: 600 !important;
      position: absolute;
      left: 5px;
      bottom: 3px;
      z-index: 3;
    }
  }
}
.order__create--form .head__create--controls .create__info .check__input {
  position: relative;
}
.order__create--form .head__create--controls .create__info .check__input .check__float {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 30px;
  text-align: center;
  color: #0e7d7d;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  min-width: 30px;
  justify-content: center;
}
.create__info {
  & .check__head {
    display: flex;
    align-items: center;
    & .custom__table--field {
      width: 100%;
      & > input {
        min-width: 0px;
        width: 100%;
        max-width: 75px;
      }
    }
    justify-content: flex-start;
    & > .service__locked {
      position: relative;
      & > p {
        position: absolute;
        left: 0px;
        bottom: -20px;
        color: #9e9e9e;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
      }
    }
    & .currency__picker {
      & .select__dropdown {
        transform: translate(-50%, 0%) !important;
      }
    }
    & .select__dropdown {
      margin-top: 0px !important;
      left: 0px !important;
      transform: none !important;
    }
    & > .small__table {
      margin-right: 10px;
      & .select__label {
        padding-right: 30px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
    & > .currency__picker {
      flex-direction: row-reverse;
      margin-left: 0px;
      & .select {
        & button {
          height: 30px;
        }
      }
      & .val__currency {
        & > input {
          max-width: 40px;
          height: 30px;
          font-size: 12px;
        }
      }
    }
    & > .custom__table--field {
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
    & > .service__locked {
      max-width: 170px;
      margin-right: 10px;
      & > input {
        width: 100%;
      }
    }
  }
}
.country__account .row-group__item p {
  font-weight: bold !important;
}

.table__common.table__transparent {
  & tr {
    background-color: transparent !important;
  }
}
.opps__deadline {
  & > p {
    white-space: nowrap;
    & > span {
      color: #787878;
    }
  }
}
.client__td {
  width: 320px;
}
.client__table--row {
  position: relative;
  display: flex;
  align-items: center;
  & > a {
    width: 280px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.opened__client {
    & > a {
      width: 110px;
    }
  }
  & .client__controls {
    position: static;
    right: 0px;
    margin-left: 12px;
    height: 100%;
    display: none;
    & .controls__inner {
      display: flex;
      align-items: center;
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        transition: 0.4s ease all;
        border: 1px solid #e3e3e3;
        min-width: 30px;
        -webkit-transition: 0.4s ease all;
        transition: 0.4s ease all;
        max-width: 30px;
        min-height: 30px;
        max-height: 30px;
        border-radius: 4px;
        margin-right: 10px;
        &:hover {
          border-color: #0e7d7d;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
        & > img {
          min-width: 13px;
          min-height: 15px;
        }
        &:hover {
          opacity: 0.7;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
      & .controls__buttons {
        display: flex;
        align-items: center;
        margin-right: 15px;
        & > a {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          border: 1px solid #e3e3e3;
          min-width: 30px;
          -webkit-transition: 0.4s ease all;
          transition: 0.4s ease all;
          max-width: 30px;
          min-height: 30px;
          max-height: 30px;
          border-radius: 4px;
          margin-right: 10px;
          &:hover {
            border-color: #0e7d7d;
            -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
  & > a {
    color: #188ef0;
    font-size: 14px;
    line-height: 21px;
    text-decoration: underline;
    &:hover {
      opacity: 0.7;
    }
  }
}
.backside {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    transition: 0.4s ease all;
    &:hover {
      opacity: 0.7;
    }
  }
}
.side__menu {
  & .side__droppable {
    width: 100%;
    & > .el__drop--menu {
      &.active__drop--menu {
        & .head__droppable {
          & > a {
            & > img {
              transform: rotate(0deg);
            }
          }
        }
      }
      & .sub__droppable {
        margin-top: 3px;
        width: 100%;
        display: none;
        & > ul {
          width: 100%;
          padding: 0px;
          list-style-type: none;
          margin: 0px;
          & > li {
            margin-bottom: 3px;
            display: flex;
            width: 100%;
            &.current__sub {
              & > a {
                background-color: rgba(255, 255, 255, 0.1);
              }
            }
            & > a {
              width: 100%;
              display: flex;
              align-items: center;
              font-size: 14px;
              padding: 10px 15px;
              line-height: 19px;
              padding-right: 5px;
              font-weight: 600;
              color: rgba(255, 255, 255, 0.5);
              justify-content: flex-start;
              border-radius: 3px;
              transition: 0.4s ease all;
              padding-left: 40px;
              &:hover {
                background-color: rgba(255, 255, 255, 0.1);
              }
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
      & > .head__droppable {
        width: 100%;
        & > a {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 10px 15px;
          line-height: 19px;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.5);
          justify-content: flex-start;
          padding-right: 20px;
          padding-left: 10px;
          position: relative;
          transition: 0.4s ease all;
          border-radius: 3px;
          &:hover {
            background: rgba(255, 255, 255, 0.1);
          }
          & > img {
            position: absolute;
            right: 15px;
            transition: 0.4s ease all;
            transform: rotate(-180deg);
          }
          & > span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 13px;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
.employers__table {
  & table.table__lines {
    border-collapse: collapse !important;
  }
}
.client__active {
  & td {
    background-color: rgba(247, 247, 247, 1);
  }
}

.modal__regular {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.7);
  &.modal__termbase {
    & .termbase__definition {
      margin-top: 30px;
      & .definition__form {
        & .row-group__item {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      & .definition__head {
        & > h2 {
          margin-top: 0px;
          margin-bottom: 30px;
          color: #3b3b3b;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
        }
        & > p {
          margin: 0px;
          color: #3b3b3b;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }
        margin-bottom: 65px;
      }
    }
    & .termbase__form {
      max-width: 100%;
      & > .float__modal--buttons {
        max-width: calc(100% + 90px);
      }
      & > div {
        max-width: 450px;
      }
      & .import__controls {
        margin-top: 50px;
      }
    }
    & .import__controls {
      & .remove__import {
        & > a {
          min-width: 205px;
        }
      }
    }
    & .termbase__head {
      & > h2 {
        font-size: 25px;
        font-weight: 700;
        color: #0e7d7d;
        margin: 0px;
        margin-bottom: 20px;
      }
      & .termbase__progress {
        display: flex;
        margin-bottom: 30px;
        align-items: center;
        justify-content: center;
        & > .elem__termbase {
          min-height: 45px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          clip-path: polygon(0 0, 95% 0%, 100% 50%, 95% 100%, 0 100%, 0 95%);
          padding-right: 30px;
          margin-right: -20px;
          &:nth-child(1) {
            z-index: 5;
          }
          &:nth-child(2) {
            padding-left: 25px !important;
            position: relative;
            z-index: 3;
          }
          &:nth-child(3) {
            padding-left: 25px;
          }
          &.disabled__termbase {
            background: #f3f3f3;
            border-radius: 6px;
            & h6 {
              opacity: 0.5;
            }
          }
          &.inactive__termbase {
            box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
            background: #fafafa;
            border-radius: 6px;
            & h6 {
              color: rgba(61, 61, 61, 1);
              font-size: 14px;
              line-height: 19px;
              opacity: 0.5;
            }
          }
          &.current__termbase {
            box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
            border-radius: 6px;
            position: relative;
            z-index: 4;
            background: #ffffff;
            padding-left: 0px;
            &:before {
              content: '';
              position: absolute;
              left: 0px;
              bottom: 0px;
              width: 100%;
              height: 3px;
              background: #0e7d7d;
            }
          }
          &:last-child {
            margin-right: 0px;
          }
          & > h6 {
            margin: 0px;
          }
        }
      }
    }
    & > a {
      right: 745px;
    }
    & > .inner__modal--regular {
      max-width: 730px;
    }
  }
  &.modal__import {
    & > .inner__modal--regular {
      & .import__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        & .replace__import {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          & > a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: 0.4s ease all;
            padding: 5px 12px;
            background: #f8f8f8;
            border-radius: 3px;
            color: rgba(14, 125, 125, 1);
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            text-decoration: none;
            transition: 0.4s ease all;
            &:hover {
              opacity: 0.7;
            }
            & span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
  & > a {
    position: absolute;
    top: 70px;
    right: 675px;
    transition: 0.4s ease all;
    opacity: 0;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    &:hover {
      background: #5c5a64;
    }
    &:active,
    &:focus {
      border-color: #fff;
    }
  }
  &.modal__overwrite,
  &.modal__import,
  &.modal__export,
  &.modal__edit,
  &.modal__import--big,
  &.modal__clarify {
    & > a {
      right: 505px;
    }
    & > .inner__modal--regular {
      max-width: 490px;
      right: -490px;
    }
  }
  & > .inner__modal--regular {
    & .export__group {
      margin-bottom: 30px;
      & h5 {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 24px;
        color: rgba(45, 45, 45, 1);
      }
      & .radio__name {
        line-height: 18px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      & .row-group {
        display: flex;
        & .row-group__item {
          &.row__group--disabled {
            pointer-events: none;
            & .radio__name {
              color: rgba(158, 158, 158, 1);
            }
            & .radio__box {
              & span {
                border-color: rgba(158, 158, 158, 1);
              }
            }
          }
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        flex-direction: column;
      }
    }
    width: 100%;
    max-width: 660px;
    right: -670px;
    height: calc(100% - 60px);
    margin-left: auto;
    margin-top: 60px;
    background: #ffffff;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    overflow-y: auto;
    padding: 50px 45px;
    transition: 0.4s ease all;
    padding-bottom: 40px;
    padding-top: 40px;

    & .import__controls,
    & .export__controls {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > input[type='submit'] {
        margin-right: 15px;
        max-width: 125px;
        width: 100%;
      }
      & > button {
        min-width: 125px;
      }
    }
    & .replace__field {
      margin-bottom: 30px;
      width: 100%;
      & .replace__group {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > input {
          height: 40px;
          background: #f7f7f7;
          border-radius: 3px;
          padding-left: 20px;
          color: rgba(45, 45, 45, 1);
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          width: 100%;
          transition: 0.4s ease all;
          border: 1px solid #f7f7f7;
          &:focus {
            border-color: #f7f7f7;
          }
        }
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: 0.4s ease all;
          margin-left: 14px;
          & img {
            min-width: 16px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
      & .replace__top {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > p {
          margin: 0px;
          color: rgba(45, 45, 45, 1);
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
        }
        & > a {
          display: inline-flex;
          align-items: center;
          color: rgba(14, 125, 125, 1);
          font-size: 14px;
          line-height: 20px;
          font-style: 600;
          text-decoration: none;
          transition: 0.4s ease all;
          &:hover {
            opacity: 0.7;
          }
          justify-content: center;
          & > img {
            min-width: 16px;
            margin-right: 12px;
          }
        }
      }
    }
    & .import__head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;
      & > a {
        display: inline-flex;
        padding: 7px 12px;
        margin-left: 20px;
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        text-decoration: none;
        transition: 0.4s ease all;
        border: 1px solid rgba(227, 227, 227, 1);
        border-radius: 5px;
        &:hover {
          opacity: 0.7;
        }
      }
      & > h2 {
        font-size: 25px;
        font-weight: 700;
        color: rgba(14, 125, 125, 1);
        margin: 0px;
        margin-bottom: 0px;
        margin-right: 15px;
      }
    }
  }
}

.regular__field {
  margin-bottom: 15px;
  width: 100%;
}
.regular__field > textarea {
  height: 60px;
  resize: none;
  padding: 8px 20px;
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  width: 100%;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
  &:focus {
    border-color: #0e7d7d;
  }
}
.regular__field > input {
  height: 40px;
  padding: 8px 20px;
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  width: 100%;
  transition: 0.4s ease all;
  &:focus {
    border-color: #0e7d7d;
  }
}
.regular__field > p {
  margin-top: 0px;
  margin-bottom: 10px;
  color: rgba(45, 45, 45, 1);
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}
.edit__block {
  padding-right: 35px;
}
.double__regular {
  margin-bottom: 15px;
}
.double__regular .select__dropdown {
  left: 0px;
  transform: none;
  margin-top: 0px;
  width: 100% !important;
}
.double__regular .group__service .select__label {
  height: 50px;
}
.double__regular .group__service > p {
  margin-top: 0px;
  margin-bottom: 10px;
  color: rgba(45, 45, 45, 1);
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}
.double__regular {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}
.modal__wrapper .select__dropdown {
  margin-top: 0px;
}

.group__tag--field > p {
  margin-top: 0px;
  margin-bottom: 10px;
  color: rgba(45, 45, 45, 1);
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}

.permissions__field .permissions__field--inner .el__field--permissions p {
  margin: 0px;
  margin-right: 5px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #fff;
}
.permissions__field .permissions__field--inner .el__field--permissions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background: #18a6a6;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.permissions__field {
  margin-bottom: 15px;
}
.permissions__field .new__permissions {
  margin-bottom: 5px;
  margin-right: 5px;
}
.permissions__field .new__permissions > input {
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 400;
  height: 26px;
}
.permissions__field .permissions__field--inner {
  padding: 5px;
  border: 1px solid #c5c5c5;
  border-radius: 3px;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
}
.permissions__field {
  position: relative;
}
.permissions__field .permissions__dropdown ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.permissions__field .permissions__dropdown ul::-webkit-scrollbar-track {
  background: #e9e9e9;
}

/* Handle */
.permissions__field .permissions__dropdown ul::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 5px;
}
.permissions__field .permissions__dropdown ul li {
  display: flex;
  width: 100%;
}
.permissions__field .permissions__dropdown ul li a:hover {
  background: #ebf1f1;
}
.permissions__field .permissions__dropdown ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  color: rgba(14, 125, 125, 1);
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  font-weight: 600;
  transition: 0.4s ease all;
  border-radius: 4px;
}
.permissions__field .permissions__dropdown ul {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
  max-height: 310px;
  overflow-y: auto;
  padding-right: 0px;
}
.permissions__field .permissions__dropdown {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 100%;
  display: none;
  background: #ffffff;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  z-index: 5;
  padding: 5px;
  padding-right: 5px;
}
.example__permissions {
  display: none !important;
}
.group__tag--field {
  & .permissions__field {
    & .permissions__dropdown {
      top: 100%;
    }
    & .permissions__field--inner {
      &.picker--online {
        border: 1px solid #0e7d7d;
      }
      transition: 0.4s ease all;
      border: 1px solid #c5c5c5;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
      & .el__field--permissions {
        background: #f3f3f3;
        border-radius: 3px;
        & p {
          color: rgba(45, 45, 45, 1);
          font-weight: 400;
        }
      }
    }
  }
}
.edit__block {
  & + .import__controls {
    margin-top: 30px !important;
  }
}
.edit__block {
  & .replace__field {
    margin-bottom: 15px !important;
  }
  & .replace__field {
    & .replace__group {
      position: relative;
      & a {
        position: absolute;
        right: -30px;
      }
    }
  }
}
.import__controls {
  & .remove__import {
    margin-left: 15px;
    & > a {
      min-width: 130px;
      min-height: 40px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      text-transform: uppercase;
      color: rgba(255, 50, 50, 1);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      transition: 0.4s ease all;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.employers-page__heading.head__no--space {
  width: 100%;
}
.tm__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  border: 1px solid rgba(14, 125, 125, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: rgba(14, 125, 125, 1);
  min-height: 40px;
  padding: 5px 10px;
  background: #ffffff;
  transition: 0.4s ease all;
  border-radius: 3px;
  margin-right: 15px;
  position: relative;
  top: 0px;
  &:hover {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  }
  &:focus,
  &:active {
    border-color: rgba(14, 125, 125, 0.3);
  }
}
.task__heading {
  & .termbase__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & .termbase__buttons {
      margin-right: 15px;
      display: flex;
      align-items: center;
    }
    & .dtd__button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 60px;
      border: 1px solid rgba(14, 125, 125, 1);
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: rgba(14, 125, 125, 1);
      min-height: 40px;
      padding: 5px 10px;
      background: #ffffff;
      transition: 0.4s ease all;
      border-radius: 3px;
      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
      &:focus,
      &:active {
        border-color: rgba(14, 125, 125, 0.3);
      }
    }
    & .termbase__create {
      margin-right: 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 105px;
      border: 1px solid rgba(14, 125, 125, 1);
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: rgba(14, 125, 125, 1);
      min-height: 40px;
      padding: 5px 10px;
      background: #ffffff;
      transition: 0.4s ease all;
      border-radius: 3px;
      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
      &:focus,
      &:active {
        border-color: rgba(14, 125, 125, 0.3);
      }
      & span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
    }
  }
  & .heading__filter {
    & .button__filter {
      padding-left: 5px;
      padding-right: 5px;
      min-width: 54px;
    }
  }
}
.catalog__right--row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > .tm__button {
    white-space: nowrap;
  }
}
.comment__text {
  & > p {
    font-style: italic;
    color: rgba(45, 45, 45, 1);
    font-size: 14px;
    line-height: 21px;
  }
}
.client__link {
  & > a {
    color: rgba(24, 142, 240, 1);
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    text-decoration: underline;
  }
}
.table__common {
  & .client__link {
    & a {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 170px;
      white-space: nowrap;
      display: inline-block;
    }
  }
  & .comment__text {
    & p {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 165px;
      white-space: nowrap;
    }
  }
}
.modal__termbase {
  & .select__dropdown {
    margin-top: 0px;
    left: 0px;
    width: 100% !important;
    transform: none !important;
  }
}
.regular__languages {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  & .language__add {
    margin-left: 20px;
    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: 40px;
      max-width: 40px;
      min-width: 40px;
      max-height: 40px;
      border: 1px solid #0e7d7d;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }
  }
  & .languages__list {
    width: 100%;
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
}
.placeholder__modal {
  z-index: 8;
}
.termbase__two {
  & .client__definition {
    margin-top: 30px;
    & > p {
      margin-top: 0px;
      margin-bottom: 10px;
      color: #2d2d2d;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
    }
    & > .client__group {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > input {
        height: 40px;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        padding-left: 12px;
        padding-right: 12px;
        color: rgba(120, 120, 120, 1);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        outline: none;
        width: 100%;
        max-width: 340px;
      }
      & > a {
        min-width: 90px;
        min-height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(158, 158, 158, 1);
        border-radius: 3px;
        color: rgba(158, 158, 158, 1);
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        text-decoration: none;
        transition: 0.4s ease all;
        margin-left: 20px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  & .import__controls {
    margin-top: 50px;
  }
}
.modal__regular.modal__termbase .termbase__definition .definition__form .row-group__item {
  width: 100%;
  & .radio__name {
    line-height: 20px;
  }
}
.terms__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > .structure__minified {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 42px;
      max-width: 42px;
      min-height: 42px;
      max-height: 42px;
      transition: 0.3s ease all;
      border-radius: 3px;
      background-color: #fff;
      & > span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        background: #e7f2f2;
      }
    }
  }
  & > .double__minified__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-right: 10px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      min-width: 1px;
      min-height: 18px;
      background-color: #e3e3e3;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    & > .export__head {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 42px;
        max-width: 42px;
        min-height: 42px;
        max-height: 42px;
        transition: 0.3s ease all;
        border-radius: 3px;
        & > span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        &:hover {
          background: #e7f2f2;
        }
      }
    }
    & > .import__entry--button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 42px;
        max-width: 42px;
        min-height: 42px;
        max-height: 42px;
        transition: 0.3s ease all;
        border-radius: 3px;
        & > span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        &:hover {
          background: #e7f2f2;
        }
      }
    }
  }
  & > .double__minified {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-right: 10px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      min-width: 1px;
      min-height: 18px;
      background-color: #e3e3e3;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    & > .remove__minified {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 42px;
        max-width: 42px;
        min-height: 42px;
        max-height: 42px;
        transition: 0.3s ease all;
        border-radius: 3px;
        & > span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        &:hover {
          background: #e7f2f2;
        }
      }
    }
    & > .entry__minified {
      margin-right: 1px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 42px;
        max-width: 42px;
        min-height: 42px;
        max-height: 42px;
        transition: 0.3s ease all;
        border-radius: 3px;
        & > span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        &:hover {
          background: #e7f2f2;
        }
      }
    }
  }
  & .export__head {
    position: relative;
    & > a {
      margin-right: 0px !important;
    }
    & .dropdown__export {
      position: absolute;
      right: 0px;
      top: 44px;
      width: 100%;
      min-width: 550px;
      background: #ffffff;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      padding: 20px;
      border-radius: 4px;
      z-index: 4;
      & .inner__export {
        & .choose__format--export {
          & .advanced__export {
            & .advanced__container {
              width: 100%;
              & .advanced__hotkeys {
                margin-bottom: 20px;

                & > .elem__hotkey {
                  margin-bottom: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  & > .hotkey__button {
                    & > input {
                      min-width: 100px;
                      max-width: 100px;
                      min-height: 40px;
                      border: 1px solid #9ea985;
                      border-radius: 5px;
                      padding-left: 20px;
                      padding-right: 20px;
                    }
                  }
                  & > .hotkey__info {
                    margin-right: 15px;
                    & > h6 {
                      margin-top: 0px;
                      margin-bottom: 0px;
                      color: #2d2d2d;
                      font-size: 14px;
                      line-height: 21px;
                      font-weight: 600;
                    }
                    & > p {
                      margin: 0px;
                      font-size: 12px;
                      line-height: 21px;
                      color: rgba(45, 45, 45, 0.7);
                      & span {
                        color: red;
                      }
                      font-weight: 400;
                    }
                  }
                  &:last-child {
                    margin-bottom: 0px;
                  }
                }
              }
              & .el__advanced--small {
                display: flex;
                margin-bottom: 10px;
                &:last-child {
                  margin-bottom: 0px;
                }
                align-items: center;
                justify-content: space-between;
                & > p {
                  margin: 0px;
                  margin-right: 15px;
                  color: rgba(45, 45, 45, 1);
                  font-size: 12px;
                  line-height: 18px;
                  font-weight: 400;
                }
                & > input[type='text'] {
                  min-width: 0px;
                  width: 100%;
                  height: 35px;
                  background: #f7f7f7;
                  border-radius: 3px;
                  padding-left: 15px;
                  padding-right: 15px;
                }
              }
              & .el__advanced {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 10px;
                &:last-child {
                  margin-bottom: 0px;
                }
                & .advanced__field {
                  min-width: 70px;
                  max-width: 70px;
                  margin-left: 20px;
                  & > input {
                    padding-left: 15px;
                    padding-right: 15px;
                    width: 100%;
                    height: 35px;
                    background: #f7f7f7;
                    border-radius: 3px;
                  }
                }
                & .advanced__desc {
                  & > h6 {
                    margin-top: 0px;
                    margin-bottom: 5px;
                    color: rgba(45, 45, 45, 1);
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                  }
                  & > p {
                    color: rgba(158, 158, 158, 1);
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    & span {
                      color: #cc2936;
                    }
                  }
                }
              }
            }
            & .advanced__switcher {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 15px;
              position: relative;
              & .advanced__float {
                position: absolute;
                display: none;
                min-width: 300px;
                max-width: 300px;
                background: #ffffff;
                box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                padding: 15px;
                top: 30px;
                z-index: 4;
                & p {
                  margin: 0px;
                  color: #2d2d2d;
                  font-size: 14px;
                  line-height: 21px;
                  font-weight: 400;
                }
              }
              & > a {
                &.switcher__opened--advanced {
                  background-color: #0e7d7d;
                  & .plus__advanced {
                    opacity: 0;
                  }
                  & .minus__advanced {
                    background-color: #fff;
                  }
                }
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 18px;
                max-width: 18px;
                min-height: 18px;
                max-height: 18px;
                border: 2px solid #0e7d7d;
                border-radius: 3px;
                background-color: transparent;
                position: relative;
                & .plus__advanced {
                  position: absolute;
                  left: 0px;
                  right: 0px;
                  margin-left: auto;
                  margin-right: auto;
                  height: 12px;
                  width: 2px;
                  background-color: #0e7d7d;
                  transition: 0.4s ease all;
                }
                & .minus__advanced {
                  position: absolute;
                  left: 0px;
                  right: 0px;
                  margin-left: auto;
                  margin-right: auto;
                  height: 2px;
                  width: 12px;
                  background-color: #0e7d7d;
                  transition: 0.4s ease all;
                }
              }
              & > span {
                display: inline-flex;
                margin-left: 10px;
                color: rgba(45, 45, 45, 1);
                font-size: 14px;
                line-height: 21px;
                font-weight: 600;
                align-items: center;
                justify-content: flex-start;
                & > img {
                  margin-left: 10px;
                }
              }
            }
          }
          & > ul {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 20px;
            flex-wrap: wrap;
            & > li {
              padding: 5px 0px;
              margin-right: 15px;
              & label {
                color: rgba(14, 125, 125, 1);
                font-size: 12px;
                line-height: 20px;
                font-weight: 600;
                & .checkbox__box {
                  margin-right: 10px;
                }
              }
              &:last-child {
                margin-right: 0px;
              }
            }
          }
          & .export__controls {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 15px;
            margin-top: 20px;
            & .cancel__button {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              & > button {
                width: 100%;
              }
            }
            & > input {
              min-height: 35px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background: #0e7d7d;
              border: 2px solid #0e7d7d;
              box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
              cursor: pointer;
              outline: none;
              font-weight: 600;
              font-size: 14px;
              line-height: 14px;
              color: #fff;
              border-radius: 3px;
              transition: 0.4s ease all;
              &:active {
                color: #ffffff;
                background-color: #094f4f;
              }
              &:focus {
                color: #ffffff;
                background-color: #0a5858;
                box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
              }
              &:hover {
                background-color: #0a5858;
              }
            }
          }
          & > h6 {
            margin-top: 0px;
            margin-bottom: 10px;
            color: rgba(45, 45, 45, 1);
            font-size: 14px;
            line-height: 21px;
            font-weight: 600;
          }
        }
      }
    }
  }
  & > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin-right: 15px;
    border: 1px solid #0e7d7d;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: #0e7d7d;
    min-height: 40px;
    background: #ffffff;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    border-radius: 3px;
    & > img {
      margin-right: 15px;
    }
    &:hover {
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    }
    &:active {
      border-color: rgba(14, 125, 125, 0.3);
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}
.catalog__general {
  padding: 0px;
}
.general__projects {
  display: flex;
  background-color: #f7f7f7;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
  & .general__content {
    width: 100%;
    background-color: #fff;
    min-height: 740px;
    padding-bottom: 40px;
    & .general__buttons {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      & .general__left {
        width: 100%;
        max-width: 500px;
      }
      & .general__right {
        display: flex;
        margin-left: auto;
        padding-top: 9px;
        align-items: center;
        justify-content: flex-end;
        & > a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3px 10px;
          border: 1px solid #0e7d7d;
          box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          min-height: 30px;
          transition: 0.4s ease all;
          white-space: nowrap;
          margin-right: 10px;
          &:hover {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          }
          &:last-child {
            margin-left: 0px;
          }
          color: rgba(14, 125, 125, 1);
          font-size: 12px;
          text-transform: uppercase;
          line-height: 12px;
          font-weight: 600;
          & img {
            min-width: 13px;
            margin-right: 8px;
          }
        }
      }
      padding: 15px 10px;
    }
  }
  & .general__search {
    & .search__general--list {
      height: calc(800px - 70px);
      overflow-y: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 14px;
      background-color: #d6d6d6;
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #0e7d7d;
        border-radius: 5px;
      }
      & .elem__search {
        margin-bottom: 1px;
        &:last-child {
          margin-bottom: 0px;
        }
        &.active__search {
          & a {
            background-color: rgba(14, 125, 125, 1);
            color: #fff;
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }
        & a {
          transition: 0.4s ease all;
          background: #ffffff;
          color: rgba(61, 61, 61, 0.9);
          font-size: 14px;
          line-height: 19px;
          width: 100%;
          font-weight: 600;
          text-decoration: none;

          box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          display: inline-flex;
          padding: 10px 13px;
          &:hover {
            background-color: rgba(14, 125, 125, 0.7);
            box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
          }
        }
      }
    }
  }
  & .top__general {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .general__search {
    min-width: 275px;
    max-width: 275px;
    & .head__general--search {
      padding: 15px;
      background-color: #fff;
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & form {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      & .filter__general {
        margin-left: 8px;
        & button {
          min-width: 38px;
          max-width: 38px;
          min-height: 38px;
          padding: 5px;
          border: 1px solid #0e7d7d;
          border-radius: 6px;
        }
      }
      & .field__general {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        & > input[type='text'] {
          width: 100%;
          height: 38px;
          padding-left: 15px;
          padding-right: 15px;
          background: #f0f0f0;
          border-radius: 50px;
          padding-right: 45px;
        }
        & > input[type='submit'] {
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;
          background: url('../images/searchicon.svg');
          background-repeat: no-repeat;
          background-size: auto;
          background-position: center;
          position: absolute;
          right: 10px;
          cursor: pointer;
          transition: 0.4s ease all;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
.languages__box {
  padding: 0px 15px;
  & .elem__language--box {
    position: relative;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 30px;
    margin-bottom: 10px;
    background: #ffffff;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
    & .term__info {
      margin-top: 15px;
      & > p {
        margin: 0px;
        color: rgba(68, 68, 68, 1);
        font-size: 15px;
        line-height: 21px;
        font-weight: bold;
      }
    }
    & .language__term--head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 80px;
        min-height: 20px;
        border: 1px solid #0e7d7d;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        color: rgba(14, 125, 125, 1);
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        padding: 7px 15px;
        transition: 0.4s ease all;
        &:hover {
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
        & > img {
          max-height: 13px;
          margin-right: 6px;
        }
      }
      & > .lang__icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        & span {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          color: rgba(45, 45, 45, 1);
          font-size: 18px;
          line-height: 21px;
          font-weight: 600;
          text-transform: uppercase;
          & > img {
            max-width: 21px;
            min-width: 21px;
            margin-right: 8px;
          }
        }
      }
    }
    &.lang__blue {
      &:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 4px;
        height: 100%;
        background: #3f88c5;
        box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
      }
    }
    &.lang__orange {
      &:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 4px;
        height: 100%;
        background: #fdb913;
        box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
      }
    }
  }
}
.add__term--field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  & > .term__buttons {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      margin-right: 10px;
      transition: 0.4s ease all;
      &:hover {
        border-color: #0e7d7d;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  & > input {
    width: 100%;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    background: #f7f7f7;
    border-radius: 3px;
    color: #000;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    max-width: 400px;
  }
}
.add__general {
  margin-top: 8px;
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: flex-start;
  & .field__add {
    width: 100%;
    & > form {
      & > div {
        margin-top: 15px;
      }
    }
  }
  flex-direction: column;
  & .field__select--wrapper {
    & .field__select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & .select__dropdown {
        margin-top: 0px;
      }
      & .select {
        max-width: 400px;
        width: 100%;
      }
    }
  }
  & .field__date--wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    & .field__date {
      & .datepicker {
        &:after {
          min-width: 40px;
          max-width: 40px;
          min-height: 40px;
          max-height: 40px;
          background-color: #ffffff;
          background-size: 22px;
          box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          right: 0px;
        }
      }
      max-width: 400px;
      width: 100%;
      & .input--border-alto {
        background: #f7f7f7;
        border: 0px;
        border-radius: 3px;
        height: 40px;
      }
    }
  }
  & .field__bool--wrapper {
    & .field__bool {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & .field__bool--input {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 400px;
        & > input[type='text'] {
          height: 40px;
          width: 100%;
          background: #f7f7f7;
          border-radius: 3px;
          padding-left: 15px;
          padding-right: 15px;
          color: #000;
          font-size: 14px;
          font-weight: 400;
          transition: 0.4s ease all;
          line-height: 20px;
          border: 1px solid #f7f7f7;
          &:focus {
            border-color: #0e7d7d;
          }
        }
      }
    }
  }
  & .field__name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & .field__name--input {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      max-width: 400px;
      & > input[type='text'] {
        height: 40px;
        width: 100%;
        background: #f7f7f7;
        border-radius: 3px;
        padding-left: 15px;
        padding-right: 15px;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        transition: 0.4s ease all;
        line-height: 20px;
        border: 1px solid #f7f7f7;
        &:focus {
          border-color: #0e7d7d;
        }
      }
    }
  }
  & .field__text {
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    & .text__main--field {
      width: 100%;
      & .bottom__main--field {
        width: 100%;
        & > textarea {
          width: 100%;
          height: 55px;
          padding: 15px;
          background: #f7f7f7;
          border-radius: 3px;
          color: #000;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          resize: none;
        }
      }
      & .top__main--field {
        padding: 5px;
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-width: 30px;
          max-width: 30px;
          min-height: 30px;
          max-height: 30px;
          margin-right: 4px;
          border-radius: 3px;
          border: 1px solid transparent;
          transition: 0.4s ease all;
          &:hover {
            border-color: #0e7d7d;
          }
        }
        display: flex;
        width: 100%;
        max-width: 400px;
        align-tems: center;
        justify-content: flex-start;
        min-height: 40px;
        border: 1px solid #dadada;
        border-radius: 3px;
        & .main__buttons--complex {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & .elem__button--complex {
            position: relative;
            margin-right: 4px;

            & .complex__tooltip {
              position: absolute;
              padding: 7px 10px;
              background: #adadad;
              border-radius: 4px;
              top: 45px;
              display: none;
              &.tooltip__source {
                min-width: 175px;
              }
              &.tooltip__image {
                min-width: 110px;
              }
              &.tooltip__reference {
                min-width: 220px;
              }
              left: 50%;
              transform: translate(-50%, 0);
              &:before {
                content: '';
                position: absolute;
                left: 0px;
                margin-left: auto;
                margin-right: auto;
                right: 0px;
                min-width: 12px;
                max-width: 12px;
                min-height: 12px;
                max-height: 12px;
                top: -5px;

                background: url('../images/greytriangle.png');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
              }
              & > h6 {
                margin-top: 0px;
                margin-bottom: 3px;
                color: rgba(255, 255, 255, 1);
                font-size: 12px;
                line-height: 18px;
                font-weight: 600;
              }
              & > p {
                margin: 0px;
                color: rgba(255, 255, 255, 1);
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
              }
            }
            &:last-child {
              margin-right: 0px;
            }
            & > a {
              max-width: 30px;
              min-height: 30px;
              max-height: 30px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              transition: 0.4s ease all;
              border: 1px solid transparent;
              border-radius: 3px;
              &:hover {
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
              }
              &:last-child {
                margin-right: 0px;
              }
              min-width: 30px;
            }
          }
        }
      }
    }
  }
  & .field__controls {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      transition: 0.4s ease all;
      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-color: #0e7d7d;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  & .general__button {
    position: relative;
    & > a {
      background-color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      color: #0e7d7d;
      font-size: 14px;
      padding: 8px 15px;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      font-weight: 600;
      min-width: 250px;
      position: relative;
      padding-right: 40px;
      transition: 0.4s ease all;
      text-decoration: none;
      &:focus {
        border: 1px solid #0e7d7d;

        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
      &:hover {
        border: 1px solid #e3e3e3;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
      & > img {
        margin-left: 10px;
        position: absolute;
        right: 15px;
      }
      & > span {
        display: inline-flex;
        margin-right: 10px;
        & > img {
          min-width: 14px;
        }
      }
    }
  }
  & .general__dropdown {
    position: absolute;
    left: 0px;
    top: 100%;
    width: 100%;
    z-index: 3;
    padding: 10px;
    background: #ffffff;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    & ul {
      padding: 0px;
      list-style-type: none;
      margin: 0px;
      width: 100%;
      & > li {
        margin-bottom: 3px;
        width: 100%;
        & > a {
          display: inline-flex;
          align-iems: center;
          justify-content: flex-start;
          padding: 8px 15px;
          color: rgba(14, 125, 125, 1);
          width: 100%;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
          border-radius: 4px;
          text-decoration: none;
          transition: 0.4s ease all;
          &:hover {
            background-color: rgba(235, 241, 241, 1);
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.dropdown__form--text {
  position: absolute;
  left: 0px;
  top: 40px;
  min-width: 290px;
  max-width: 290px;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  z-index: 4;
  background-color: #fff;
  & .inner__cross--dropdown {
    padding: 20px;
    & .cross__container {
      width: 100%;
      & .cross__submit {
        width: 100%;
        display: grid;
        margin-top: 20px;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-height: 35px;
          border: 1px solid #0e7d7d;
          box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          color: rgba(14, 125, 125, 1);
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
          transition: 0.4s ease all;
          &:hover {
            background-color: #0a5858;
            color: #fff;
          }
          &:active {
            color: #ffffff;
            border-color: #094f4f;
            background-color: #094f4f;
          }
          &:focus {
            color: #ffffff;
            border-color: #0a5858;
            background-color: #0a5858;
            box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
          }
        }
        & > input[type='submit'] {
          height: 35px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: #0e7d7d;
          color: #ffffff;
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
          border: 2px solid #0e7d7d;
          box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          cursor: pointer;
          transition: 0.4s ease all;
          &:hover {
            background-color: #0a5858;
          }
          &:focus {
            color: #ffffff;
            background-color: #0a5858;
            box-shadow: 0 0 0 0.2rem rgba(14, 125, 125, 0.5);
          }
          &:active {
            color: #ffffff;
            background-color: #094f4f;
          }
        }
      }
    }
    & .cross__select {
      margin-bottom: 20px;
      & ul {
        padding: 0px;
        list-style-type: none;
        margin: 0px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        & > li {
          &.current__cross {
            & > a {
              font-weight: 600;
              color: rgba(14, 125, 125, 1);
              border-color: rgba(14, 125, 125, 1);
            }
          }
          & > a {
            display: inline-flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 21px;
            transition: 0.4s ease all;
            padding: 6px 10px;
            border-bottom: 1px solid rgba(231, 231, 231, 1);
            &:hover {
              font-weight: 600;
              color: rgba(14, 125, 125, 1);
              border-color: rgba(14, 125, 125, 1);
            }
          }
        }
      }
    }
    & .head__cross {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.4s ease all;
        &:hover {
          opacity: 0.7;
        }
      }
      & > h2 {
        margin: 0px;
        color: rgba(45, 45, 45, 1);
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
      }
    }
  }
}
.cross__container {
  & .select__dropdown {
    margin-top: 0px;
  }
  & .group__term {
    margin-bottom: 20px;
    & > input {
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;
      background: #f7f7f7;
      border-radius: 3px;
      font-size: 14px;
      line-height: 18px;
      width: 100%;
    }
    &.disabled__group {
      pointer-events: none;
      & .select__label {
        background-color: transparent;
        border: 1px solid rgba(227, 227, 227, 1);
      }
      & .select__label:before {
        background-color: transparent;
      }
      & .select__label:after {
        background-image: url('../images/servicearrowdisabled.svg');
        background-size: 11px 6px;
        background-position: center;
        background-repeat: no-repeat;
      }
      & > p {
        color: rgba(158, 158, 158, 1);
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
    & > p {
      margin-top: 0px;
      margin-bottom: 10px;
      color: rgba(45, 45, 45, 1);
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      & span {
        color: #cc2936;
      }
    }
  }
}
.general__projects {
  overflow: visible;
}
.general__buttons {
  & .general__left {
    & .general__button {
      margin-left: 30px;
    }
  }
}
.general__result {
  padding-top: 10px;
  padding-left: 30px;
  & .elem__result {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 30px;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0px;
    }
    & .result__info {
      & .result__static {
        & .static__image {
          display: flex;
          align-tems: flex-start;
          justify-content: flex-start;
          & .static__button {
            margin-left: 10px;
            & a {
              &:hover {
                border-color: #0e7d7d;
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
              }
              display: -webkit-inline-box;
              display: -ms-inline-flexbox;
              display: inline-flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              min-width: 30px;
              max-width: 30px;
              min-height: 30px;
              max-height: 30px;
              border: 1px solid #e3e3e3;
              border-radius: 3px;
              margin-right: 7px;
              -webkit-transition: 0.4s ease all;
              transition: 0.4s ease all;
            }
          }
          & > img {
            min-width: 240px;
            max-width: 240px;
            object-fit: cover;
            border-radius: 5px;
          }
        }
        & > p {
          white-space: nowrap;
          margin: 0px;
          color: #3d3d3d;
          font-size: 14px;
          line-height: 19px;
          font-weight: 600;
        }
      }
    }
    & > span {
      color: #9e9e9e;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      min-width: 100px;
    }
    & .result__info {
      display: flex;
      align-items: center;
      justify-contnet: flex-start;
    }
  }
}
.result__hover {
  opacity: 0;
  transition: 0.4s ease all;
  pointer-events: none;
  & .inner__result--hover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
    & > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      margin-right: 7px;
      transition: 0.4s ease all;
      &:hover {
        border-color: #0e7d7d;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
.general__result {
  width: 100%;
}
.elem__result {
  & .result__info {
    width: 100%;
  }
}
.result__edit {
  width: 100%;
  display: none;
}
.general__left {
  & .field__add {
    margin-left: 30px;
  }
}
.elem__language--box {
  & .general__result {
    padding-left: 0px;
  }
}
.result__info:hover {
  & .result__hover {
    opacity: 1;
    pointer-events: auto;
  }
}
.structure__form--block {
  margin-bottom: 30px;
  & .structure__tree {
    min-width: 350px;
    border-radius: 5px;
    border: 1px solid #cecece;
    max-width: 350px;
    & > .structure__tree--element {
      & .structure__fields {
        width: 100%;
        & > ul {
          & > li {
            & > .structure__tree--element {
              width: 100%;
              width: calc(100% + 40px);
              margin-left: -30px;
              margin-right: -10px;
              & > .head__structure--element {
                padding-left: 40px;
                & > p {
                  cursor: pointer;
                }
              }
              & > .structure__fields {
                & > ul {
                  & > li {
                    padding-left: 60px;
                    & > .structure__tree--element {
                      width: 100%;
                      width: calc(100% + 80px);
                      margin-left: -60px;
                      margin-right: -10px;
                      & .head__structure--element {
                        padding-left: 80px;
                        & > p {
                          // cursor:pointer;
                        }
                      }
                      & .structure__fields {
                        & ul {
                          & li {
                            padding-left: 100px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 30px;
            padding-right: 10px;
            display: flex;
            align-items: center;
            align-items: center;
            justify-content: space-between;
            transition: 0.4s ease all;
            font-size: 16px;
            color: #000;
            font-weight: 400;
            & .structure__field--buttons {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              & > .structure__edit {
                margin-right: 10px;
              }
              & a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                transition: 0.4s ease all;
                min-width: 30px;
                max-width: 30px;
                min-height: 30px;
                max-height: 30px;
                border: 1px solid #e3e3e3;
                border-radius: 3px;
                background-color: #fff;
                &:hover {
                  border-color: #0e7d7d;
                  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
                }
              }
            }
            &.structure__click:hover {
              background-color: #e7f2f2;
            }
          }
        }
      }
      & .head__structure--element {
        height: 40px;
        & > p {
          // cursor:pointer;
        }
        & .structure__add-field {
          margin-left: 15px;
          & > a {
            & > span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 5px;
            }
            & > img {
              margin-left: 10px;
            }
            & span {
              & img {
                max-width: 11px;
              }
            }
            background-color: #fff;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            color: #0e7d7d;
            font-size: 12px;
            padding: 3px 5px;
            border: 1px solid #3f88c5;
            border-radius: 3px;
            font-weight: 600;
            min-width: 90px;
            height: 25px;
            position: relative;
            padding-right: 5px;
            -webkit-transition: 0.4s ease all;
            transition: 0.4s ease all;
            text-decoration: none;
          }
        }
        display: flex;
        padding-left: 15px;
        padding-right: 15px;
        align-items: center;

        justify-content: flex-start;
        & > p {
          margin: 0px;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          justify-content: flex-start;
          & > img {
            min-width: 11px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.structure__form--block .structure__tree > .structure__tree--element .head__structure--element > p {
  font-size: 12px;
  color: #000;
  font-weight: 400;
}
.structure__field--elem {
  & > p {
    font-size: 12px;
    color: #000;
    font-weight: 400;
  }
}
.structure__fields--container {
  width: calc(100% - 350px);
  margin-left: 30px;
  & .head__fields {
    margin-bottom: 15px;
    border-bottom: 1px solid #cecece;
    padding-bottom: 0px;
    & > h6 {
      margin: 0px;
      font-size: 20px;
      color: #000;
      padding-bottom: 10px;
      font-weight: 400;
    }
  }
}
.structure__form--block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.modal__termbase.termbase__long {
  & > a {
    right: 1155px;
  }
  & .inner__modal--regular {
    max-width: 1140px;
  }
}
.fields__grid--container {
  & table {
    width: 100%;
  }
}
.structure__fields--container {
  & .fields__grid--container {
    &.table__wrapper {
      & table {
        border-collapse: collapse !important;
      }
      & tr {
        & td {
          padding: 10px 10px;
        }
      }
    }
    & .link__icon {
      position: relative;
      & .link__tooltip {
        display: none;
        min-width: 290px;
        max-width: 290px;
        background: #adadad;
        border-radius: 4px;
        padding: 5px 6px;
        position: absolute;
        left: 0px;
        top: 35px;
        z-index: 3;
        &:before {
          content: '';
          position: absolute;
          left: 6px;
          margin-left: 0px;
          margin-right: auto;
          right: 0px;
          min-width: 12px;
          max-width: 12px;
          min-height: 12px;
          max-height: 12px;
          top: -5px;
          background: url('../images/greytriangle.png');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
        & > p {
          font-size: 12px;
          line-height: 18px;
          color: rgba(255, 255, 255, 1);
          font-weight: 600;
          margin: 0px;
        }
      }
      & a {
        display: inline-flex;
        max-width: 24px;
        min-height: 24px;
        min-height: 24px;
        max-height: 24px;
        & img {
          min-width: 24px;
          max-width: 100%;
        }
      }
    }
  }
}

.field__filled {
  & .permissions__field {
    margin-bottom: 0px;
  }
  & .permissions__field--inner {
    border: 0px;
    & .new__permissions {
      & > input {
        border: 0px;
      }
    }
  }
}
.structure__fields--container {
  & .table__wrapper {
    overflow-x: visible;
    & .permissions__field--inner {
      & .el__field--permissions {
        background-color: #ececec;
        & > p {
          color: #000;
        }
      }
    }
  }
}
.el__field--permissions > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease all;
  &:hover {
    opacity: 0.7;
  }
}
.head__structure--element {
  & > p {
    & > img {
      transform: rotate(-180deg);
      transition: 0.4s ease all;
    }
  }
}
.structure__tree--element.active__tree {
  & > .head__structure--element {
    & > p {
      & > img {
        transform: rotate(0deg);
      }
    }
  }
}
.structure__add-field {
  position: relative;
  & > .structure__dropdown {
    position: absolute;
    z-index: 6;
    min-width: 190px;
    max-width: 190px;
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    & .controls__structure {
      display: flex;
      align-items: center;
      justify-content: center;
      & > a {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-right: 10px;
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        -webkit-transition: 0.4s ease all;
        transition: 0.4s ease all;
        &:last-child {
          margin-right: 0px;
        }
        transition: 0.4s ease all;
        &:hover {
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          border-color: #0e7d7d;
        }
      }
    }
  }
}
.select__choose > h6 {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #cecece;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.select__choose {
  margin-bottom: 20px;
  & .elem__select--choose {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.add__choose > h6 {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #cecece;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.add__choose {
  margin-bottom: 20px;
  & .add__wrapper {
    & .elem__add--choose {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
.structure__dropdown {
  display: none;
}
.modal__import--big {
  & .import__element--form {
    & > form {
      & > h2 {
        margin-top: 0px;
        color: #0e7d7d;
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 30px;
      }
      & > .upload__files {
        & .uploaded__files--additional {
          margin-top: 15px;
          & > p {
            margin-top: 0px;
            margin-bottom: 10px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            color: #9e9e9e;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            & span {
              font-weight: bold;
              color: red;
              margin-right: 7px;
              position: relative;
              top: 2px;
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
        & > p {
          margin-top: 0px;
          margin-bottom: 12px;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
        }
      }
    }
  }
}
.modal__import--big {
  & .inner__modal--regular {
    overflow-x: hidden;
  }
}
.strict__import {
  position: relative;
  margin-top: 20px;
  padding: 20px 0px;
  &:before {
    content: '';
    position: absolute;
    left: -80px;
    width: 800px;
    height: 1px;
    top: 0px;
    background-color: #e3e3e3;
  }
  &:after {
    content: '';
    position: absolute;
    left: -80px;
    width: 800px;
    height: 1px;
    bottom: 0px;
    background-color: #e3e3e3;
  }
  & .strict__float {
    position: absolute;
    left: 0px;
    top: 50px;
    padding: 15px;
    z-index: 4;
    background: #ffffff;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    max-width: 450px;
    & > p {
      margin-top: 0px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      padding-left: 10px;
      color: #000;
      & span {
        font-weight: bold;
      }
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  & .strict__field {
    display: flex;
    align-items: center;
    justify-contnet: flex-start;
    position: relative;
    padding-left: 10px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #000;
    & span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  }
}
.duplicated__entries {
  padding-top: 20px;
  margin-bottom: 45px;
  & .elem__entry {
    margin-bottom: 10px;
    & .radio {
      align-items: flex-start;
      & .radio__box {
        top: 5px;
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
    & p {
      color: #9e9e9e;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      & span {
        color: #2d2d2d;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
      }
    }
  }
  & .head__entries {
    margin-bottom: 10px;
    & > h6 {
      margin: 0px;
      color: #000000;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}
.radio__box {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  margin-right: 10px;
}
.modal__regular.modal__import--big > a {
  right: 665px;
}
.modal__regular.modal__import--big > .inner__modal--regular {
  max-width: 650px;
}
.advanced__container {
  & > .advanced__triple {
    & .advanced__triple--fields {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 15px;
      & > .el__advanced--small {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 0px !important;
        & > p {
          width: 100%;
          margin-right: 0px !important;
          margin-bottom: 10px !important;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
        }
      }
    }
    & .advanced__head {
      margin-bottom: 20px;
      & > span {
        margin: 0px;
        font-size: 12px;
        line-height: 21px;
        color: rgba(45, 45, 45, 0.7);
        font-weight: 400;
      }
      & > p {
        margin: 0px;
        color: #2d2d2d;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
      }
    }
  }
}

.clarify__progress {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  & > .elem__clarify {
    min-height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 95% 0%, 100% 50%, 95% 100%, 0 100%, 0 95%);
    padding-right: 30px;
    margin-right: -20px;
    &:nth-child(1) {
      z-index: 5;
    }
    &:nth-child(2) {
      padding-left: 25px !important;
      position: relative;
      z-index: 3;
    }
    &:nth-child(3) {
      padding-left: 25px;
    }
    &.disabled__termbase {
      background: #f3f3f3;
      border-radius: 6px;
      & h6 {
        opacity: 0.5;
      }
    }
    &.inactive__clarify {
      box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
      background: #fafafa;
      border-radius: 6px;
      & h6 {
        color: rgba(61, 61, 61, 1);
        font-size: 14px;
        line-height: 19px;
        opacity: 0.5;
      }
    }
    &.current__clarify {
      box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      position: relative;
      z-index: 4;
      background: #ffffff;
      padding-left: 0px;
      &:before {
        content: '';
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 3px;
        background: #0e7d7d;
      }
    }
    &:last-child {
      margin-right: 0px;
    }
    & > h6 {
      margin: 0px;
    }
  }
}

.modal__clarify {
  & > a {
    right: 715px;
  }
  & > .inner__modal--regular {
    max-width: 700px;
    & .clarify__block {
      & > h2 {
        margin-top: 0px;
        margin-bottom: 20px;
        font-size: 25px;
        line-height: 34px;
        font-weight: bold;
        color: #0e7d7d;
      }
    }
  }
}
.clarify__errors {
  margin-bottom: 30px;
  & .clarify__error {
    & ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      & > li {
        color: #ff3232;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin: 0px;
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    & p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #ff3232;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin: 0px;
      & > span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        & > img {
          min-width: 22px;
        }
      }
    }
  }
}
.controls__next {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  position: relative;
  z-index: 4;
  margin-top: 30px;
  & > a {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 15px;
    min-height: 40px;
    color: #0e7d7d;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    text-decoration: none;
    transition: 0.4s ease all;
    &:hover {
      opacity: 0.7;
    }
    &.back__controls {
      & > img {
        margin-right: 15px;
      }
    }
    &.next__controls {
      & > img {
        margin-left: 15px;
      }
    }
  }
  & > .back__controls {
    margin-right: 15px;
  }
}
.record__table {
  width: calc(100% + 90px);
  margin-left: -45px;
  overflow-x: auto;
  padding-bottom: 220px;
  margin-bottom: -220px;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
    height: 0px;
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
  & > table {
    min-width: 700px;
    width: 100%;
    & td {
      height: 39px;
      padding: 8px 15px;
    }
    & th {
      height: 39px;
      padding: 8px 15px;
      & h5 {
        margin: 0px;
        color: rgba(158, 158, 158, 1);
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        display: flex;
        text-transform: uppercase;
        align-items: center;
        justify-content: flex-start;
        & > img {
          max-width: 30px;
          margin-left: 5px;
          min-width: 30px;
        }
      }
    }
  }
}
.match__block {
  & > h2 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 25px;
    line-height: 34px;
    font-weight: bold;
    color: #0e7d7d;
  }
  & > .match__inner {
    width: 100%;
    & .match__field {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > input {
        min-width: 150px;
        max-width: 150px;
        min-height: 40px;
        border: 1px solid #9e9e9e;
        border-radius: 3px;
        padding-left: 15px;
        padding-right: 15px;
        color: rgba(45, 45, 45, 1);
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        outline: none;
      }
      & > p {
        margin-top: 0px;
        color: #2d2d2d;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
      }
    }
    & > p {
      margin-bottom: 30px;
      color: #3b3b3b;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }
}
.modal__regular.modal__clarify > .inner__modal--regular {
  max-width: 700px;
}
.modal__regular.modal__clarify > a {
  right: 715px;
}
.table__record {
  border-collapse: collapse !important;
  & tr {
    &.content__droppable {
      &.second__layer {
        & > td:nth-child(1) {
          padding-left: 100px;
        }
      }
      &.first__layer {
        & > td:nth-child(1) {
          padding-left: 70px;
        }
      }
      & .document__info {
        & > .no__match {
          padding-left: 25px;
          & > p {
            margin: 0px;
            color: #9e9e9e;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
          }
        }
        & > .document__input {
          position: relative;
          width: 100%;
          left: -10px;
          display: flex;
          align-items: center;
          & > span {
            position: absolute;
            left: 9px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #2d2d2d;
            font-size: 14px;
            line-height: 21px;
            min-width: 14px;
          }
          & > a {
            padding-right: 15px;
            padding-left: 35px;
            width: 100%;
            height: 35px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: #ffffff;
            border: 1px solid rgba(14, 125, 125, 0.3);
            border-radius: 3px;
            overflow: hidden;
            background: url('../images/selectback.svg');
            background-repeat: no-repeat;
            background-size: auto;
            background-position: 95% center;
            & > span {
              display: flex;
              width: 100%;
              white-space: nowrap;
              &.empty__subject {
                color: rgba(158, 158, 158, 1);
              }
              color: #000;
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
        & > p {
          padding-left: 9px 15px;
          margin: 0px;
          display: inline-flex;
          align-items: center;
          justify-contentr: flex-start;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          & > span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #2d2d2d;
            font-size: 14px;
            line-height: 21px;
            min-width: 14px;
            margin-right: 10px;
          }
        }
      }
      & .document__headline {
        &.headline__match {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & .document__match {
            position: relative;
            & .doc__drop {
              position: absolute;
              left: 0px;
              top: 30px;
              width: 100%;
              background: #ffffff;
              box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
              border-radius: 4px;
              padding: 5px;
              z-index: 4;
              & ul {
                width: 100%;
                & > li {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  & > a {
                    padding: 10px;
                    border-radius: 4px;
                    transition: 0.4s ease all;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: flex-start;
                    height: 30px;
                    color: rgba(45, 45, 45, 1);
                    font-size: 12px;
                    line-height: 21px;
                    font-weight: 400;
                    &:hover {
                      background: #ebf1f1;
                    }
                  }
                }
                &::-webkit-scrollbar {
                  width: 3px;
                  border-radius: 3px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                  background: #e9e9e9;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: #aeaeae;
                  border-radius: 3px;
                }
                max-height: 200px;
                overflow-y: auto;
              }
            }
            & > a {
              max-width: 165px;
              overflow: hidden;
              display: inline-flex;
              align-items: center;
              height: 30px;
              border: 1px solid rgba(14, 125, 125, 0.3);
              border-radius: 3px;
              white-space: nowrap;
              padding-left: 10px;
              padding-right: 40px;
              background: url('../images/selectback.svg');
              background-repeat: no-repeat;
              background-size: auto;
              background-position: 95% center;
              & > span {
                &.empty__match {
                  color: rgba(158, 158, 158, 1);
                }
                color: #000;
                font-size: 12px;
                line-height: 18px;
              }
            }
          }
        }
        & p {
          margin: 0px;
          display: flex;
          align-items: center;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          justify-content: flex-start;
          & > .filter__document {
            margin-left: 10px;
          }
          & > span {
            min-width: 18px;
            max-width: 18px;
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
            justify-content: center;
            & > img {
              max-width: 100%;
            }
          }
        }
      }
    }
    & th {
      border-bottom: 1px solid #e3e3e3;
    }
    & .center__text {
      text-align: center;
      & > h5 {
        text-align: center;
        justify-content: center;
      }
    }
    & .center__text {
      text-align: center;
    }
    &.sub__droppable {
      & > td {
        padding-left: 70px;
      }
    }
    & td {
      border-bottom: 1px solid #e3e3e3;
      & .head__record {
        &.droppable__record {
          &.record__opened {
            & > h5 {
              & > img {
                transform: rotate(0deg);
              }
            }
          }
          & > h5 {
            & > .match {
              color: #2d2d2d;
              font-size: 14px;
              line-height: 21px;
              font-weight: 400;
              &.not__found {
                color: #9e9e9e;
              }
            }
            & > .flag__image {
              display: inline-flex;
              align-tems: center;
              justify-content: center;
              margin-right: 10px;
              & > img {
                max-width: 21px;
              }
            }
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: flex-start;
            & > img {
              margin-right: 10px;
              transform: rotate(-90deg);
              transition: 0.4s ease all;
            }
          }
        }
        & > h5 {
          margin: 0px;
          color: #2d2d2d;
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
        }
      }
    }
  }
}
.document__dropdown {
  position: absolute;
  right: 0px;
  top: 40px;
  z-index: 5;
  width: 100%;
  padding: 10px;
  min-width: 340px;
  background: #ffffff;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  & ul {
    padding: 0px;
    list-style-type: none;
    margin: 0px;
    max-height: 200px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #e9e9e9;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aeaeae;
      border-radius: 3px;
    }
    & > li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: q0px;
      }
      & > a {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        transition: 0.4s ease all;
        & > .doc__letter {
          color: rgba(120, 120, 120, 1);
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          min-width: 20px;
          text-align: center;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        & > p {
          margin: 0px;
          color: rgba(120, 120, 120, 1);
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          margin-left: 12px;
          margin-right: 12px;
        }
        &:hover {
          background-color: rgba(235, 241, 241, 1);
        }
        & > .link__status {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-width: 24px;
          max-width: 24px;
          & > img {
            max-width: 100%;
          }
          margin-left: auto;
        }
      }
    }
  }
}
.double__buttons--document {
  display: flex;
  align-items: center;
  justify-content: center;
  & .warn__info {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    position: relative;
    & > .warn__tooltip {
      position: absolute;
      right: 0px;
      top: 30px;
      padding: 15px;
      min-width: 245px;
      background: #ffffff;
      box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      display: none;
      z-index: 2;
      & > p {
        margin: 0px;
        color: rgba(45, 45, 45, 1);
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
      }
    }
    & > img {
      max-width: 20px;
    }
  }
}
.underline__span {
  cursor: pointer;
  transition: 0.4s ease all;
  border-bottom: 1px solid transparent;
  &.active__underline {
    border-color: #3d3d3d;
  }
}
.head__structure--element {
  & p {
    cursor: pointer !important;
  }
}
.table__wrapper table.table__common.transparent__table tr.placeholder__tr {
  transition: 0.4s ease all;
}
.table__wrapper table.table__common.transparent__table tr.placeholder__tr.temporary__placeholder {
  background-color: rgba(255, 0, 0, 0.15) !important;
}
.placeholder__tr .remove__row--field {
  display: none;
}
.field__picker {
  min-width: 150px;
}
.placeholder__tr {
  & .field__picker {
    & > a {
      pointer-events: none;
    }
  }
}
.fields__grid--container {
  &.table__wrapper {
    & tr {
      & td {
        padding: 7px 15px !important;
      }
    }
  }
}
.remove__row--field > a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease all;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;
  background-color: #e2e7eb;
  &:hover {
    opacity: 0.7;
  }
}
.field__picker {
  position: relative;
  & > a {
    display: flex;
    width: 100%;
    padding: 5px 0px;
  }
  & .field__drop {
    display: none;
    position: absolute;
    width: 100%;
    left: 0px;
    z-index: 5;
    top: 30px;
    background: #ffffff;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
    & ul {
      list-style-type: none;
      margin: 0px;
      & li {
        width: 100%;
        display: flex;
        & > a {
          display: flex;
          width: 100%;
          padding: 7px 15px;
          font-size: 14px;
          line-height: 21px;

          transition: 0.4s ease all;
          font-weight: 400;
          color: #2d2d2d;
          &:hover {
            background-color: #e7f2f2;
          }
        }
      }
    }
  }
}
.placeholder__tr {
  & .unlink__icon {
    display: none;
  }
}
.field__value {
  & > input {
    background-color: transparent;
  }
}
.picklist__border .new__picklist > input {
  background-color: transparent;
}
.field__new {
  & > input {
    margin-left: -10px;
    height: 30px;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
  }
}
.unlink__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.placeholder__tr {
  & .add__row--field {
    display: inline-flex !important;
  }
}
.add__row--field {
  display: none !important;
}
.add__row--field {
  & > a {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    border-radius: 5px;
    background-color: #e2e7eb;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s ease all;
    &:hover {
      opacity: 0.7;
    }
  }
}
.field__value > input {
  margin-left: -10px;
  height: 30px;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
}
.table__wrapper table.table__common.transparent__table tr.dcurr__field {
  // background-color:#E7F2F2!important;
}
.unlink__icon {
  & > img {
    min-width: 24px;
  }
}
.pick__list {
  margin-bottom: 15px;
  position: relative;
  & .pick__list--dropdown {
    position: absolute;
    left: 0px;
    top: 35px;
    width: 100%;
    background: #ffffff;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
    display: none;
    z-index: 3;
    & ul {
      width: 100%;
      padding: 0px;
      list-style-type: none;
      margin: 0px;
      & > li {
        width: 100%;
        display: flex;
        & > a {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          padding: 7px 15px;
          font-size: 14px;
          line-height: 21px;
          -webkit-transition: 0.4s ease all;
          transition: 0.4s ease all;
          font-weight: 400;
          color: #2d2d2d;
          word-break: break-all;
          &:hover {
            background-color: #e7f2f2;
          }
        }
      }
    }
  }
  & > a {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #3d3d3d;
    overflow: hidden;
    height: 35px;
  }
}
.field__created {
  &.linked__field {
    & .unlink__icon {
      opacity: 0;
      pointer-events: none;
    }
  }
}
.pick__list {
  // &>a{
  //   pointer-events:none;
  // }

  &.not__empty {
    // cursor:pointer;
    // &>a{

    //   pointer-events:initial!important;
    // }
  }
}
.picklist__border {
  border: 1px solid #c5c5c5;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  -ms-flex-wrap: wrap;
  border-radius: 3px;
  flex-wrap: wrap;
  padding: 5px;
  & > .el__picklist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 5px 10px;
    background: #f3f3f3;
    border-radius: 3px;
    margin-right: 5px;
    margin-bottom: 5px;
    & p {
      margin: 0px;
      margin-right: 5px;
      font-size: 12px !important;
      line-height: 16px !important;
      color: #2d2d2d !important;
      font-weight: 400 !important;
    }
    & a {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-transition: 0.4s ease all;
      transition: 0.4s ease all;
      margin-left: 10px;
      & img {
        min-width: 11px;
      }
    }
  }
  & .new__picklist {
    & > input {
      color: #2d2d2d;
      font-size: 14px;
      font-weight: 400;
      height: 26px;
    }
  }
}
.modal__termbase.termbase__long .inner__modal--regular {
  overflow-x: hidden;
}
.structure__field--buttons {
  margin-left: 10px;
}
.structure__field--elem > p {
  outline: none;
  padding: 3px;
  border-radius: 3px;
}
.structure__field--elem > p:focus {
  border: 1px solid #3f88c5;
}
.general__right {
  & .lang__picker--general {
    & .select {
      & .select__dropdown {
        left: auto;
        right: 0px;
        transform: none;
      }
      position: relative;
      & > img {
        position: absolute;
        left: 12px;
        top: 6px;
      }
      & .select__dropdown {
        margin-top: 0px;
      }
      & > button {
        &:before {
          content: none;
        }
        &:after {
          content: none;
        }
        &:hover {
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
        height: auto;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 3px 10px;
        border: 1px solid #0e7d7d;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        min-height: 30px;
        -webkit-transition: 0.4s ease all;
        transition: 0.4s ease all;
        white-space: nowrap;
        margin-right: 10px;
        color: #0e7d7d;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 12px;
        font-weight: 600;
        background-color: transparent;
        padding-left: 30px;
        & > img {
          margin-right: 8px;
          max-width: 13px;
        }
      }
    }
  }
}
.structure__checkboxes {
  margin-bottom: 15px;
  & .elem__structure--checkbox {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.structure__choose--level {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #cecece;
  & > .elem__choose {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.structure__field--elem {
  &.Text {
    padding-left: 30px;
    background: url('../images/treeicon1.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center left;
  }
}
.structure__field--elem {
  &.Number {
    padding-left: 30px;
    background: url('../images/treeicon2.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center left;
  }
}
.structure__field--elem {
  &.Boolean {
    padding-left: 30px;
    background: url('../images/treeicon3.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center left;
  }
}
.structure__field--elem {
  &.Date {
    padding-left: 30px;
    background: url('../images/treeicon5.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center left;
  }
}
.structure__field--elem {
  &.Picklist {
    padding-left: 30px;
    background: url('../images/treeicon4.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center left;
  }
}
.fields__grid--container {
  & table {
    & tr {
      transition: 0.4s ease all;
      & td {
        transition: 0.4s ease all;
      }
      &:hover {
        & td {
          background-color: #ebf1f1;
        }
      }
    }
  }
}
.general__right {
  & .lang__picker--general.picked {
    & .select {
      & > img {
        display: none;
      }
      & > button {
        padding: 3px 10px;
      }
    }
  }
}

.group__tag--checkbox {
  margin-bottom: 15px;
  position: relative;
  & .target__dropdown {
    position: absolute;
    left: 0px;
    top: 100%;
    width: 100%;
    display: none;
    background: #ffffff;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    z-index: 5;
    padding: 5px;
    padding-right: 5px;
    & > ul {
      padding: 0px;
      list-style-type: none;
      margin: 0px;
      max-height: 310px;
      overflow-y: auto;
      padding-right: 0px;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #e9e9e9;
      }
      &::-webkit-scrollbar-thumb {
        background: #aeaeae;
        border-radius: 5px;
      }
      & > li {
        &:hover {
          background-color: #ebf1f1;
        }
        display: flex;
        width: calc(100% - 5px);
        transition: 0.4s ease all;
        border-radius: 4px;
        & > label {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 10px 15px;
          color: #0e7d7d;
          font-size: 14px;
          line-height: 21px;
          width: 100%;
          font-weight: 600;
          -webkit-transition: 0.4s ease all;
          transition: 0.4s ease all;
          border-radius: 4px;
        }
      }
    }
  }
  & > p {
    margin-top: 0px;
    margin-bottom: 10px;
    color: #2d2d2d;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
  }
  & > .group__target--container {
    padding: 5px;
    min-height: 38px;
    cursor: pointer;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
    padding-bottom: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    transition: 0.4s ease all;
    border: 1px solid #c5c5c5;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    background-image: url('../images/servicearrow.svg');
    background-size: 11px 6px;
    background-position: 95%;
    padding-right: 29px;
    background-repeat: no-repeat;
    & > input {
      color: #2d2d2d;
      font-size: 14px;
      font-weight: 400;
      height: 26px;
    }
    &.focused__target {
      border-color: #0e7d7d;
    }
    & > .el__field--target {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 5px 10px;
      margin-right: 5px;
      margin-bottom: 5px;
      background: #f3f3f3;
      border-radius: 3px;
      & > p {
        color: #2d2d2d;
        font-weight: 400;
        margin: 0px;
        margin-right: 5px;
        font-size: 12px;
        line-height: 16px;
      }
      & > a {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-transition: 0.4s ease all;
        transition: 0.4s ease all;
      }
    }
    & > .new__permissions {
      margin-bottom: 5px;
      margin-right: 5px;

      & > input {
        color: #2d2d2d;
        font-size: 14px;
        font-weight: 400;
        height: 26px;
      }
    }
  }
}

.modal__structure {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background: rgba(22, 20, 25, 0.6);
  backdrop-filter: blur(4px);
  height: 100%;
  z-index: 100;
  & .inner__structure {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 100%;
    width: 100%;
    & > .structure__box {
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      background-color: #fff;
      max-width: 800px;
      & .structure__example {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid #e3e3e3;
        width: 100%;
        overflow-x: auto;
        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
          border-radius: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #e9e9e9;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #aeaeae;
          border-radius: 3px;
        }
        & > .example__left {
          width: 100%;
          min-width: 400px;
          & > .sample__tree {
            & .sample__tree--layer {
              &.opened__layer {
                & > .head__sample--tree {
                  & > a {
                    transform: rotate(90deg);
                  }
                }
              }
              & > .head__sample--tree {
                & > a {
                  transition: 0.4s ease all;
                }
              }
            }
            & > .sample__tree--layer {
              & > .content__sample--tree {
                padding-left: 55px;
                padding-right: 20px;
                & .after__icon {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 10px;
                }
                & > .sample__tree--layer {
                  & > .content__sample--tree {
                    & > .elem__sample--tree {
                      padding: 10px 0px;
                      position: relative;
                      display: flex;
                      align-items: center;

                      & > p {
                        margin: 0px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;
                        color: #2d2d2d;
                        & > span {
                          min-width: 24px;
                          max-width: 24px;
                          min-height: 24px;
                          max-height: 24px;
                          display: inline-flex;
                          align-items: center;
                          justify-content: center;
                          margin-right: 10px;
                          & > img {
                            max-width: 100%;
                          }
                        }
                      }
                    }
                  }
                  & > .head__sample--tree {
                    padding: 10px 0px;
                    position: relative;
                    align-items: center;
                    justify-content: flex-start;
                    display: flex;
                    align-items: center;
                    & > a {
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      min-width: 18px;
                      max-width: 18px;
                      min-height: 18px;
                      max-height: 18px;
                      margin-right: 10px;
                    }
                    & > p {
                      margin: 0px;
                      display: inline-flex;
                      align-items: center;
                      justify-content: flex-start;
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: 400;
                      color: #2d2d2d;
                      & > span {
                        min-width: 24px;
                        max-width: 24px;
                        min-height: 24px;
                        max-height: 24px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        & > img {
                          max-width: 100%;
                        }
                      }
                    }
                    &.tree__button {
                      margin-left: -28px;
                    }
                  }
                }
                & > .elem__sample--tree {
                  padding: 10px 0px;
                  position: relative;
                  display: flex;
                  align-items: center;

                  & > p {
                    margin: 0px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    color: #2d2d2d;
                    & > span {
                      min-width: 24px;
                      max-width: 24px;
                      min-height: 24px;
                      max-height: 24px;
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      margin-right: 10px;
                      & > img {
                        max-width: 100%;
                      }
                    }
                  }
                }
              }
              & > .head__sample--tree {
                padding: 10px 35px;
                padding-right: 20px;
                & > p {
                  margin: 0px;
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  color: #2d2d2d;
                }
              }
            }
          }
        }
        & > .example__right {
          padding: 35px;
          background: #fcfcfc;
          width: 100%;
          max-width: 200px;
          min-width: 200px;
          & ul {
            margin-bottom: 40px;
            padding-bottom: 40px;
            border-bottom: 1px solid #e3e3e3;
            & > li {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 10px;

              color: rgba(45, 45, 45, 1);
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              &:last-child {
                margin-right: 0px;
              }
              & > span {
                display: inline-flex;
                align-items: center;
                min-width: 24px;
                max-width: 24px;
                min-height: 24px;
                max-height: 24px;
                justify-content: center;
                margin-right: 10px;
                & > img {
                  max-width: 100%;
                }
              }
            }
            &:last-child {
              margin-bottom: 0px;
              border-bottom: 0px;
            }
          }
        }
      }
      & > .structure__controls {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        align-items: center;
        & > a {
          display: inline-flex;
          max-width: 80px;
          width: 100%;
        }
      }
      & > .structure__samples {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 35px;
        border-bottom: 1px solid #e3e3e3;
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: flex-end;
          text-decoration: none;
          color: #0e7d7d;
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          & > span {
            display: inline-flex;
            margin-right: 10px;
          }
        }
      }
      & > .head__structure {
        padding: 15px 35px;
        border-bottom: 1px solid #e3e3e3;
        & > h2 {
          color: #2d2d2d;
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          margin: 0px;
        }
      }
    }
  }
}
.float__modal--buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% + 90px);
  margin-top: 10px;
  margin-left: -45px;
  border-top: 1px solid #e2e7eb;
  padding: 15px;
  padding-bottom: 0px;
  & > .step__buttons {
    & > .controls__next {
      margin: 0px;
      margin-right: 16px;
      padding-right: 15px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        right: 0px;
        width: 1px;
        height: 20px;
        background-color: #d0d0d0;
      }
    }
  }
  & > .controls__float--modal {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > input,
    & > button,
    & > a {
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
.delete__float {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ff3232;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  padding: 12px 15px;
  -ms-flex-pack: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}
.registrations__modal .inner__registrations {
  overflow-x: hidden;
}
.record__table {
  & table {
    border-collapse: collapse;
    width: 100%;
  }
  & tr {
    &.opened__tree {
      & .arrow__tree {
        & > img {
          transform: rotate(0deg) !important;
        }
      }
    }
    &.second__layer {
      & > td {
        &:nth-child(1) {
          padding-left: 70px;
        }
      }
    }
    &:last-child {
      & td {
        border-bottom: 0px;
      }
    }
    & td {
      & .layer__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & > .arrow__tree {
          cursor: pointer;
          margin-right: 5px;
          & > img {
            transition: 0.4s ease all;
            transform: rotate(-90deg);
          }
        }
      }
      &:last-child {
        min-width: 90px;
        max-width: 90px;
        width: 90px;
        padding-right: 25px;
      }
      & .import__block {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        text-align: center;
        height: 100%;
        & .warn__info {
          position: absolute;
          right: -4px;
          display: inline-flex;
          align-items: center;
          & span {
            position: absolute;
            left: -220px;
            min-width: 210px;
            max-width: 210px;
            display: flex;
            text-align: left;
            padding: 15px;
            background: #ffffff;
            box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
            font-size: 14px;
            color: #2d2d2d;
            justify-content: flex-start;
            line-height: 21px;
            font-weight: 400;
            z-index: 5;
            border-radius: 5px;
          }
          & > img {
            max-width: 20px;
          }
        }
      }
      & .select__field {
        & .select__field--inner {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & .select__icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            & > img {
              max-width: 18px;
            }
          }
          & .select__flag {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            & > img {
              max-width: 18px;
            }
          }
          & .select__link {
            & > span {
              display: -webkit-inline-box;
              display: -ms-inline-flexbox;
              display: inline-flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              color: #2d2d2d;
              font-size: 14px;
              line-height: 21px;
              min-width: 14px;
              margin-right: 10px;
              transition: 0.4s ease all;
            }
          }
          & .select__input {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            & .dropdown__select--field {
              position: absolute;
              left: 0px;
              top: 30px;
              width: 100%;
              z-index: 5;
              background-color: #fff;
              border-radius: 5px;
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
              & > ul {
                padding: 0px;
                list-style-type: none;
                margin: 0px;
                width: 100%;
                max-height: 220px;
                overflow-y: auto;
                &::-webkit-scrollbar {
                  width: 3px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                  background: #fff;
                  border-radius: 5px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: #0e7d7d;
                  border-radius: 5px;
                }
                & > li {
                  display: flex;
                  align-items: center;
                  justify-content: justify-content;
                  & > a {
                    transition: 0.4s ease all;
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    padding-right: 30px;
                    & > img {
                      position: absolute;
                      right: 10px;
                    }
                    & > span {
                      display: inline-flex;
                      align-items: center;
                      margin-right: 20px;
                      font-weight: 500;
                      min-width: 14px;
                      justify-content: center;
                      align-items: center;
                    }
                    &:hover {
                      background-color: rgba(14, 125, 125, 0.1);
                    }
                    padding: 4px 10px;
                  }
                }
              }
            }
            & > a {
              position: absolute;
              right: 10px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              display: none;
              opacity: 0;
              transition: 0.4s ease all;
            }
            &.focused__select {
              & > a {
                opacity: 1;
                display: inline-flex;
              }
              & > input {
                border-radius: 3px;
                border-color: #0e7d7d;
              }
            }
            & > input {
              width: 100%;
              height: 30px;
              font-size: 14px;
              line-height: 21px;
              font-weight: 400;
              padding-left: 10px;
              padding-right: 10px;
              border: 1px solid #fff;
              transition: 0.4s ease all;
            }
          }
        }
      }

      &.no__spacer {
        padding: 3px 0px;
      }
      padding: 3px 15px;
      border-bottom: 1px solid #e3e3e3;
      & > p {
        margin: 0px;
        color: #2d2d2d;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
      }
    }
  }
  & th {
    border-bottom: 1px solid #e3e3e3;
    &:nth-child(3) {
      width: 90px;
      padding-right: 25px;
    }
    & h6 {
      margin: 0px;
      color: #9e9e9e;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-transform: uppercase;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      & span {
        max-width: 30px;
        margin-left: 5px;
        min-width: 30px;
      }
    }
  }
}
.not__filled {
  & > td {
    &:nth-child(1) {
      & .select__icon,
      & .select__flag {
        opacity: 0.5;
      }
      .select__input input {
        color: rgba(158, 158, 158, 1);
      }
    }
  }
  & > td {
    &:nth-child(2) {
      & .select__link {
        & span {
          color: rgba(158, 158, 158, 1) !important;
        }
      }
      .select__input input {
        color: rgba(158, 158, 158, 1);
      }
    }
  }
}
tr.error__tr .import__block .warn__info {
  opacity: 1;
  pointer-events: initial;
}
.import__block .warn__info {
  opacity: 0;
  pointer-events: none;
}
.clarify__error {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.record__table {
  & tr {
    & td {
      & .select__field {
        & .select__field--inner {
          & .select__input {
            & .dropdown__select--field {
              padding: 5px;
              & > ul {
                & > li {
                  & > a {
                    font-size: 12px;
                    padding: 10px;
                    transition: 0.4s ease all;
                    border-radius: 4px;
                    &:hover {
                      background: #ebf1f1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.dropdown__select--field {
  & ul {
    & li {
      & a {
        & > .linked__tooltip {
          display: inline-flex;
          align-items: center;
          justify-cotnent: center;
          position: absolute;
          right: 10px;
        }
      }
      & a.linked {
        & > .linked__tooltip {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
}
.tooltip__linked {
  position: absolute;
  display: none;
  max-width: 210px;
  z-index: 500;
  margin-left: -220px;
  & > span {
    display: inline-flex;
    padding: 15px;
    background: #ffffff;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: rgba(45, 45, 45, 1);
    line-height: 21px;
    font-weight: 400;
    border-radius: 5px;
  }
}
.entry__minified,
.remove__minified,
.import__entry--button,
.export__head,
.structure__minified {
  position: relative;
}
.terms__buttons {
  & .tooltip__minified {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -25px;
    display: none;
    & > span {
      padding: 3px 9px;
      background: rgba(56, 56, 56, 0.8);
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      font-size: 13px;
      line-height: 21px;
      font-weight: 400;
      color: #ffffff;
      white-space: nowrap;
    }
  }
}

.regular__languages {
  & .group__tag--checkbox {
    margin-bottom: 0px;
  }
  & .group__target--container {
    & > input {
      width: 130px;
    }
  }
}
.link__information--tooltip {
  position: absolute;
  display: none;
  max-width: 210px;
  z-index: 500;
  margin-left: -220px;
  & > span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 15px;
    background: #ffffff;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: #2d2d2d;
    line-height: 21px;
    font-weight: 400;
    border-radius: 5px;
  }
}
.name__radio {
  & .row-group__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & .radio__name {
      white-space: nowrap;
    }
  }
}
.payment__records {
  margin-top: 25px;
  background: #ffffff;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05), 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  overflow: hidden;
  & .total__unpaid {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    & > p {
      margin: 0px;
      font-size: 12px;
      line-height: 18px;
      color: #2d2d2d;
      font-weight: 600;
    }
    & > span {
      font-size: 12px;
      line-height: 18px;
      color: #2d2d2d;
      font-weight: 600;
    }
  }
  & .content__records {
    & > .elem__record {
      margin-bottom: 10px;
      padding: 10px;
      background: #e7f2f2;
      border-radius: 10px;
      & > .record__el {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0px;
        }
        & > span {
          color: #2d2d2d;
          font-size: 12px;
          line-height: 21px;
          font-weight: 600;
          text-align: right;
          &.record__green {
            color: #00c868;
          }
          &.record__red {
            color: #ff3232;
          }
        }
        & > p {
          font-size: 12px;
          line-height: 20px;
          color: #2d2d2d;
          font-weight: 600;
          margin: 0px;
        }
      }
      &:hover {
        & > .record__remove {
          opacity: 1;
        }
      }
      & > .record__remove {
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: auto;
        margin-bottom: 5px;
        opacity: 0;
        transition: 0.4s ease all;
        & > a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    padding: 10px;
  }
  & .head__records {
    width: 100%;
    padding: 10px 15px;
    background: #0e7d7d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // &.opened__head{
    //   &>.records__status{
    //     &>span{
    //       &:nth-child(2){
    //         opacity:0;
    //       }
    //     }
    //   }
    // }
    & > h2 {
      margin: 0px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
    & > .records__status {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 26px;
      max-width: 26px;
      min-height: 26px;
      max-height: 26px;
      background: #ffffff;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      position: relative;
      & > span {
        &:nth-child(1) {
          width: 12px;
          height: 2px;
          background-color: #0e7d7d;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        &:nth-child(2) {
          height: 12px;
          width: 2px;
          background-color: #0e7d7d;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.4s ease all;
        }
      }
    }
  }
}
.add__plan {
  position: relative;
  z-index: 1;
}
.header__notification .dropdown__box {
  min-height: 0px;
  width: 100%;
  min-width: 350px;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 0px;
  & .notification__list {
    & .switcher__wrapper {
      padding-top: 25px;
      & .content__switcher {
        & .el__switcher {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0px;
          & .status__switch {
            margin-left: 5px;
            & > a {
              min-width: 90px;
              min-height: 30px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background: #ffffff;
              border: 1px solid #0e7d7d;
              border-radius: 4px;
              font-size: 13px;
              line-height: 20px;
              color: #0e7d7d;
              font-weight: 600;
              text-decoration: none;
              transition: 0.4s ease all;
              &:hover {
                opacity: 1 !important;
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
              }
              & span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;
              }
            }
            & .active__switch {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              min-height: 30px;
              min-width: 95px;
              background: #ebf1f1;
              border-radius: 50px;
              font-size: 14px;
              line-height: 14px;
              color: #0e7d7d;
              font-weight: 600;
            }

            & .archived__switch {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              min-height: 30px;
              min-width: 95px;
              background: #fbe7d5;
              border-radius: 50px;
              font-size: 14px;
              line-height: 14px;
              color: #e27a00;
              font-weight: 600;
            }
          }
          & > p {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            & a {
              display: inline-flex;
              font-weight: bold;
              text-decoration: underline;
            }
          }
        }
      }
    }
    & .switcher__notif {
      & ul {
        & > li {
          margin-right: 20px;
          &.active__notif {
            & > a {
              color: #0e7d7d;
              font-weight: 600;
              &:before {
                opacity: 1;
              }
              & span {
                color: #0e7d7d;
              }
            }
          }
          & > a {
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
            color: #9e9e9e;
            text-decoration: none;
            position: relative;
            &:before {
              content: '';
              position: absolute;
              left: 0px;
              bottom: -5px;
              width: 100%;
              height: 2px;
              background-color: #0e7d7d;
              border-radius: 2px;
              opacity: 0;
            }
            & span {
              padding: 0px 10px;
              min-height: 24px;
              display: inline-flex;
              align-items: center;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              color: #9e9e9e;
              margin-left: 10px;
              background: rgba(145, 158, 158, 0.1);
              border-radius: 50px;
              justify-content: center;
            }
          }
          &:last-child {
            margin-right: 0px;
          }
        }
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}
.notifications-dropdown__scroll {
  padding: 0px;
}
.tooltip__notification {
  position: fixed;
  top: 80px;
  left: 500px;
  z-index: 10;
  background-color: rgba(14, 125, 125, 1);
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
  & span {
  }
}
.notification__messages {
  & .head__notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid rgb(227, 227, 227);
    & .mark__read {
      position: relative;
      &:hover {
        & > span {
          opacity: 1;
        }
      }
      & > span {
        padding: 5px 10px;
        background-color: rgba(14, 125, 125, 1);
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        position: absolute;
        left: -133px;
        white-space: nowrap;
        top: -7px;
        opacity: 0;
        transition: 0.3s ease all;
        &:before {
          content: '';
          position: absolute;
          left: auto;
          right: -3px;
          bottom: 9px;
          top: auto;
          margin-left: auto;
          margin-right: auto;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid #0e7d7d;
          border-top: 6px solid transparent;
          transform: rotate(-42deg);
        }
      }
      & a {
        position: relative;
        & span {
          position: absolute;
          top: -18px;
        }
        &:hover {
          opacity: 1;
          & svg {
            fill: rgba(14, 125, 125, 1);
          }
        }
        & svg {
          width: 16px;
          fill: #9e9e9e;
          transition: 0.3s ease all;
        }
      }
    }
    & > h2 {
      margin: 0px;
      font-size: 18px;
      color: rgba(14, 125, 125, 1);
      font-weight: bold;
    }
  }
}
.header__notification .dropdown__box {
  max-height: 400px;
}
.notifications-dropdown__scroll {
  max-height: 400px !important;
}
.notification__messages--list {
  max-height: 345px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #e9e9e9;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aeaeae;
    border-radius: 3px;
  }
  & > .elem__notification--message {
    border-bottom: 1px solid #d4d8dd;
    position: relative;
    &:last-child {
      border-bottom: 0px;
    }
    &:hover {
      & .notification__controls--details {
        & > .read__status {
          opacity: 1;
        }
        & > .remove__notification {
          opacity: 1;
        }
      }
      & > a {
        background-color: #f2f3f5;
        opacity: 1 !important;
      }
    }
    & .notification__controls--details {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > .remove__notification {
        opacity: 0;
        transition: 0.3s ease all;
      }
      & > .read__status {
        &.unread--message {
          opacity: 1;
          background-color: #0e7d7d;
        }
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 8px;
        max-width: 8px;
        min-height: 8px;
        max-height: 8px;
        border-radius: 250px;
        background-color: #9e9e9e;
        margin-bottom: 10px;
        opacity: 0;
        transition: 0.3s ease all;
      }
    }
    & > a {
      padding: 15px;
      display: flex;
      align-items: flex-start !important;
      justify-content: flex-start;
      padding-right: 30px;
      transition: 0.4s ease all;

      & > .el__notification--details {
        & > h2 {
          font-weight: 600;
          font-size: 16px;
          color: #0e7d7d;
          margin-bottom: 5px;
        }
        & > p {
          font-size: 14px;
          color: #000;
          margin: 0px;
          margin-bottom: 5px;
          opacity: 0.8;
        }
        & > span {
          color: #555b60;
          font-weight: 400;
          font-size: 12px;
        }
      }
      & > .el__notification--image {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        min-width: 38px;
        min-width: 38px;
        min-height: 38px;
        max-height: 38px;
        border-radius: 250px;
        & span {
          & svg {
            width: 15px;
            max-height: 15px;
          }
        }
        & > img {
          min-width: 38px;
          max-width: 38px;
          border-radius: 250px;
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .status__employers .success__status {
    padding-right: 240px;
  }
  .status__employers .finish__info .finish__text > h2 {
    max-width: 340px;
    white-space: inherit;
  }
  .status__employers {
    & .deadline__status {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .deadline__left {
        margin-bottom: 10px;
        & .deadline__image {
          margin-bottom: 0px;
          margin-right: 10px;
        }
        & > h6 {
          margin-right: 0px;
        }
      }
    }
  }
  .freelancer__box .freelancer__main {
    & .status__employers {
      & .correction__status {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & .correction__left {
          margin-bottom: 10px;
        }
      }
    }
  }
  .status__employers .success__status .success__note {
    min-width: 190px;
  }
  .status__employers .success__status .success__image {
    margin-right: 30px;
  }
}
@media (max-width: 1280px) {
  .order__create--form .head__create--controls .create__info .check__input .check__float {
    position: static;
  }
  .create__info.order__info--create {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .order__info--create.create__info .check__head {
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    & > .custom__table--field {
      margin-right: 0px;
    }
    & > .currency__picker {
      margin-right: 0px;
      margin-bottom: 10px;
      width: 100%;
      max-width: 100%;
      & > .val__currency {
        width: 100%;
        & > input {
          width: 100%;
          max-width: 100%;
        }
      }
      & > .select {
        max-width: 50px;
      }
    }
    & > .table__custom--right {
      width: 100%;
      max-width: 100%;
      margin-bottom: 10px;
      & > input {
        width: 100%;
        max-width: 100%;
      }
    }
    & > .service__locked {
      max-width: 100%;
      width: 100%;
      margin-right: 0px;
      margin-bottom: 10px;
      & > p {
        position: static;
        margin-top: 5px;
      }
    }
    & > .small__table {
      margin-right: 0px;
      margin-bottom: 10px;
      max-width: 100%;
      width: 100%;
    }
  }
  .order__create--form .head__create--controls .create__info .check__input.check__v--name {
    margin-right: 0px;
    margin-bottom: 15px;
    & label {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .order__create--form .head__create--controls .create__info .check__input .check__float {
    left: 0px;
    transform: none;
    margin-top: 5px;
  }
}
@media (max-width: 1200px) {
  .link__information--tooltip {
    max-width: 145px;
    margin-left: -155px;
  }
  .structure__form--block {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > .structure__tree {
      max-width: 100%;
      min-width: 0px;
      width: 100%;
      margin-bottom: 30px;
    }
    & > .structure__fields--container {
      width: 100%;
      margin-left: 0px;
    }
  }
  .modal__termbase {
    &.termbase__long {
      & .inner__modal--regular {
        max-width: 800px;
      }
    }
  }
  .general__result {
    & .elem__result {
      & .result__info {
        & .result__static {
          & .static__image {
            & > img {
              min-width: 160px;
              max-width: 160px;
            }
          }
        }
      }
    }
  }
  .general__projects {
    & .general__content {
      & .general__buttons {
        flex-direction: column-reverse;
        & .general__right {
          width: 100%;
          padding-top: 0px;
        }
      }
    }
  }
  .employers-page__heading.task__heading > .task__heading {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .pre__translation {
    & .translation__memories {
      & .memories__main--list {
        & .list__full {
          & .custom__select {
            & .select {
              width: 100%;
              max-width: 100%;
            }
          }
        }
        & .triple__list {
          grid-row-gap: 20px;
          grid-template-columns: 1fr;
          & .elem__list {
            & .custom__select {
              & .select {
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .advanced__settings {
    & .head__advanced {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      & .switcher__advanced {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
  }
  .upload__block {
    &.service__button {
      & .wrap-btn-add {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}
.radio__checkmark {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
}
.name__radio {
  & .radio {
    & .radio__name {
      white-space: nowrap;
    }
  }
}
@media (max-width: 991px) {
  .notification__messages--list {
    max-height: 210px;
  }
  .menu__button--header > a.active__menu > span:nth-child(3) {
    bottom: 7px;
  }
  .header__notification .dropdown__box {
    left: 50%;
    transform: translate(-50%, 0);
  }
  body > .overlay {
    z-index: 5;
  }
  .advanced__container {
    & > .advanced__triple {
      & .advanced__triple--fields {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        & .el__advanced--small {
          & > input {
            max-width: 100%;
            width: 100%;
            min-width: 0px;
          }
        }
      }
    }
  }
  .terms__buttons {
    & .export__head {
      & .dropdown__export {
        min-width: 360px;
        max-width: 360px;
      }
    }
  }
  .modal__regular.modal__termbase .termbase__three .import__controls .remove__import > a {
    position: relative;
  }
  .structure__fields--container {
    & .table__wrapper {
      overflow-x: auto;
      padding-bottom: 200px;
      margin-bottom: -200px;
      &::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        height: 0px;
        background: transparent; /* Optional: just make scrollbar invisible */
      }
      /* Optional: show position indicator in red */
      &::-webkit-scrollbar-thumb {
        background: #ff0000;
      }
    }
  }
  .general__projects {
    & .general__content {
      border-top: 1px solid rgba(14, 125, 125, 1);
    }
  }
  .languages__box {
    & .elem__language--box {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .general__projects {
    & .general__content {
      min-height: 0px;
      padding-bottom: 40px;
    }
  }
  .general__projects .general__search .head__general--search form {
    width: 100%;
  }
  .general__projects .general__search .head__general--search .field__general {
    width: 100%;
  }
  .general__projects {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > .general__search {
      min-width: 0px;
      max-width: 100%;
      width: 100%;
      & .search__general--list {
        height: 150px;
      }
    }
  }
  .modal__regular.modal__termbase
    .termbase__head
    .termbase__progress
    > .elem__termbase:nth-child(2) {
    padding-left: 0px !important;
  }

  .modal__regular.modal__termbase
    .termbase__head
    .termbase__progress
    > .elem__termbase.current__termbase {
    background-color: rgb(252, 252, 255);
  }

  .modal__regular {
    &.modal__termbase {
      & > a {
        right: 555px;
      }
      & > .inner__modal--regular {
        max-width: 540px;
      }
      & .termbase__head {
        & .termbase__progress {
          & > .elem__termbase {
            margin-right: 0px !important;
            &:nth-child(2) {
              padding-left: 0px;
            }
            &:nth-child(3) {
              padding-left: 0px;
            }
          }
          flex-direction: column;
        }
      }
    }
  }
  .submitted__files--employee {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .submitted__files--controls {
      & .hint__submitted {
        & .submitted__float {
          left: 0px;
          right: auto;
        }
      }
    }
    & .submitted__files--info {
      margin-bottom: 20px;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      & .icon__submitted {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
  }
  .upload__block {
    & .documents__files {
      & .head__documents {
        flex-direction: column;
        justify-content: flex-start;
        & .full__finished--docs {
          & p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  .glossary__complex--wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .glossary__right--complex {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      & .glossary__radio {
        margin-bottom: 10px;
        margin-right: 0px !important;
        & label {
          white-space: initial !important;
        }
      }
    }
  }
  .advanced__settings {
    & .head__advanced {
      width: 100%;
      & .advanced__tab--switcher {
        margin-bottom: 10px;
        width: 100%;
        & ul {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          & > li {
            width: 100%;
            margin-right: 0px;
            & > a {
              width: 100%;
            }
          }
        }
      }
    }
    & .content__advanced {
      & .elem__advanced {
        & .translation__memories {
          & .memories__list {
            & .elem__memory {
              & .memory__content {
                & .memory__top {
                  & .right__memory {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    & .memory__buttons {
                      margin-left: 0px;
                    }
                    & .top__memory--edit {
                      margin-bottom: 10px;
                    }
                  }
                }
                & .memory__bottom {
                  display: grid;
                  grid-template-columns: 1fr;
                  justify-content: center;
                  align-items: center;
                  & > .mem__bottom {
                    border-bottom: 1px solid #ebebeb;
                    &:nth-child(2) {
                      padding-left: 15px;
                    }
                  }
                }
                & .memory__top {
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  & .top__memory--info {
                    width: 100%;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .employers-page__heading.order__heading .heading__my--projects > h2 {
    margin-bottom: 0px;
  }
  .termbase__three {
    & .permissions__field .permissions__dropdown ul {
      max-height: 140px;
    }
  }
}

@media (max-width: 767px) {
  .notifications-dropdown__header {
    display: none;
  }
  .header__notification .dropdown__box {
    max-height: 100vh;
  }
  .notifications-dropdown__scroll {
    max-height: 100% !important;
  }
  .notification__messages--list {
    max-height: calc(100vh - 53px);
    height: calc(100vh - 53px);
  }
  .header__notification .dropdown__box {
    min-width: 0px;
  }
  .inner__modal--cancelation .float__modal--buttons,
  .inner__modal--correction .float__modal--buttons {
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .inner__modal--project .float__modal--buttons {
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .registrations__modal {
    & .float__modal--buttons {
      width: 100%;
      margin-left: 0px;
    }
  }

  .float__modal--buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > .controls__float--modal {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      & > input,
      & > button,
      & > a {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    & > .step__buttons {
      width: 100%;
      & > .controls__next {
        width: 100%;
        margin-right: 0px;
        padding-right: 0px;
        margin-bottom: 10px;
        padding-bottom: 0px;
        &:before {
          content: none;
          width: 50px;
          height: 1px;
          bottom: -10px;
          top: auto;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
  }
  .terms__buttons
    .export__head
    .dropdown__export
    .inner__export
    .choose__format--export
    .advanced__export
    .advanced__container
    .advanced__hotkeys
    > .elem__hotkey
    > .hotkey__button
    > input {
    min-width: 75px;
    max-width: 75px;
  }
  .modal__clarify {
    & .import__controls {
      width: calc(100%) !important;
    }
  }
  .controls__next {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    & > .back__controls {
      margin-right: 0px;
    }
  }
  .match__block {
    & > .match__inner {
      & .match__field {
        flex-direction: column;
        align-items: flex-start;
        & > input {
          min-width: 0px;
          max-width: 100%;
          width: 100%;
        }
        & > p {
          margin-bottom: 10px;
        }
      }
    }
  }
  .clarify__progress {
    flex-direction: column;
    & > .elem__clarify {
      margin-right: 0px;
    }
  }
  .strict__import {
    & .strict__float {
      padding: 10px;
      & > p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .structure__add-field {
    & > .structure__dropdown {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .structure__fields--container .fields__grid--container .link__icon .link__tooltip {
    min-width: 230px;
    max-width: 230px;
  }
  .termbase__three .import__triple {
    width: 100% !important;
  }
  .elem__language--box {
    & .add__general {
      & .general__button {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .terms__buttons {
    & .export__head {
      width: 100%;
      & .dropdown__export {
        width: 100%;
        min-width: 0px;
      }
      max-width: 100%;
      & > a {
        width: 100%;
      }
    }
  }
  .languages__box {
    & .elem__language--box {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
      & .language__term--head {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 0px;
        & > .lang__icon {
          margin-right: 0px;
          margin-bottom: 9px;
        }
      }
    }
  }
  .terms__buttons {
    justify-content: center;
    align-items: center;
    width: 100%;
    & > a {
      margin-right: 0px;
      width: 100%;
      max-width: 100%;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .task__heading.employers-page__heading {
    margin-bottom: 10px;
  }
  .termbase__two .import__controls {
    margin-top: 25px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .termbase__form {
    & .import__triple {
      margin-top: 25px !important;
      max-width: 100%;
      width: 100% !important;
    }
  }
  .regular__languages {
    & .languages__list {
      grid-template-columns: 1fr;
      grid-row-gap: 15px;
    }
  }
  .modal__regular {
    &.modal__termbase {
      & > a {
        right: 435px;
      }
      & > .inner__modal--regular {
        max-width: 420px;
      }
    }
  }
  .task__heading .task__heading .task__group > h2 {
    text-align: center;
  }
  .import__triple {
    display: flex;
    flex-direction: column;
    width: calc(100% - 35px) !important;
    & > input[type='submit'] {
      max-width: 100% !important;
      margin-bottom: 10px;
      margin-right: 0px !important;
    }
    & > button {
      margin-bottom: 10px;
      width: 100%;
      max-width: 100%;
    }
    & .remove__import {
      margin-left: 0px;
      width: 100%;
      max-width: 100%;
      & > a {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .modal__regular {
    &.modal__overwrite,
    &.modal__import,
    &.modal__export,
    &.modal__edit,
    &.modal__import--big,
    &.modal__clarify {
      & > a {
        right: 440px;
      }
      & > .inner__modal--regular {
        max-width: 420px;
      }
    }
  }
  .download__complex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .complex__left {
      margin-bottom: 15px;
      & .upload__block {
        & .wrap-btn-add {
          padding-right: 0px;
        }
      }
    }
  }
  .pre__translation {
    .machine__translation:before {
      width: calc(100% + 59px);
      left: -47px;
    }
  }
  .advanced__settings {
    padding: 10px 12px;
    & .content__advanced {
      & .elem__advanced {
        & .quality__container {
          & .elem__quality {
            & .head__quality {
              & p {
                font-size: 12px;
                line-height: 18px;
              }
            }
            & .quality__el {
              padding: 7px 10px;
              & > .quality__events {
                & .quality__drop {
                  & ul {
                    & li {
                      & a {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
              & > p {
                text-align: center;
                font-size: 12px;
                line-height: 18px;
              }
              & a {
                & p {
                  font-size: 12px !important;
                  line-height: 18px !important;
                }
              }
              flex-direction: column;
              & .quality__events {
                margin-top: 5px;
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
  .picker__v--currency {
    width: 100%;
    max-width: 100%;
  }
  .new__service.modal__wrapper {
    & > a {
      right: 425px;
    }
    & .inner__service--create {
      max-width: 420px;
      & .more__button {
        margin-top: 0px;
      }
      & .group__service {
        & .triple__service {
          margin-bottom: 25px;
          flex-direction: column;
          & .fee__group {
            padding-left: 25px;
            & > span {
              left: 3px;
            }
          }
          & > .group__service {
            width: 100% !important;
            max-width: 100% !important;
            margin-right: 0px !important;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .units__wrp {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .modal__service {
    & .matt__group {
      & .default__matt {
        & .select__label {
          height: 40px;
        }
      }
      & .unit__group {
        & input {
          height: 40px;
        }
      }
    }
    & .price__options {
      & .inner__price--option {
        & > .elem__price--option {
          & .input {
            height: 40px;
          }
        }
      }
    }
    & .group__field {
      &.big__field {
        & input {
          height: 40px;
        }
      }
    }
    & .inner__modal--service {
      & > form {
        & > h2 {
          margin-bottom: 25px;
        }
      }
    }
    & .price__options {
      & .inner__price--option {
        flex-direction: column;
        & > .elem__price--option {
          margin-right: 0px;
          width: 100%;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    & .matt__group {
      flex-direction: column;
      & .matt__selection {
        min-width: 0px;
        max-width: 100%;
        width: 100%;
        margin-bottom: 15px;
      }
      & .default__matt {
        min-width: 0px;
        max-width: 100%;
        width: 100%;
        margin-bottom: 15px;
      }
      & .unit__group {
        min-width: 0px;
        max-width: 100%;
        width: 100%;
      }
    }
    & > a {
      right: 440px;
    }
    & > .inner__modal--service {
      max-width: 420px;
    }
  }
  .employers-page__heading.order__heading .heading__my--projects > h2 {
    margin-bottom: 25px;
  }
  .jobs__assignation .head__assignation {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .jobs__assignation .head__assignation > h2 {
    margin-bottom: 20px;
  }
  .float__table--content .comment__inner {
    transform: translateX(-100%);
    margin-left: 27px;
  }
}
@media (max-width: 480px) {
  .structure__form--block > .structure__tree {
    overflow-x: auto;
    & > .structure__tree--element {
      min-width: 340px;
    }
  }
  .float__modal--buttons {
    width: 100%;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    & > .controls__float--modal {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      & > a,
      & > input,
      & > button {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .record__table {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .structure__add-field > .structure__dropdown {
    transform: none;
    left: auto;
    right: 0px;
  }
  .structure__fields--container .fields__grid--container .link__icon .link__tooltip {
    min-width: 200px;
    max-width: 200px;
  }
  .elem__result {
    & .result__info {
      flex-direction: column;
      justify-content: center;
      & .result__static {
        & .static__image {
          & .static__button {
            & a {
              margin-right: 0px !important;
            }
          }
        }
      }
      & .result__hover {
        margin-top: 7px;
        & > .inner__result--hover {
          margin-left: 0px;
        }
      }
    }
  }
  .elem__language--box {
    & .general__result {
      & .elem__result {
        margin-bottom: 12px !important;
        &:last-child {
          margin-bottom: 0px !important;
        }
        & > span {
          min-width: 0px;
          margin-bottom: 10px;
        }
        & .result__info {
          justify-content: center;
          align-items: center;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .general__buttons {
    & .general__left {
      & .general__button {
        margin-left: 0px;
      }
      & .general__result {
        padding-left: 0px;
        & .elem__result {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0px;
          }
          & .result__info {
            justify-content: center;
          }
          & > span {
            min-width: 0px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .dropdown__form--text {
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 260px;
    max-width: 260px;
    & .inner__cross--dropdown {
      & .cross__select {
        & ul {
          grid-template-columns: 1fr;
          grid-row-gap: 7px;
        }
      }
    }
  }
  .add__general {
    & .field__date--wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .field__controls {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
    & .field__bool--wrapper {
      & .field__bool {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & .field__controls {
          margin-left: 0px;
          margin-top: 10px;
        }
      }
    }
    & .field__select--wrapper {
      & .field__select {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & .field__controls {
          margin-left: 0px;
          margin-top: 10px;
        }
      }
    }
  }
  .add__general {
    & .field__name {
      flex-direction: column;
      & .field__controls {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
  .add__general {
    & .field__text {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .field__controls {
        margin-left: 0px;
        margin-top: 10px;
      }
      & > .text__controls {
        margin-bottom: 10px;
        margin-left: 0px;
      }
      & .text__main--field {
        & .top__main--field {
          flex-wrap: wrap;
        }
      }
    }
  }
  .add__general {
    & .general__button {
      margin-left: 0px;
      margin-right: auto;
    }
  }
  .add__general .general__button > a {
    padding-right: 25px;
    min-width: 215px;
    max-width: 215px;
  }
  .languages__box {
    & .elem__language--box {
      padding-left: 19px;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 15px;
    }
  }
  .add__term--field {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .term__buttons {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
  .general__projects .general__content .general__buttons {
    flex-direction: column-reverse;
    & .general__left {
      & .add__general {
        & .general__button {
          width: 100%;
          max-width: 100%;
          & > a {
            min-width: 0px;
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
    & .general__right {
      flex-direction: column;
      justify-content: center;
      & > a {
        width: 100%;
        max-width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    & > a {
      margin-right: 0px;
      width: 100%;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .termbase__two {
    & .client__definition {
      & > .client__group {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > a {
          width: 100%;
          min-width: 0px;
          max-width: 100%;
          margin-left: 0px;
          margin-top: 10px;
        }
      }
    }
  }
  .modal__regular {
    &.modal__termbase {
      & > a {
        right: 300px;
      }
      & > .inner__modal--regular {
        max-width: 290px;
        padding: 30px 15px;
      }
    }
  }
  .modal__regular > .inner__modal--regular .import__head {
    margin-bottom: 20px;
  }
  .modal__regular > .inner__modal--regular .import__head > h2 {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .double__regular {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
  }
  .edit__block {
    padding-right: 0px;
  }
  .edit__block + .import__controls {
    width: 100% !important;
  }
  .edit__block .replace__field .replace__group a {
    position: static;
  }
  .modal__regular {
    &.modal__overwrite,
    &.modal__import,
    &.modal__export,
    &.modal__edit,
    &.modal__import--big,
    &.modal__clarify {
      & > a {
        right: 300px;
      }
      & > .inner__modal--regular {
        max-width: 290px;
        padding: 30px 15px;
        & .replace__field {
          margin-bottom: 20px;
          & .replace__top {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            & > p {
              margin-bottom: 7px;
            }
          }
        }
        & .import__controls,
        & .export__controls {
          flex-direction: column;
          & > input[type='submit'] {
            width: 100%;
            max-width: 100%;
            margin-bottom: 10px;
            margin-right: 0px;
          }
          & > button {
            width: 100%;
            max-width: 100%;
          }
        }
        & .import__head {
          flex-direction: column;
          align-items: flex-start;
          & > a {
            margin-top: 10px;
            margin-left: 0px;
          }
        }
      }
    }
  }
  .submitted__files--employee {
    & .submitted__files--controls {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .hint__submitted {
        margin-bottom: 10px;
      }
    }
  }
  .pre__translation {
    padding-left: 0px;
    & .machine__translation {
      & > span {
        top: -7px;
        left: -12px;
        z-index: 2;
      }
    }
    & .translation__memories {
      & > span {
        top: -7px;
        left: -12px;
        z-index: 2;
      }
    }
    & .source__block {
      & > span {
        top: -7px;
        left: -12px;
      }
    }
  }
  .advanced__settings {
    & .content__advanced {
      & .elem__advanced {
        & .glossary__info {
          & .content__glossary {
            & .el__glossary {
              & .glossary__content {
                & .glossary__main {
                  & .glossary__top {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    & .glossary__buttons {
                      margin-top: 10px;
                    }
                  }
                }
              }
            }
          }
        }
        & .translation__memories {
          & .memories__list {
            & .elem__memory {
              & .memory__content {
                & .memory__bottom {
                  & .mem__bottom {
                    & .match__info {
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                      & > p {
                        width: 100%;
                        margin-right: 0px;
                        margin-bottom: 10px;
                      }
                      & .custom__select {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .fee__group {
    max-width: 100%;
    padding-left: 25px !important;
    & p {
      padding-left: 0px !important;
    }
    & .select {
      padding-left: 0px !important;
    }
    & > span {
      left: 5px !important;
    }
  }
  .new__service.modal__wrapper {
    & > a {
      right: 300px;
    }
    & .service__buttons {
      flex-direction: column;
      margin-top: 25px;
      & > input[type='submit'] {
        width: 100%;
        max-width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
      }
      & > a {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .new__service.modal__wrapper .inner__service--create {
    max-width: 290px;
    padding: 30px 15px;
    & .group__service {
      & .double__service {
        grid-template-columns: 1fr;
        grid-column-gap: 20px;
        & > .group__service {
          margin-bottom: 20px;
        }
      }
    }
  }
  .units__wrp .input {
    padding: 9px 8px;
  }
  .add__more--services {
    margin-top: 15px;
  }
  .units__wrp .select .select__label {
    padding-right: 10px;
    padding-left: 10px;
  }
  .units__wrp .select__label:after {
    right: 5px;
  }
  .units__wrp .select__label:before {
    width: 15px;
  }
  .lang__picker .elem__advanced--picker .switch__adv--elem > p {
    font-size: 11px;
  }
  .modal__service {
    & .service__controls {
      margin-top: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > a {
        margin-right: 0px !important;
        width: 100% !important;
        max-width: 100% !important;
        &:nth-child(1) {
          margin-bottom: 10px;
        }
      }
    }
    & > a {
      right: 300px;
    }
    & > .inner__modal--service {
      max-width: 290px;
      padding: 30px 15px;
    }
  }
  .employers-page__heading .wrap__double {
    width: 100%;
  }
  .employers-page__heading
    .wrap__double
    .add__service--after
    .service__drop--wrapper
    .arrow__custom {
    right: -40px !important;
  }
  .employers-page__heading .wrap__double {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > .add__service--after {
      width: 100%;
      max-width: 200px;
      margin-bottom: 10px;
      & .service__button {
        width: 100%;
        & .service__drop--wrapper {
          width: 100%;
          & .btn-add {
            width: 100%;
            & .btn-add__input_add {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .float__table--content .comment__inner {
    min-width: 230px;
    max-width: 230px;
  }
  .float__table--content .comment__inner .comment__users .comment__user .comment__head > a {
    margin-bottom: 7px;
  }
  .float__table--content .comment__inner .comment__users .comment__user .comment__desc > p {
    font-size: 13px;
  }
  .float__table--content .comment__inner .comment__users .comment__user .comment__head {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .status__hint {
    min-width: 150px;
    max-width: 150px;
  }
  .status__hint p {
    font-size: 11px !important;
    line-height: 17px !important;
  }
  .jobs__assignation .head__assignation .assignation__controls {
    flex-direction: column;
    justify-content: center;
  }
  .jobs__assignation .head__assignation .assignation__controls > .assign__button {
    margin-bottom: 15px;
  }
  .jobs__assignation .head__assignation .assignation__controls > .play__style {
    margin-left: 0px;
  }
  .custom__drop {
    min-width: 240px;
    max-width: 240px;
    margin-left: -80px;
  }
}
.menu__main--side {
  & ul {
    & li {
      &.droppable__link {
        & > ul {
          margin-top: 5px;
          margin-bottom: 5px;
        }
        &.droppable__active {
          & a {
            & .drop__arrow {
              transform: rotate(180deg);
            }
          }
        }
        & a {
          padding-right: 25px;
          & .drop__arrow {
            position: absolute;
            right: 15px;
            transition: 0.4s ease all;
          }
        }
      }
      & ul {
        width: 100%;
        & li {
          width: 100%;
          & a {
            font-size: 14px;
            justify-content: flex-start;
            color: rgba(255, 255, 255, 0.5);
            display: flex;
            width: 100%;
            text-decoration: none;
            padding: 10px 15px;
            transition: 0.4s ease all;
            border-radius: 3px;
            &:hover {
              background: rgba(255, 255, 255, 0.1);
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.service__drop--wrapper .service__list {
  max-height: 145px;
}
.employers-page__heading .wrap__double {
  display: flex;
  align-items: center;
  & .upload__block {
    & .wrap-btn-add {
      padding-left: 20px;
    }
  }
  & .add__service--after {
    margin-top: 0px;
    & .service__drop--wrapper {
      padding-left: 0px;
      & .arrow__custom {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -15px;
        top: 1px;
        width: 12px;
        height: 12px;
        cursor: pointer;
        border-radius: 4px;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBFN0Q3RCIvPgo8L3N2Zz4K)
          center no-repeat;
        transition: all 0.4s ease;
        position: relative;
      }
      & .btn-add__input-label {
        min-height: 46.8px;
      }
      & .btn-add {
        & .btn-add__header {
          min-height: 46.8px;
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .modal__regular {
    &.modal__termbase {
      & > a {
        right: 270px;
      }
      & > .inner__modal--regular {
        max-width: 260px;
      }
    }
  }
  .modal__regular {
    &.modal__overwrite,
    &.modal__import,
    &.modal__export,
    &.modal__edit,
    &.modal__import--big,
    &.modal__clarify {
      & > a {
        right: 270px;
      }
      & > .inner__modal--regular {
        max-width: 260px;
      }
    }
  }
  .new__service.modal__wrapper {
    & > a {
      right: 270px;
    }
    & .inner__service--create {
      max-width: 260px;
    }
  }
  .modal__service .switcher__service ul > li > a {
    padding: 10px 10px;
  }
  .units__wrp {
    grid-column-gap: 7px;
  }
  .modal__service {
    & > a {
      right: 270px;
    }
    & > .inner__modal--service {
      max-width: 260px;
    }
  }
}
